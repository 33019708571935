import Api from "../config/axios";
import config from "../config/config";

export const addCampaignService = async (data: any) => {
  const url = config.apiUrl + "/campaign/";

  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const uploadCampaignFiles = async (idCampaign: any, files: any) => {
  const url = config.apiUrl + "/campaign/adddocs/" + idCampaign;
  const formData = new FormData();
  files.forEach((file: File) => formData.append("docs", file));
  try {
    return await Api.post(url, formData);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const GettCampaignService = async (data: any) => {
  const url = config.apiUrl + "/campaign/" + data;
  try {
    return await Api.get(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getCampaignsByRegionAndCategory = async (data: any) => {
  const url = config.apiUrl + "/campaign/Recherche/Campaign";
  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateProjectService = async (data: any) => {
  const url = config.apiUrl + "/campaign/" + data._id;

  try {
    return await Api.put(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getCampaignsByidProject = async (id: any) => {
  const url = config.apiUrl + "/campaign/get/CampaignbyIdProject/";
  try {
    return await Api.post(url, {id});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getCampaignsByidProject,
  addCampaignService,
  uploadCampaignFiles,
  GettCampaignService,
  getCampaignsByRegionAndCategory,
  updateProjectService
};
