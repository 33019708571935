import Api from "../config/axios";
import config from "../config/config";

export const RecentDonation = async (idproject: string) => {
  const url = config.apiUrl + "/donation/web3/lastDonations/" + idproject;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const RecentDonationbyProject = async (idproject: string) => {
  const url = config.apiUrl + "/donation/web3/lastDonationByProject/" + idproject;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  RecentDonation,
  RecentDonationbyProject
};
