import { useState } from "react";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { wordCounter } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep20 = (props: any) => {
  const { t } = useTranslation();

  const [durable, setDurable] = useState(
    props.project.durable ? props.project.durable : 1
  );
  const [durableDetails, setDurableDetails] = useState(
    props.project.durableDetails ? props.project.durableDetails : ""
  );
  const [durableDetailsError, setDurableDetailsError] = useState("");

  const handleDurableChange = (event: any) => {
    setDurable(event.target.value);
  };
  const handleDurableDetailsChange = (event: any) => {
    setDurableDetails(event.target.value);
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();
    if (!durableDetails) {
      setDurableDetailsError(t("Ce_champ_est_obligatoire"));
      return;
    } else if (!wordCounter(durableDetails, 10)) {
      setDurableDetailsError(t("Veuillez_taper_au_moins_10_mots"));
      return;
    } else {
      setDurableDetailsError("");
    }
    props.project.durable = durable;
    props.project.durableDetails = durableDetails;
    props.updateProject(props.project);
    props.nextHandler(2);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(0)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">{t("Durabilité")}</h5>
        <span className="mt-4 mb-4">
          {t("projet_est_durable")} <br />
          {t("échelle")}
        </span>
        <div className="col-12">
          <SelectInput
            selectedCategory={durable}
            selectList={[
              { _id: 1, name: "1" },
              { _id: 2, name: "2" },
              { _id: 3, name: "3" },
              { _id: 4, name: "4" },
              { _id: 5, name: "5" },
            ]}
            onChange={handleDurableChange}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("précisions")}
            onChange={handleDurableDetailsChange}
            error={durableDetailsError}
            value={durableDetails}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep20;
