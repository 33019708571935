export const regions = [
  { _id: "Ariana", name: "Ariana" },
  { _id: "Béja", name: "Béja" },
  { _id: "Ben Arous", name: "Ben Arous" },
  { _id: "Bizerte", name: "Bizerte" },
  { _id: "Gabès", name: "Gabès" },
  { _id: "Gafsa", name: "Gafsa" },
  { _id: "Jendouba", name: "Jendouba" },
  { _id: "Kairouan", name: "Kairouan" },
  { _id: "Kasserine", name: "Kasserine" },
  { _id: "Kébilia", name: "Kébilia" },
  { _id: "Le Kef", name: "Le Kef" },
  { _id: "Mahdia", name: "Mahdia" },
  { _id: "La Manouba", name: "La Manouba" },
  { _id: "Médenine", name: "Médenine" },
  { _id: "Monastir", name: "Monastir" },
  { _id: "Nabeul", name: "Nabeul" },
  { _id: "Sfax", name: "Sfax" },
  { _id: "Sidi Bouzid", name: "Sidi Bouzid" },
  { _id: "Siliana", name: "Siliana" },
  { _id: "Tataouine", name: "Tataouine" },
  { _id: "Tozeur", name: "Tozeur" },
  { _id: "Tunis", name: "Tunis" },
  { _id: "Zaghouan", name: "Zaghouan" },
  { _id: "Autre", name: "Autre" },
];
export const regionsArabe = [
  { _id: "Ariana", name: "أريانة" },
  { _id: "Béja", name: "باجة" },
  { _id: "Ben Arous", name: "بن عروس" },
  { _id: "Bizerte", name: "بنزرت" },
  { _id: "Gabès", name: "قابس" },
  { _id: "Gafsa", name: "قفصة" },
  { _id: "Jendouba", name: "جندوبة" },
  { _id: "Kairouan", name: "القيروان" },
  { _id: "Kasserine", name: "القصرين" },
  { _id: "Kébilia", name: "قبلي" },
  { _id: "Le Kef", name: "الكاف" },
  { _id: "Mahdia", name: "المهدية" },
  { _id: "La Manouba", name: "منوبة" },
  { _id: "Médenine", name: "مدنين" },
  { _id: "Monastir", name: "المنستير" },
  { _id: "Nabeul", name: "نابل" },
  { _id: "Sfax", name: "صفاقس" },
  { _id: "Sidi Bouzid", name: "سيدي بوزيد" },
  { _id: "Siliana", name: "سليانة" },
  { _id: "Tataouine", name: "تطاوين" },
  { _id: "Tozeur", name: "توزر" },
  { _id: "Tunis", name: "تونس الكبرى" },
  { _id: "Zaghouan", name: "زغوان" },
  { _id: "Autre", name: "آخر" },
];
export const regions2Arabe : any = [
  { _id: "Ariana", name: "أريانة" },
  { _id: "Béja", name: "باجة" },
  { _id: "Ben Arous", name: "بن عروس" },
  { _id: "Bizerte", name: "بنزرت" },
  { _id: "Gabès", name: "قابس" },
  { _id: "Gafsa", name: "قفصة" },
  { _id: "Jendouba", name: "جندوبة" },
  { _id: "Kairouan", name: "القيروان" },
  { _id: "Kasserine", name: "القصرين" },
  { _id: "Kébilia", name: "قبلي" },
  { _id: "Le Kef", name: "الكاف" },
  { _id: "Mahdia", name: "المهدية" },
  { _id: "La Manouba", name: "منوبة" },
  { _id: "Médenine", name: "مدنين" },
  { _id: "Monastir", name: "المنستير" },
  { _id: "Nabeul", name: "نابل" },
  { _id: "Sfax", name: "صفاقس" },
  { _id: "Sidi Bouzid", name: "سيدي بوزيد" },
  { _id: "Siliana", name: "سليانة" },
  { _id: "Tataouine", name: "تطاوين" },
  { _id: "Tozeur", name: "توزر" },
  { _id: "Tunis", name: "تونس الكبرى" },
  { _id: "Zaghouan", name: "زغوان" },
];

export const regions2 = [
  { _id: "Ariana", name: "Ariana" },
  { _id: "Béja", name: "Béja" },
  { _id: "Ben Arous", name: "Ben Arous" },
  { _id: "Bizerte", name: "Bizerte" },
  { _id: "Gabès", name: "Gabès" },
  { _id: "Gafsa", name: "Gafsa" },
  { _id: "Jendouba", name: "Jendouba" },
  { _id: "Kairouan", name: "Kairouan" },
  { _id: "Kasserine", name: "Kasserine" },
  { _id: "Kébilia", name: "Kébilia" },
  { _id: "Le Kef", name: "Le Kef" },
  { _id: "Mahdia", name: "Mahdia" },
  { _id: "La Manouba", name: "La Manouba" },
  { _id: "Médenine", name: "Médenine" },
  { _id: "Monastir", name: "Monastir" },
  { _id: "Nabeul", name: "Nabeul" },
  { _id: "Sfax", name: "Sfax" },
  { _id: "Sidi Bouzid", name: "Sidi Bouzid" },
  { _id: "Siliana", name: "Siliana" },
  { _id: "Tataouine", name: "Tataouine" },
  { _id: "Tozeur", name: "Tozeur" },
  { _id: "Tunis", name: "Tunis" },
  { _id: "Zaghouan", name: "Zaghouan" },
];

export const countries = [
  { _id: "L'Europe", name: "L'Europe" },
  { _id: "Pays du Golfe", name: "Pays du Golfe" },
  { _id: "L'Afrique", name: "L'Afrique" },
  { _id: "Canada", name: "Canada" },
  { _id: "L'Amérique", name: "L'Amérique" },
];

export const countriesArabe = [
  { _id: "L'Europe", name: "أوروبا" },
  { _id: "Pays du Golfe", name: "دول الخليج" },
  { _id: "L'Afrique", name: "أفريقيا" },
  { _id: "Canada", name: "كندا" },
  { _id: "L'Amérique", name: "أمريكا" },
];
