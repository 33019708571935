import { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";

const Links = (props: any) => {
  const { t } = useTranslation();
  const [linkedin, setLinkedin] = useState(
    props.project.linkedin
      ? props.project.linkedin.replace("http://www.linkedin.com/", "")
      : ""
  );
  const [facebook, setFacebook] = useState(
    props.project.facebook
      ? props.project.facebook.replace("http://www.facebook.com/", "")
      : ""
  );
  const [twitter, setTwitter] = useState(
    props.project.twitter
      ? props.project.twitter.replace("http://www.twitter.com/", "")
      : ""
  );
  const [showEdit, setShowEdit] = useState(false);
  const changeLinkedinHandler = (e: any) => {
    setLinkedin(e.target.value);
  };
  const changeFacebookHandler = (e: any) => {
    setFacebook(e.target.value);
  };
  const changeTwitterHandler = (e: any) => {
    setTwitter(e.target.value);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const validate = () => {
    const project = { ...props.project };
    project.facebook = "http://www.facebook.com/" + facebook;
    if (facebook.charAt(0) === "/") {
      project.facebook = "http://www.facebook.com/" + facebook.slice(1);
    }
    project.twitter = "http://www.twitter.com/" + twitter;
    if (twitter.charAt(0) === "/") {
      project.twitter = "http://www.twitter.com/" + twitter.slice(1);
    }
    project.linkedin = "http://www.linkedin.com/" + linkedin;
    if (linkedin.charAt(0) === "/") {
      project.linkedin = "http://www.linkedin.com/" + linkedin.slice(1);
    }
    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{t("Liens")}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
      </div>
      {!showEdit && (
        <div className="social_icon_sidebar mt-3">
          <ul>
            <li>
              <a
                href={facebook ? "http://www.facebook.com/" + facebook : ""}
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href={twitter ? "http://www.twitter.com/" + twitter : ""}
                target="_blank"
                aria-disabled
              >
                <i
                  style={{ fontSize: "30px" }}
                  className="fab fa-twitter-square"
                ></i>
              </a>
            </li>
            <li>
              <a
                href={linkedin ? "http://www.linkedin.com/" + linkedin : ""}
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      )}
      {showEdit && (
        <div className="social_icon_sidebar mt-3">
          <Input
            name="linkedin"
            type="text"
            placeholder="Linkedin"
            value={linkedin.replace("http://www.linkedin.com/", "")}
            onChange={changeLinkedinHandler}
            fixedValue={"www.linkedin.com/"}
            cssClass={"small-text"}
          />
          <Input
            name="facebook"
            type="text"
            placeholder="Facebook"
            value={facebook.replace("http://www.facebook.com/", "")}
            onChange={changeFacebookHandler}
            fixedValue={"www.facebook.com/"}
            cssClass={"small-text"}
          />
          <Input
            name="twitter"
            type="text"
            placeholder="Twitter"
            value={twitter.replace("http://www.twitter.com/", "")}
            onChange={changeTwitterHandler}
            fixedValue={"www.twitter.com/"}
            cssClass={"small-text"}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default Links;
