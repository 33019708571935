import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DonationService from "../services/DonationService";

export const adddonation = createAsyncThunk(
  "user/Konnect",
  async (userData: any, thunkAPI) => {
    try {
      const { status, data } = await DonationService.DonationService(userData);
      if (status === 200) {
        return data;
      } else {
        
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const adddonations = createAsyncThunk(
  "user/adddonations",
  async (userData: number, thunkAPI) => {
    try {
      const { status, data } = await DonationService.addDonationService(userData);
      if (status === 200) {
        return data;
      } else {
        
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
    donation: {
    status: "idle",
    data: {
      email: "",
      name: "",
    },
    error: {},
  },
  adddonationBlock: {
    status: "idle",
    data: null,
    error: {},
  },
};
const SignInSlice = createSlice({
  name: "donation-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [adddonation.pending.type]: (state) => {
      state.donation = {
        status: "loading",
        data: {
          email: "",
          name: "",
        },
        error: {},
      };
    },
    [adddonation.fulfilled.type]: (state, action) => {
      state.donation = {
        status: "success",
        data: {
          name: action.payload.name,
          email: action.payload.email,
        },
        
        error: {},
      };
    },
    [adddonation.rejected.type]: (state, action) => {
      state.donation = {
        status: "failed",
        data: {
          email: "",
          name: "",
        },
        error: {
        
        },
      };
    },
    [adddonations.pending.type]: (state) => {
      state.adddonationBlock = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [adddonations.fulfilled.type]: (state, action) => {
      state.adddonationBlock = {
        status: "success",
        data: null,
        error: {},
      };
    },
    [adddonations.rejected.type]: (state, action) => {
      state.adddonationBlock = {
        status: "failed",
        data: null,
        error: {
        
        },
      };
    },
  },
});

export default SignInSlice.reducer;
