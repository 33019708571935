import Filter from "./components/Filter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignsByRegionAndCategory } from "../../../reducers/campaign";
import { useNavigate } from "react-router-dom";
import "./CreateQuadraticCampaign.css";
import Campaigns from "./components/Campaigns";
import Simulation from "./components/Simulation";
import Button from "../../../components/Button/Button";
import { FooterComponent } from "../../../components/footer";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import i18n from "i18next";
import Spinner from "../../../components/Spinner/Spinner";

const getCampaignsByRegionAndCategoryAction = async (
  dispatch: any,
  data: any
) => {
  return await dispatch(getCampaignsByRegionAndCategory(data)).unwrap();
};
const CreateQuadraticCampaign = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSimulation, setShowSimulation] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [selectedCategoriesNames, setSelectedCategoriesNames] = useState<
    string[]
  >([]);
  const [selectedArabicCategoriesNames, setSelectedArabicCategoriesNames] =
    useState<string[]>([]);
  const campaigns = useSelector(
    (state: any) => state.Campaign.campaignsByRegionAndCategory.data
  );
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const showSimulationHandler = () => {
    setShowSimulation(true);
  };

  const onSelectRegionHandler = (region: string) => {
    if (selectedRegions.includes(region)) {
      const x = selectedRegions.filter((item: string) => item !== region);
      setSelectedRegions(x);
    } else {
      const x = [...selectedRegions, region];
      setSelectedRegions(x);
    }
  };

  const onSelectCategoryHandler = (category: any) => {
    if (selectedCategories.includes(category._id)) {
      const x = selectedCategories.filter(
        (item: string) => item !== category._id
      );
      const y = selectedCategoriesNames.filter(
        (item: string) => item !== category.name
      );
      const z = selectedArabicCategoriesNames.filter(
        (item: string) => item !== category.name
      );
      setSelectedCategories(x);
      setSelectedArabicCategoriesNames(z);
    } else {
      const x = [...selectedCategories, category._id];
      const y = [...selectedCategoriesNames, category.name];
      const z = [...selectedArabicCategoriesNames, category.arabicName];
      setSelectedCategories(x);
      setSelectedCategoriesNames(y);
      setSelectedArabicCategoriesNames(z);
    }
  };
  const resetFilterByRegion = () => {
    setSelectedRegions([]);
  };
  const resetFilterByCategory = () => {
    setSelectedCategories([]);
    setSelectedCategoriesNames([]);
  };
  const filterByRegion = async () => {
    const data = {
      categories: selectedCategories,
      regions: selectedRegions,
    };
    await getCampaignsByRegionAndCategoryAction(dispatch, data);
  };
  const filterByCategory = async () => {
    const data = {
      categories: selectedCategories,
      regions: selectedRegions,
    };
    await getCampaignsByRegionAndCategoryAction(dispatch, data);
  };
  const annulate = async () => {
    setSelectedCategories([]);
    setSelectedRegions([]);
    const data = {
      categories: [""],
      regions: [""],
    };
    await getCampaignsByRegionAndCategoryAction(dispatch, data);
    navigate("/corporate-profile");
  };
  return (
    <>
      <section dir={t("dir")}>
        <div className="container">
          <h3 className="mt-4 mb-4" style={{ textAlign: "center" }}>
            {t("Créer_une_campagne_quadratique")}{" "}
          </h3>
          {!showSimulation && (
            <>
              <Filter
                selectedRegions={selectedRegions}
                selectedCategories={selectedCategories}
                selectedCategoriesNames={
                  currentLanguage === "fr"
                    ? selectedCategoriesNames
                    : selectedArabicCategoriesNames
                }
                onSelectRegionHandler={onSelectRegionHandler}
                resetFilterByRegion={resetFilterByRegion}
                filterByRegion={filterByRegion}
                onSelectCategoryHandler={onSelectCategoryHandler}
                resetFilterByCategory={resetFilterByCategory}
                filterByCategory={filterByCategory}
                currentLanguage={currentLanguage}
              />

              {(!campaigns || (campaigns && campaigns.value.length === 0)) && (
                <h4 className="mt-4" style={{ marginBottom: "100px" }}>
                  {t("Selectionner")}
                </h4>
              )}
              <Campaigns campaigns={campaigns} />
              {campaigns && campaigns.value.length > 0 && (
                <section
                  className="mt-4 mb-4"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    cssClass="me-2"
                    title={t("Annuler")}
                    onClick={annulate}
                  />
                  <Button
                    cssClass="me-2"
                    title={t("simulation")}
                    onClick={showSimulationHandler}
                  />
                </section>
              )}
            </>
          )}
          {showSimulation && (
            <Simulation
              campaigns={campaigns}
              annulate={annulate}
              regions={selectedRegions}
              categories={selectedCategories}
            />
          )}
        </div>
      </section>
      <FooterComponent />
    </>
  );
};
export default CreateQuadraticCampaign;
