import { useEffect, useState } from "react";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import { wordCounter } from "../../../../utils/functions";
import i18n from "i18next";

const ProjectDetails = (props: any) => {
  const { t } = useTranslation();
  //console.log('asslama',props.response, t(props.response), props.project);
  const [showTranslate, setShowTranslate] = useState(false);
  const [option, setOption] = useState(props.response);
  const [optionDetails, setOptionDetails] = useState("");
  const [optionDetailsError, setOptionDetailsError] = useState("");
  const [selectList, setSelectList] = useState<any>([]);
  const [showEdit, setShowEdit] = useState(false);
  const [canTranslate, setCanTranslate] = useState(true);
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  useEffect(() => {
    if (currentLanguage === "ar") {
      setOptionDetails(props.description);
    }
    if (currentLanguage === "fr") {
      setOptionDetails(props.frenchDescription);
    }
  }, [currentLanguage]);
  const handleOptionChange = (event: any) => {
    setOption(event.target.value);
  };
  const handleOptionDetailsChange = (event: any) => {
    setOptionDetails(event.target.value);
  };
  const showEditHandler = () => {
    setShowEdit(true);
    if (currentLanguage === "ar") {
      setOptionDetails(props.description);
    }
    if (currentLanguage === "fr") {
      setOptionDetails(props.frenchDescription);
    }
  };
  const showTranslateHandler = () => {
    setShowTranslate(true);
    if (currentLanguage === "fr") {
      setOptionDetails(props.description);
    }
    if (currentLanguage === "ar") {
      setOptionDetails(props.frenchDescription);
    }
  };
  useEffect(() => {
    if (props.title === t("DurabilitéTitle")) {
      setSelectList([
        { _id: 1, name: "1" },
        { _id: 2, name: "2" },
        { _id: 3, name: "3" },
        { _id: 4, name: "4" },
        { _id: 5, name: "5" },
      ]);
      setOption(props.response ? props.response : 1);
    }
    if (props.title === t("UnicitéTitle")) {
      setSelectList([
        { _id: true, name: t("yes") },
        { _id: false, name: t("no") },
      ]);
      setOption(props.response === t("yes") ? "true" : "false");
      setCanTranslate(props.response === t("yes") ? true : false);
    }
    if (props.title === t("InnovationTitle")) {
      setSelectList([
        { _id: true, name: t("yes") },
        { _id: false, name: t("no") },
      ]);
      setOption(props.response === t("yes") ? "true" : "false");
      setCanTranslate(props.response === t("yes") ? true : false);
    }
    if (props.title === t("Impact_sur_environnement")) {
      setSelectList([
        {
          _id: "good_impact",
          name: t("good_impact"),
        },
        { _id: "bad_impact", name: t("bad_impact") },
        { _id: "no_impact", name: t("no_impact") },
      ]);
      setOption(props.response ? props.response : "");
    }
    if (props.title === t("Valeur_communauté")) {
      setSelectList([
        {
          _id: "no_value",
          name: t("no_value"),
        },
        { _id: "little_value", name: t("little_value") },
        { _id: "moyennement", name: t("moyennement") },
        { _id: "majority", name: t("majority") },
      ]);
      setOption(props.response ? props.response : "");
    }
  }, [props.title]);

  const validate = () => {
    if (!wordCounter(optionDetails, 50)) {
      setOptionDetailsError(t("Veuillez_taper_au_moins_50_mots"));
      return;
    } else {
      setOptionDetailsError("");
    }
    const project = { ...props.project };
    if (props.title === t("DurabilitéTitle")) {
      project.durable = option;
      if (currentLanguage === "fr") {
        project.frenchdurableDetails = optionDetails;
      }
      if (currentLanguage === "ar") {
        project.durableDetails = optionDetails;
      }
    }
    if (props.title === t("UnicitéTitle")) {
      project.isUnique = option;
      if (currentLanguage === "fr") {
        project.frenchuniqueDetails = optionDetails;
      }
      if (currentLanguage === "ar") {
        project.uniqueDetails = optionDetails;
      }
    }
    if (props.title === t("InnovationTitle")) {
      project.isInnovative = option;
      if (currentLanguage === "fr") {
        project.franchinnovationDetails = optionDetails;
      }
      if (currentLanguage === "ar") {
        project.innovationDetails = optionDetails;
      }
    }
    if (props.title === t("Impact_sur_environnement")) {
      project.impactOnEnviroment = option;
      if (currentLanguage === "fr") {
        project.frenchimpactOnEnviromentDetails = optionDetails;
      }
      if (currentLanguage === "ar") {
        project.impactOnEnviromentDetails = optionDetails;
      }
    }
    if (props.title === t("Valeur_communauté")) {
      project.addedValueToCommunity = option;
      if (currentLanguage === "fr") {
        project.frenchaddedValueToCommunityDetails = optionDetails;
      }
      if (currentLanguage === "ar") {
        project.addedValueToCommunityDetails = optionDetails;
      }
    }

    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  const translate = () => {
    if (!wordCounter(optionDetails, 50)) {
      setOptionDetailsError(t("Veuillez_taper_au_moins_50_mots"));
      return;
    } else {
      setOptionDetailsError("");
    }
    const project = { ...props.project };
    if (props.title === t("DurabilitéTitle")) {
      if (currentLanguage === "ar") {
        project.frenchdurableDetails = optionDetails;
      }
      if (currentLanguage === "fr") {
        project.durableDetails = optionDetails;
      }
    }
    if (props.title === t("UnicitéTitle")) {
      if (currentLanguage === "ar") {
        project.frenchuniqueDetails = optionDetails;
      }
      if (currentLanguage === "fr") {
        project.uniqueDetails = optionDetails;
      }
    }
    if (props.title === t("InnovationTitle")) {
      if (currentLanguage === "ar") {
        project.franchinnovationDetails = optionDetails;
      }
      if (currentLanguage === "fr") {
        project.innovationDetails = optionDetails;
      }
    }
    if (props.title === t("Impact_sur_environnement")) {
      if (currentLanguage === "ar") {
        project.frenchimpactOnEnviromentDetails = optionDetails;
      }
      if (currentLanguage === "fr") {
        project.impactOnEnviromentDetails = optionDetails;
      }
    }
    if (props.title === t("Valeur_communauté")) {
      if (currentLanguage === "ar") {
        project.frenchaddedValueToCommunityDetails = optionDetails;
      }
      if (currentLanguage === "fr") {
        project.addedValueToCommunityDetails = optionDetails;
      }
    }

    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  const annulateTranslate = () => {
    setShowTranslate(false);
  };
  return (
    <div className="share_causes_wrapper sidebar_boxed mt-3">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{props.title}</h3>
        {!showEdit && !showTranslate && props.canEdit && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {canTranslate && (
              <i
                className={"fa fa-language me-2 pointer"}
                style={{
                  color: "#818090",
                  fontSize: "20px",
                }}
                onClick={showTranslateHandler}
              ></i>
            )}
            <i
              className={"fa fa-edit me-2 pointer"}
              style={{
                color: "#818090",
              }}
              onClick={showEditHandler}
            ></i>
          </div>
        )}
      </div>
      {!showEdit && !showTranslate && (
        <div className="mt-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>{t("Réponse")}</p>
            <p className="fw-bold">{t(props.response)}</p>
          </div>
          <div className="mt-3">
            <p>
              {currentLanguage === "ar"
                ? props.description
                  ? props.description
                  : props.frenchDescription
                : props.frenchDescription
                ? props.frenchDescription
                : props.description}
            </p>
          </div>
        </div>
      )}
      {showEdit && (
        <div className="mt-3">
          <SelectInput
            selectedCategory={option}
            selectList={selectList}
            onChange={handleOptionChange}
          />
          <Textarea
            rows={7}
            placeholder={t("précisions")}
            onChange={handleOptionDetailsChange}
            error={optionDetailsError}
            value={optionDetails}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
      {showTranslate && (
        <div className="mt-3">
          <Textarea
            rows={7}
            placeholder={t("précisions")}
            onChange={handleOptionDetailsChange}
            error={optionDetailsError}
            value={optionDetails}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulateTranslate}
            secondBtnAction={translate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectDetails;
