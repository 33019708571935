import Api from "../config/axios";
import config from "../config/config";

export const createQuadraticCampaign = async (data: any) => {
  const url = config.apiUrl + "/campaign/";

  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const launchQuadraticCampaign = async (data: any) => {
  const url = config.apiUrl + "/campaign/QF/donationCampaign";

  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateStatusQuadraticCampaign = async (data: any) => {
  const url = config.apiUrl + "/campaign-quadratic/update-status/";

  try {
    return await Api.put(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getQuadraticCampaignsByCorporate = async (id: string) => {
  const url = config.apiUrl + "/campaign-quadratic/by-entreprise/" + id;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getQuadraticCampaignById = async (id: string) => {
  const url = config.apiUrl + "/campaign-quadratic/details/" + id;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  createQuadraticCampaign,
  launchQuadraticCampaign,
  updateStatusQuadraticCampaign,
  getQuadraticCampaignsByCorporate,
  getQuadraticCampaignById,
};
