import { useState } from "react";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const Links = (props: any) => {
  const { t } = useTranslation();

  const [showEditLinks, setShowEditLinks] = useState(false);
  const [linkedin, setLinkedin] = useState(
    props.user.value.investors.linkedin
      ? props.user.value.investors.linkedin.replace(
          "http://www.linkedin.com/",
          ""
        )
      : ""
  );
  const [facebook, setFacebook] = useState(
    props.user.value.investors.facebook
      ? props.user.value.investors.facebook.replace(
          "http://www.facebook.com/",
          ""
        )
      : ""
  );
  const [twitter, setTwitter] = useState(
    props.user.value.investors.twitter
      ? props.user.value.investors.twitter.replace(
          "http://www.twitter.com/",
          ""
        )
      : ""
  );

  const changeLinkedinHandler = (e: any) => {
    setLinkedin(e.target.value);
  };
  const changeFacebookHandler = (e: any) => {
    setFacebook(e.target.value);
  };
  const changeTwitterHandler = (e: any) => {
    setTwitter(e.target.value);
  };

  const validate = async () => {
    if (props.user && props.user.value) {
      const data = {
        id: props.id,
        name: props.user.value.name,
        roleAgent: props.user.value.roleAgent,
        email: props.user.value.email,
        address: props.user.value.investors.address
          ? props.user.value.investors.address
          : "",
        secteur: props.user.value.investors.secteur
          ? props.user.value.investors.secteur
          : "",
        type: props.user.value.type,
        description: props.user.value.investors.description
          ? props.user.value.investors.description
          : "",
        facebook: "",
        linkedin: "",
        twitter: "",
      };
      data.facebook = "http://www.facebook.com/" + facebook;
      if (facebook.charAt(0) === "/") {
        data.facebook = "http://www.facebook.com/" + facebook.slice(1);
      }
      data.twitter = "http://www.twitter.com/" + twitter;
      if (twitter.charAt(0) === "/") {
        data.twitter = "http://www.twitter.com/" + twitter.slice(1);
      }
      data.linkedin = "http://www.linkedin.com/" + linkedin;
      if (linkedin.charAt(0) === "/") {
        data.linkedin = "http://www.linkedin.com/" + linkedin.slice(1);
      }
      props.editUserHandler(data);
      setShowEditLinks(false);
    }
  };

  const annulate = () => {
    setShowEditLinks(false);
    setLinkedin(
      props.user.value.investors.linkedin
        ? props.user.value.investors.linkedin.replace(
            "http://www.linkedin.com/",
            ""
          )
        : ""
    );
    setFacebook(
      props.user.value.investors.facebook
        ? props.user.value.investors.facebook.replace(
            "http://www.facebook.com/",
            ""
          )
        : ""
    );
    setTwitter(
      props.user.value.investors.twitter
        ? props.user.value.investors.twitter.replace(
            "http://www.twitter.com/",
            ""
          )
        : ""
    );
  };
  return (
    <div className="mt-4">
      <div className="mb-4" style={{ display: "flex", alignItems: "center" }}>
        <h5 className="me-2">{t("Liens")}</h5>
        <i
          className={!showEditLinks ? "fa fa-edit me-2 pointer" : "fa fa-times me-2 pointer"}
          style={{
            color: "#818090",
          }}
          onClick={() => setShowEditLinks(!showEditLinks ? true : false)}
        ></i>
      </div>
      {showEditLinks && (
        <div className="mb-3">
          <Input
            name="linkedin"
            type="text"
            placeholder="Linkedin"
            value={linkedin.replace("http://www.linkedin.com/", "")}
            onChange={changeLinkedinHandler}
            fixedValue={"www.linkedin.com/"}
            cssClass={"small-text"}
          />
          <Input
            name="facebook"
            type="text"
            placeholder="Facebook"
            value={facebook.replace("http://www.facebook.com/", "")}
            onChange={changeFacebookHandler}
            fixedValue={"www.facebook.com/"}
            cssClass={"small-text"}
          />
          <Input
            name="twitter"
            type="text"
            placeholder="Twitter"
            value={twitter.replace("http://www.twitter.com/", "")}
            onChange={changeTwitterHandler}
            fixedValue={"www.twitter.com/"}
            cssClass={"small-text"}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
      {!showEditLinks && (
        <div className="social_icon_sidebar mt-3">
          <ul>
            <li>
              <a
                className="disabled"
                href={linkedin ? "http://www.linkedin.com/" + linkedin : ""}
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href={facebook ? "http://www.facebook.com/" + facebook : ""}
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href={twitter ? "http://www.twitter.com/" + twitter : ""}
                target="_blank"
              >
                <i
                  style={{ fontSize: "30px" }}
                  className="fab fa-twitter-square"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default Links;
