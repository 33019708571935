import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkIsValidEmail,
  checkIsValidPassword,
} from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../../reducers/signup";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import GoogleLoginBtn from "../../../components/GoogleLoginBtn/GoogleLoginBtn";
import SelectInput from "../../../components/SelectInput/SelectInput";
import {
  countries,
  countriesArabe,
  regions,
  regionsArabe,
} from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import i18n from "i18next";
import Spinner from "../../../components/Spinner/Spinner";

const register = async (dispatch: any, data: any) => {
  return await dispatch(signupUser(data)).unwrap();
};
const SignUpForm = (props: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const [message, setMessage] = useState("");
  const [showVerificationEmailMessage, setShowVerificationEmailMessage] =
    useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hiddenRePassword, setHiddenRePassword] = useState(true);
  const [country, setCountry] = useState("");

  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [governorateError, setGovernorateError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");
  const [countryError, setCountryError] = useState("");

  const status = useSelector((state: any) => state.Signup.signup.status);
  const error = useSelector((state: any) => state.Signup.signup.error);

  useEffect(() => {
    setShowVerificationEmailMessage(false);
    if (status === "success") {
      setShowVerificationEmailMessage(true);
    } else if (status === "failed" && error === "email does exist") {
      setMessage("Email existe déjà");
    } else if (status === "failed") {
      setMessage("Une erreur est survenue");
    }
  }, [status]);


  const handleEmailChange = (event: any) => {
    const newEmail = event.target.value;
    if (!newEmail.trim()) {
      setEmailError(t("Ce_champ_est_obligatoire"));
    } else {
      const isValidEmail = checkIsValidEmail(newEmail);
      setEmailError(isValidEmail ? '' : t("Lemail_nest_pas_valide"));
    }
    setEmail(newEmail);
  };

  const handleNameChange = async (event: any) => {
    const newName = event.target.value;
    if (!newName.trim()) {
      setNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setNameError("");
    }
    setName(newName);
  };
  const handleGovernorateChange = (event: any) => {
    setGovernorate(event.target.value);
  };
  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    // Check if the password is empty
    if (!newPassword.trim()) {
      // Show a required message or perform any necessary action
      setPasswordError(t("Ce_champ_est_obligatoire"));
    } else {
      // Validate the password format
      const isValidPassword = checkIsValidPassword(newPassword);
  
      // Update the error state based on the validation result
      setPasswordError(isValidPassword ? '' : t("mdp"));
    }
  
    // Set the password state with the entered value
    setPassword(newPassword);
  };
  
  const handleRePasswordChange = (event: any) => {
    setRePassword(event.target.value);
    // Check if the passwords match
    const passwordsMatch = event.target.value === password;
    // Update the error state based on the validation result
    setRePasswordError(passwordsMatch ? '' : t("mdp_necorrespondpas"));
  };
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };

  const googleOath = async (userData: any) => {
    await register(dispatch, userData);
  };
  const validateForm = () => {
    let invalidForm = false;
    const emptyFields =
      password === "" && email === "" && name === "" && governorate === "";

    if (emptyFields) {
      setMessage(t("Veuillez_remplir_tous_les_champs"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }

    if (!email) {
      invalidForm = true;
      setEmailError(t("Ce_champ_est_obligatoire"));
    } else if (!checkIsValidEmail(email)) {
      invalidForm = true;
      setEmailError(t("Lemail_nest_pas_valide"));
    } else {
      invalidForm = false;
      setEmailError("");
    }

    if (!password) {
      invalidForm = true;
      setPasswordError(t("Ce_champ_est_obligatoire"));
    } else if (!checkIsValidPassword(password)) {
      invalidForm = true;
      setPasswordError(t("mdp"));
    } else {
      setPasswordError("");
    }

    if (password !== rePassword) {
      invalidForm = true;
      setRePasswordError(t("mdp_necorrespondpas"));
    } else {
      setRePasswordError("");
    }

    if (!name) {
      invalidForm = true;
      setNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setNameError("");
    }

    if (!governorate) {
      invalidForm = true;
      setGovernorateError(t("Ce_champ_est_obligatoire"));
    } else {
      setGovernorateError("");
    }

    if (governorate === "Autre" && !country) {
      invalidForm = true;
      setCountryError(t("Ce_champ_est_obligatoire"));
    } else {
      setCountryError("");
    }
    return invalidForm;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    let userData = {
      name: name,
      email: email,
      password: password,
      gouvernorat: governorate === 'Autre' ? country : governorate,
      walletPubKey: '0xae6F01cBe348FBd7EF0d7459F1e7a5cafE739e6a',
      walletPrivKey:
        'bcbfcec4c1f52b48af8aed6ddfde2c97bb7493c7008bdf2836735f8c90490a38',
      id_google: '',
    };
    await register(dispatch, userData);
  };

  return (
    <form dir={t("dir")}>
      <br />
      <Input
        name="email"
        type="email"
        placeholder={t("Email")}
        value={email}
        onChange={handleEmailChange}
        error={emailError}
      />
    
      <Input
        name="name"
        type="text"
        placeholder={t("Nom_et_Prénom")}
        onChange={handleNameChange}
        error={nameError}
      />

      <SelectInput
        selectedCategory={governorate}
        defaultValue={{ _id: "", name: t("region") }}
        selectList={currentLanguage === "fr" ? regions : regionsArabe}
        onChange={handleGovernorateChange}
        error={governorateError}
      />
      {governorate === "Autre" && (
        <SelectInput
          selectedCategory={country}
          defaultValue={{ _id: "", name: t("pays") }}
          selectList={currentLanguage === "fr" ? countries : countriesArabe}
          onChange={handleCountryChange}
          error={countryError}
        />
      )}
      <Input
        name="password"
        type={hiddenPassword ? "password" : "text"}
        placeholder={t("Mot_de_passe")}
        value={password}
        onChange={handlePasswordChange}
        error={passwordError}
        icon={
          hiddenPassword ? (
            <i className="fa fa-eye-slash" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-eye" aria-hidden="true"></i>
          )
        }
        onClickIcon={() => setHiddenPassword((current) => !current)}
      />
      <Input
        name="repassword"
        type={hiddenRePassword ? "password" : "text"}
        placeholder={t("Confirmer_mot_passe")}
        value={rePassword}
        onChange={handleRePasswordChange}
        error={rePasswordError}
        icon={
          hiddenRePassword ? (
            <i className="fa fa-eye-slash" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-eye" aria-hidden="true"></i>
          )
        }
        onClickIcon={() => setHiddenRePassword((current) => !current)}
      />
      <p className="text-danger text-center mt-3 mb-3">{message}</p>
      {showVerificationEmailMessage && (
        <div className="alert alert-success text-center mt-3 mb-3" role="alert">
        {t("validation_de_votre_compte")}
      </div>
      )}

      <Button
        cssClass="d-grid"
        title={t("Inscrivez_vous")}
        onClick={handleSubmit}
      />
      {status === "loading" && (
        <div className="mt-3" style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      )}
      <hr />
      <GoogleLoginBtn googleOath={googleOath} />
    </form>
  );
};
export default SignUpForm;
