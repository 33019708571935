import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectCategoryService from "../services/ProjectCategoryService";

export const getProjectCategories = createAsyncThunk(
  "project/get-categories",
  async (x: any, thunkAPI: any) => {
    try {
      const { status, data } =
        await ProjectCategoryService.getCategoriesService();
      if (status === 201 || status === 200) {
        return data.value;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  categories: {
    status: "idle",
    data: null,
    error: {},
  },
};
const ProjectCategorySlice = createSlice({
  name: "get-project-categories",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getProjectCategories.pending.type]: (state) => {
      state.categories = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getProjectCategories.fulfilled.type]: (state, action) => {
      state.categories = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectCategories.rejected.type]: (state, action) => {
      state.categories = {
        status: "failed",
        data: action.payload,
        error: "",
      };
    },
  },
});

export default ProjectCategorySlice.reducer;
