import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import config from "../../../../config/config";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";

import { updateProfilePhoto } from "../../../../reducers/donator";
const updateProfilePhotoAction = async (formData: any, dispatch: any) => {
  return await dispatch(updateProfilePhoto(formData)).unwrap();
};
const ProfilePhoto = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const fileInput: any = useRef();
  const [src, setSrc] = useState(
    props.profilePhoto
      ? config.apiUrl + "/donator/getFile/" + props.profilePhoto
      : ""
  );
  const onInputClick = (event: any) => {
    event.target.value = "";
  };
  const selectFile = (e: any) => {
    e.preventDefault();
    fileInput.current.click();
  };
  const onChangeHandler = async (file: any) => {
    const files = file.target.files;
    var reader = new FileReader();
    reader.onload = function (e: any) {
      setSrc(e.target.result);
    };
    reader.readAsDataURL(files[0]);
    const data = { id: props.id, files: [...files] };
    await updateProfilePhotoAction(data, dispatch);
  };
  const removeImage = async () => {
    setSrc("");
    const data = { id: props.id, files: [] };
    await updateProfilePhotoAction(data, dispatch);
  };
  return (
    <div
      className="mb-4"
      style={{
        position: "relative",
        width: "max-content",
        height: "max-content",
        margin: "0 auto",
      }}
    >
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        accept={"image/*"}
        onClick={onInputClick}
        onChange={onChangeHandler}
      />
      <img
        src={src ? src : "assets/img/avatar.png"}
        className="image-profile pointer"
        alt="..."
        onClick={selectFile}
      />{" "}
      <div
        className="mt-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <button
          className="btn btn_theme me-2"
          style={{ fontSize: "12px" }}
          onClick={selectFile}
        >
          {t("Ajouter_une_image")}{" "}
        </button>
        <button
          className="btn btn_navber me-2"
          style={{ fontSize: "12px" }}
          onClick={removeImage}
        >
          {t("Retirer")}
        </button>
      </div>
    </div>
  );
};
export default ProfilePhoto;
