import Links from "./Links";
import ProfilePhoto from "./ProfilePhoto";
import UserInfos from "./UserInfos";

const ProfileDetails = (props: any) => {
  return (
    <div className="contact_form_Wrapper" style={{ height: "100%" }}>
      <div className="text-center">
        {props.user.value.investors && (
          <ProfilePhoto
            id={props.user.value.investors._id}
            profilePhoto={props.user.value.investors.logo}
          />
        )}
        <UserInfos
          user={props.user}
          id={props.id}
          editUserHandler={props.editUserHandler}
        />
      </div>
      <Links
        user={props.user}
        id={props.id}
        editUserHandler={props.editUserHandler}
      />
    </div>
  );
};
export default ProfileDetails;
