import React from 'react';
import './SubscriptionBox.css';
import { useTranslation } from "react-i18next";


 const SubscriptionBox = () => {
    const { t } = useTranslation();
    return (
      <div className="subscription-box-container">
        <div className="left-section">
          <h2>{t('rejoignez')} <br /> {t('communauté')}</h2>
          <img src="assets\img\logoSup.png" alt="AntLike Logo" />
        </div>
        <div className="right-section">
          <div className="subscription-card">
            <p>{t('Abonnez_vous_propos')}</p>
            <p>{t('utilisation_mail')}</p>
            <div className="subscription-form">
              <input type="email" placeholder={t('Email')} />
              <button>{t('Abonnez_vous')}</button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default SubscriptionBox;