import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIsValidEmail,
} from "../../../utils/functions";
import Alert from "react-bootstrap/Alert";
import GoogleLoginBtn from "../../../components/GoogleLoginBtn/GoogleLoginBtn";
import { Provider } from "react-redux";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
// import { signinUser } from "../../reducers/signin";
import { signinUser } from "../../../reducers/signin";
import Input from "../../../components/Input/Input";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import Spinner from "../../../components/Spinner/Spinner";
const login = async (dispatch: any, data: any) => {
  return await dispatch(signinUser(data)).unwrap();
};
const SignIn = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [hiddenPassword, setHiddenPassword] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const clientId =
    "1059456331373-mq9e6dsm7rg95gnqqk9ic288d0emt7jl.apps.googleusercontent.com";
  const navigate = useNavigate();
  const status = useSelector((state: any) => state.Signin.signin.status);
  const error = useSelector((state: any) => state.Signin.signin.error);
  useEffect(() => {
    if (status === "success") {
      navigate("/");
    } else if (status === "failed" && error === "verifier votre compte") {
      setMessage(t('Veuillez_confirmer_votre_adresse_email'));
    } else if (status === "failed") {
      setMessage(t('Vérifier_votre_email_et_mot_de_passe'));
    }
  }, [status]);

  /* useEffect(()=>
  {
    function start(){
      gapi.auth2.init({
        clientId: clientId,
        scope:""
      })
    }
    gapi.load('client:auth2',start)
  })*/


  const handleEmailChange = (event:any) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Validate the email format
    const isValidEmail = checkIsValidEmail(newEmail);
    console.log('emailError',emailError)
    // Update the error state based on the validation result
    setEmailError(isValidEmail ? '' : t("Lemail_nest_pas_valide"));
  };
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };
  const googleOath = async (userData: any) => {
    await login(dispatch, userData);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let invalidForm = false;
    if (!email) {
      invalidForm = true;
      setEmailError(t('email_est_obligatoire'));
    } else {
      invalidForm = false;
      setEmailError("");
    }

    if (!password) {
      invalidForm = true;
      setPasswordError(t('Le_mot_de_passe_est_obligatoire'));
    } else {
      setPasswordError("");
    }
    if (invalidForm) {
      return;
    }
    let userData = {
      email: email,
      password: password,
    };
    await login(dispatch, userData);
  };

  return (
    <form dir={t("dir")}>
      <h3 className="text-center">{t('Connectez_vous')} </h3>
      <br />

      <div className="mb-3">
        <input
          type="email"
          className="form-control"
          placeholder={t("Email")}
          value={email}
          onChange={handleEmailChange}
        />
        <small className="text-danger mt-3 mb-3">{emailError}</small>
      </div>
      <div className="mb-3">
        {/* <input
            type="password" 
            className="form-control"
            placeholder=" Mot de passe"
            onChange={handlePasswordChange}
          />
           <small className="text-danger mt-3 mb-3">{passwordError}</small> */}
        <Input
          name="password"
          type={hiddenPassword ? "password" : "text"}
          placeholder={t('Mot_de_passe')}
          onChange={handlePasswordChange}
          error={passwordError}
          icon={
            hiddenPassword ? (
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye" aria-hidden="true"></i>
            )
          }
          onClickIcon={() => setHiddenPassword((current) => !current)}
        />
      </div>
      <p className="text-danger text-center">{message}</p>

      <a
        href={"/forgot-password"}
        target={"_blank"}
        className=" text-center link-secondary"
        style={{ fontWeight: "Bold" }}
      >
{t('Mot_de_passe_oublié')}      </a>
      <br />
      <hr />
      <div className="d-grid">
        <button className="btn btn_theme " onClick={handleSubmit}>
        {t('Connectez_vous')} 
        </button>
      </div>
      {status === "loading" && (
        <div className="mt-3" style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      )}
      <br />
      <a href="/register">{t("Inscrivez_vous_ici")} </a>
    </form>
  );
};
export default SignIn;
