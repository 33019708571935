import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import "./ProjectItem.css";
import moment from "moment";
import i18n from "i18next";
import { countriesArabe, regions2Arabe } from "../../utils/constants";
import _ from "lodash";
import { getFileService } from "../../services/FileService";
const ProjectItem = (props: any, key: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const [region, setRegion] = useState("");
  const [goal, setGoal] = useState(0);
  const [projectPicture, setProjectPicture] = useState("");
  const [dateValidationMessage, setDateValidationMessage] = useState('');

  type Translations = {
    'En cours': { ar: string };
    'à venir': { ar: string };
    'Expiré': { ar: string };
  };



  const goTo = async (_id: any) => {
    navigate(`/profile_project/${_id}`, { state: _id });
    console.log('id'+_id)
  };

  const getFileHandler = async (file: any) => {
    const { status, data } = await getFileService(file);
    if (status === 201 || status === 200) {
      setProjectPicture(
        config.apiUrl + "/project/getFile/" + props.campaign.project.photo
      );
    } else {
      setProjectPicture("assets/img/causes/causes-1.png");
    }
  };

  const dateValidationOfCamp= async (startDate: any,delay:any)=>{
    const today = new Date();
    const startDateCamp = new Date(startDate);
    const campDelay = new Date(delay)
    // Compare start date with today
    if (startDateCamp> today && campDelay >today) {
      return'à venir';
    } else if (startDateCamp<today && campDelay >today) {
      return 'En cours';
    }else{  return'Expiré';
  }
  }

  function translate(text: string, currentLanguage: string): string {
    const translations: Translations = {
      'En cours': { ar: 'جارٍ' },
      'à venir': { ar: 'قادم' },
      'Expiré': { ar: 'منتهي' },
    };

    if (currentLanguage === 'ar') {
      return translations[text as keyof Translations]?.ar || text;
    }
    return text;
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await dateValidationOfCamp(props.campaign.startDate, props.campaign.delay);
      setDateValidationMessage(result);
    };

    fetchData();
  }, [props.campaign.startDate, props.campaign.delay]);

  useEffect(() => {
    setGoal(
      props.campaign &&
        props.campaign.cureentCap !== undefined &&
        props.campaign.maxCap !== undefined
        ? Math.floor((props.campaign.cureentCap / props.campaign.maxCap) * 100)
        : 0
    );
    getFileHandler(props.campaign.project.photo);
  }, [props.campaign.project]);

  useEffect(() => {
    if (currentLanguage === "ar") {
      const allRegions = regions2Arabe.concat(countriesArabe);
      const res = _.find(allRegions, { _id: props.campaign.project.region });
      if (res) {
        setRegion(res.name);
      }
    } else {
      if (props.campaign.project) {
        setRegion(props.campaign.project.region);
      }
    }
    
    
  }, [props.campaign.project]);

  
  return (
    <>
      {props.campaign.project && (
        <div
        className="case_boxed_wrapper shadowClass"
        key={props.campaign.project._id}
      >
        <div className="case_boxed_img_container">
          <div className="case_boxed_img">
            <a
              className="pointer"
              onClick={() => {
                goTo(props.campaign.project._id);
              }}
            >
              <img src={projectPicture} alt="img" className="project-image" />
            </a>
          </div>

          {props.campaign.project.category && (
            <span className="causes_badge box_cat_home">
              {currentLanguage === "ar"
                ? props.campaign.project.category.arabicName
                : props.campaign.project.category.name}
            </span>
          )}
        </div>

          <div className="causes_boxed_text">
            {props.campaign.cureentCap !== undefined &&
              props.campaign.maxCap !== undefined && (
                <div className="class-full causes_pro_bar progress_bar box_goal">
                  <div className="class-full-bar-box">
                    <h3 className="h3-title">
                      {t("objectif")}:{" "}
                      <span>
                        {props.campaign.maxCap ? props.campaign.maxCap : 0}{" "}
                        {t("tnd")}
                      </span>
                    </h3>
                    <div
                      className="class-full-bar-percent"
                      style={{
                        right: t("dir") === "ltr" ? 0 : "unset",
                        left: t("dir") === "rtl" ? 0 : "unset",
                      }}
                    >
                      <h2>
                        <span className="counting-data" data-count={goal}>
                          {goal}
                        </span>
                        <span>%</span>
                      </h2>
                    </div>
                    <div
                      className="skill-bar class-bar"
                      data-width={goal + "%"}
                    >
                      <div
                        className="skill-bar-inner class-bar-in"
                        style={{
                          width: `${Math.min(goal, 100)}%`,
                          overflow: "hidden",
                          right: t("dir") === "rtl" ? 0 : "unset",
                          left: t("dir") === "ltr" ? 0 : "unset",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            <h3>
              <a
                className="pointer"
                onClick={() => {
                  goTo(props.campaign.project._id);
                }}
              >
                {currentLanguage === "fr"
                  ? props.campaign.project.frenchTitre
                    ? props.campaign.project.frenchTitre
                    : props.campaign.project.titre
                  : props.campaign.project.titre
                  ? props.campaign.project.titre
                  : props.campaign.project.frenchTitre}
              </a>
            </h3>
            <p className="description">
              {currentLanguage === "fr"
                ? props.campaign.project.frenchDescription
                  ? props.campaign.project.frenchDescription
                  : props.campaign.project.description
                : props.campaign.project.description
                ? props.campaign.project.description
                : props.campaign.project.frenchDescription}
            </p>
            <div className="causes_boxed_bottom_wrapper">
              <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="casuses_bottom_boxed casuses_left_padding">
                    <div className="casuses_bottom_icon">
                      <img src="assets/img/icon/map.png" alt="icon" />
                    </div>
                    <div className="casuses_bottom_content me-2">
                      <h5>{t("region")}</h5>
                      <p>{region}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="casuses_bottom_boxed">
                    <div className="casuses_bottom_icon">
                      <img src="assets/img/icon/cal.png" alt="icon" />
                    </div>
                    <div className="casuses_bottom_content me-2">
                      <h5>{t("Date")}</h5>
                      <p>
                        {moment(props.campaign.project.created_at).format(
                          "DD-MM-YYYY"
                        )}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <button onClick={() => {
                  goTo(props.campaign.project._id);
                }} className="card-button_recentP">Faire un don</button>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ProjectItem;
