import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './detailsPage.css';
import { useTranslation } from 'react-i18next';

const DetailsComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="donation-page">
      <header>
        <h1>{t("contribuez")}</h1>
      </header>
      <div className="content">
        <div className="text-content">
          <div className="section">
            <h2>{t("Innovation_permanente")}</h2>
            <p>{t("Innovation_permanente_p")}</p>
          </div>
          <div className="section">
            <h2>{t("Transparence_totale")}</h2>
            <p>{t("Transparence_totale_p")}</p>
          </div>
          <div className="section">
            <h2>{t("inclusion_accessibilité")}</h2>
            <p>{t("inclusion_accessibilité_p")}</p>
          </div>
          <div className="section">
            <h2>{t("protection_données_prso")}</h2>
            <p>{t("protection_données_prso_p")}</p>
          </div>
        </div>
        <div className="image-content">
          <img src="assets\img\detailsImg.png" alt="Fists illustration" />
        </div>
      </div>
      <footer>
      <Link to="/AllProjects"> 
      <button>{t("Faire_un_don")}</button>
      </Link>


      </footer>
    </div>
  );
};

export default DetailsComponent;
