import Api from "../config/axios";
import config from "../config/config";

export const DonationService = async (KonnectData :{}) => {
  const url = config.apiUrl + "/payme/Konnect";

  try {
    return await Api.post(url,   KonnectData  );
  } catch (error) {

    throw new Error("An error occured");
  }
};
export const addDonationService = async (DonationData: {}) => {
  const url = config.apiUrl + "/donation";

  try {
    return await Api.post(url,   DonationData  );
  } catch (error) {
    
    throw new Error("An error occured");
  }
};
export default {
  addDonationService,
  DonationService,
};
