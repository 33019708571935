import React from 'react';
import './TransparencyPage.css'; // Assuming you will create a CSS file for styles

const TransparencyPage = () => {
  return (
    <div className="transparency-page">
    <div className="line top-line">
    <img src="assets\img\about\Rectangle 7.png" alt="line"  />
    </div>
    <div className="content">
      <div className="text">
        <h1 className="title">La transparence</h1>
        <h1 className="soustitle"> est notre engagement.</h1>
        <p className="description">
          Avec la technologie blockchain, chaque pas est enregistré, chaque don est traçable. 
          Une confiance solide, étape par étape.
        </p>
        <p className="description">
          Contribuez en toute confiance, avec la certitude que chaque don est suivi de près.
        </p>
      </div>
      <div className="illustration">
        <img src="assets\img\TransparencyPage.png" alt="Illustration" />
      </div>
    </div>
    <div className="line bottom-line">
       <img src="assets\img\about\Rectangle 7.png" alt="line"  />
    </div>
  </div>

  );
}

export default TransparencyPage;
