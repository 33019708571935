import { useState } from "react";
import Input from "../../../../components/Input/Input";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import Spinner from "../../../../components/Spinner/Spinner";
import { useSelector } from "react-redux";
const FormStep31 = (props: any) => {
  const { t } = useTranslation();
  const status = useSelector(
    (state: any) => state.Project.addProject.status
  );
  const [addCofounder, setAddCoFounder] = useState(
    props.project.addCofounder !== undefined
      ? props.project.addCofounder
        ? "true"
        : "false"
      : "true"
  );
  const [coFounder, setCoFounder] = useState(
    props.project.coFounder ? props.project.coFounder : ""
  );
  const [coFounderError, setCoFounderError] = useState("");
  const handleAddCoFounderChange = (event: any) => {
    setAddCoFounder(event.target.value);
    if (event.target.value === "false") {
      setCoFounderError("");
    }
  };
  const handleCoFounderChange = (event: any) => {
    setCoFounder(event.target.value);
  };
  const validateHandler = (e: any) => {
    e.preventDefault();
    if (addCofounder === "true" && !coFounder) {
      setCoFounderError(t("Ce_champ_est_obligatoire"));
      return;
    } else {
      setCoFounderError("");
    }
    props.project.addCofounder = addCofounder === "true" ? true : false;
    props.project.coFounder = coFounder;
    props.updateProject(props.project);
    props.validate();
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(7)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h3 className="text-center mb-5">{t("Finaliser")}</h3>
        <span className="mt-4 mb-4">{t("ajouter_Co_fondateur")}</span>
        <div className="col-12">
          <SelectInput
            selectedCategory={addCofounder}
            selectList={[
              { _id: true, name: t("yes") },
              { _id: false, name: t("no") },
            ]}
            onChange={handleAddCoFounderChange}
          />
        </div>
        <span>{t("Ajouter_un_co_fondateur")}: </span>
        <div className="col-lg-12">
          <Input
            name="cofondateur"
            type="text"
            placeholder={t("Co_fondateur")}
            onChange={handleCoFounderChange}
            error={coFounderError}
            disabled={addCofounder === "false"}
            value={coFounder}
          />
        </div>
        <div
          className="mt-4"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button className="btn btn_theme" onClick={validateHandler} disabled={status === "loading"}>
            {t("Valider")}
          </button>
        </div>
        {status === "loading" && <Spinner/>}
      </div>
    </>
  );
};
export default FormStep31;
