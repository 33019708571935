import { useEffect, useState } from "react";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import SelectFileButton from "../../../../components/SelectFileBtn/SelectFileBtn";
import config from "../../../../config/config";
import { useTranslation } from "react-i18next";
import { getFileService } from "../../../../services/FileService";

const ProjectVideo = (props: any) => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [videos, setVideos] = useState<File[]>([]);
  const [video, setVideo] = useState("");
  const handleVideosChange = (file: any) => {
    const files = file.target.files;
    setVideos([...files]);
  };
  const removeVideo = (index: number) => {
    const res = [...videos];
    res.splice(index, 1);
    setVideos(res);
  };
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const hideEditHandler = () => {
    setShowEdit(false);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  const validate = async () => {
    const filesToAdd = [...videos];
    const data = { id: props.id, files: filesToAdd };
    props.uploadFilesHandler(data);
    setShowEdit(false);
  };
  const getFileHandler = async (file: any) => {
    const { status, data } = await getFileService(file);
    if (status === 201 || status === 200) {
      setVideo(
        config.apiUrl + "/project/getFile/" + file
      );
    } else {
      setVideo("");
    }
  };
  useEffect(() => {
    getFileHandler(props.video);
  }, [props.video]);
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{props.title}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
        {showEdit && props.canEdit && (
          <i
            className={"fa fa-times-circle pointer"}
            style={{
              color: "#818090",
            }}
            onClick={hideEditHandler}
          ></i>
        )}
      </div>
      {video && !showEdit && (
        <div className="row popup-gallery mt-3">
          <div className="col-12">
            <video
              src={config.apiUrl + "/project/getFile/" + props.video}
              style={{ height: "200px" }}
              controls
            ></video>
          </div>
        </div>
      )}
      {!video && !showEdit && (
        <div className="row popup-gallery mt-3">
          <h5>{t("Aucune_vidéo")}</h5>
        </div>
      )}
      {showEdit && (
        <div className="mt-3">
          <SelectFileButton
            title={"Télécharger"}
            onChange={handleVideosChange}
            accept={"video/*"}
          />

          {videos.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {videos.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeVideo(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectVideo;
