import Api from "../config/axios";
import config from "../config/config";

export const addInvestorAgent = async (data: any) => {
  const url = config.apiUrl + "/agentinvestor";

  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const uploadDocuments = async (data: any) => {
  const url = config.apiUrl + "/agentinvestor/adddocs/" + data.id;
  const formData = new FormData();
  data.files.forEach((file: File) => formData.append("docs", file));
  try {
    return await Api.post(url, formData);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getInvestors = async (data: any) => {
  const url = config.apiUrl + "/investors/getbyName";

  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getInvestorAgentDetails = async (id: string) => {
  const url = config.apiUrl + "/agentinvestor/" + id;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const editInvestorAgent = async (userData: any) => {
  const url = config.apiUrl + "/agentinvestor/UpdateEntreprisebyID/" + userData.id;

  try {
    return await Api.put(url, userData);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const editCorporatePhoto = async (corporateData: any) => {
  const url = config.apiUrl + "/agentinvestor/uploadlogo/" + corporateData.id;
  try {
    const formData = new FormData();
    corporateData.files.forEach((file: File) => formData.append("logo", file));
    try {
      return await Api.post(url, formData);
    } catch (error) {
      throw new Error("An error occured");
    }
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  addInvestorAgent,
  uploadDocuments,
  getInvestors,
  getInvestorAgentDetails,
  editInvestorAgent,
  editCorporatePhoto
};
