import "./FormCheck.css";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
const FormCheck = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      {" "}
      <div className="form-group mb-3" style={{ display: "flex" }}>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            onChange={() => props.onChange("Entreprise")}
            checked={props.type === "Entreprise"}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            {t('Entreprise')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            checked={props.type === "ONG"}
            onChange={() => props.onChange("ONG")}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
          {t('ONG')}

          </label>
        </div>
      </div>
      <small className="text-danger mt-3 mb-3">{props.error}</small>
    </div>
  );
};
export default FormCheck;
