import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectService from "../services/ProjectService";
export const getBestProjects = createAsyncThunk(
  "project/getBestProjects",
  async (x: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.getBestProjects(x);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectsByUser = createAsyncThunk(
  "project/get-projects-by-user",
  async (x: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.getProjectsByUserService(x);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getcategorys = createAsyncThunk(
  "project/get-category",
  async (id: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.getcategory(id);
      if (status === 201 || status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllProjects = createAsyncThunk(
  "project/get-All-projects",
  async (filterData: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.getAllProjects(filterData);
      if (status === 201 || status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getSupportedProjectsByUser = createAsyncThunk(
  "project/get-supported-projects-by-user",
  async (token: any, thunkAPI) => {
    try {
      const { status, data } =
        await ProjectService.getSupportedProjectsByUserService(token);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addProject = createAsyncThunk(
  "project/add",
  async (projectData: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.addProjectService(
        projectData
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadProjectFiles = createAsyncThunk(
  "project/ulpoad-files",
  async (uploadData: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.uploadProjectFiles(
        uploadData.id,
        uploadData.files
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateProject = createAsyncThunk(
  "project/update",
  async (projectData: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.updateProjectService(
        projectData
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  bestProjects: {
    status: "idle",
    data: null,
    error: {},
  },
  projects: {
    status: "idle",
    data: null,
    error: {},
  },
  supportedProjects: {
    status: "idle",
    data: null,
    error: {},
  },
  addProject: {
    status: "idle",
    data: null,
    error: {},
  },
  uploadFiles: {
    status: "idle",
    data: null,
    error: {},
  },
  getProjects: {
    status: "idle",
    data: null,
    error: {},
  },
  getcategory: {
    status: "idle",
    data: null,
    error: {},
  },
  updateProject: {
    status: "idle",
    data: null,
    error: {},
  },
};
const ProjectSlice = createSlice({
  name: "get-projects-by-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getProjectsByUser.pending.type]: (state) => {
      state.projects = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getProjectsByUser.fulfilled.type]: (state, action) => {
      state.projects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectsByUser.rejected.type]: (state, action) => {
      state.projects = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getSupportedProjectsByUser.pending.type]: (state) => {
      state.supportedProjects = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getSupportedProjectsByUser.fulfilled.type]: (state, action) => {
      state.supportedProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getSupportedProjectsByUser.rejected.type]: (state, action) => {
      state.supportedProjects = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [addProject.pending.type]: (state) => {
      state.addProject = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [addProject.fulfilled.type]: (state, action) => {
      state.addProject = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addProject.rejected.type]: (state, action) => {
      state.addProject = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [uploadProjectFiles.pending.type]: (state) => {
      state.uploadFiles = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [uploadProjectFiles.fulfilled.type]: (state, action) => {
      state.uploadFiles = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [uploadProjectFiles.rejected.type]: (state, action) => {
      state.uploadFiles = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getAllProjects.pending.type]: (state) => {
      state.getProjects = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getAllProjects.fulfilled.type]: (state, action) => {
      state.getProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllProjects.rejected.type]: (state, action) => {
      state.getProjects = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getcategorys.pending.type]: (state) => {
      state.getcategory = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getcategorys.fulfilled.type]: (state, action) => {
      state.getcategory = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getcategorys.rejected.type]: (state, action) => {
      state.getcategory = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [updateProject.pending.type]: (state) => {
      state.updateProject = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateProject.fulfilled.type]: (state, action) => {
      state.updateProject = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateProject.rejected.type]: (state, action) => {
      state.updateProject = {
        status: "failed",
        data: action.payload,
        error: action.payload,
      };
      
    },
    [getBestProjects.pending.type]: (state) => {
      state.bestProjects = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getBestProjects.fulfilled.type]: (state, action) => {
      state.bestProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getBestProjects.rejected.type]: (state, action) => {
      state.bestProjects = {
        status: "failed",
        data: action.payload,
        error: action.payload,
      };
    }
  },
});

export default ProjectSlice.reducer;
