import Historique from "./Historique";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";

const Wallet = (props: any) => {
  const { t } = useTranslation();
  console.log(props.Transactions,"hehehe")
  return (
    <>
      {  props.Transactions && props.Transactions.value.length  > 0 && (
        <div className="mt-5">
           <div className="row mb-4">
        <div className="col-4">
          <span>{t("Montant_Total")}</span>
          <h2>{props.Transactions.Info[0].sommeDonation}</h2>
        </div>
        <div
          className="col-4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            className="fa fa-exchange-alt"
            aria-hidden="true"
            style={{ fontSize: "50px" }}
          ></i>
        </div>
        <div
          className="col-4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <span>{t('Solidarity_Token')}</span>
          <h2>{props.Transactions.Info[0].SolidarityToken}</h2>
        </div>
      </div>
          <div>
            <h5>{t("Historique_des_transactions")}</h5>
            <table className="mt-4 table">
              <thead>
                <tr>
                  <th scope="col">{t("Nom")}</th>
                  <th scope="col"></th>
                  <th scope="col">
                    {t("Date")}{" "}
                    <i
                      className="fa fa-sort-amount-down"
                      aria-hidden="true"
                    ></i>
                  </th>
                  <th scope="col">
                    {t("Montant")}{" "}
                    <i
                      className="fa fa-sort-amount-down"
                      aria-hidden="true"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.Transactions.value.map((item: any) => (
                  <Historique Historique={item} />
                ))}
              </tbody>
            </table>
          </div>
          <span>wallet : {props.Transactions.walletAddress}</span>

        </div>
      )}
      {props.Transactions && props.Transactions.value.length === 0 && (
        <p className="mt-4">{t("no_transcations")}</p>
      )}
    </>
  );
};
export default Wallet;
