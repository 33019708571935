import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { project } from "../../../../reducers/projectDetails";
import { useDispatch, useSelector } from "react-redux";
import RecentDonation from "./recentDonation";
import jwt_decode from "jwt-decode";
import InformationUsers from "./informationUsers";
import { FooterComponent } from "../../../../components/footer";
import "../../../../translations/i18n";
import CoverPicture from "../../../donator/Profile/components/CoverPicture";
import ProjectImages from "./ProjectImages";
import ProjectVideo from "./ProjectVideo";
import ProjectCampaign from "./ProjectCampaign";
import ProjectInfos from "./ProjectInfos";
import Links from "./Links";
import ProjectEmployees from "./ProjectEmployees";
import ProjectDetails from "./ProjectDetails";
import ProjectCollaborators from "./ProjectCollaborators";
import ProjectDescription from "./ProjectDescription";
import {
  updateProject,
  uploadProjectFiles,
} from "../../../../reducers/project";
import { useTranslation } from "react-i18next";
import ProjectDocument from "./ProjectDocument";
import i18n from "i18next";

const updateProjectAction = async (dispatch: any, projectData: any) => {
  return await dispatch(updateProject(projectData)).unwrap();
};
const uploadProjectFilesAction = async (formData: any, dispatch: any) => {
  return await dispatch(uploadProjectFiles(formData)).unwrap();
};
const ProfileProject = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const projectInformations = async (dispatch: any, data: any) => {
    return await dispatch(project(data)).unwrap();
  };
  const dispatch = useDispatch();
  const [currentUserId, setCurrentuserId] = useState("");
  const [reload, setReload] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const projectData = useSelector(
    (state: any) => state.projectDetails.Project.data
  );
  const donator = useSelector((state: any) =>
    state.Donation.getdonation.data ? state.Donation.getdonation.data.value : []
  );
  const status = useSelector(
    (state: any) => state.projectDetails.Project.status
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const res: any = jwt_decode(token);
      const id = res.id;
      setCurrentuserId(id);
    }
    projectInformations(dispatch, id);
    setReload(false);
  }, [reload]);

  useEffect(() => {
    if (projectData && projectData.value) {
      if (projectData.value.porteur._id === currentUserId) {
        setCanEdit(true);
      }
    }
  }, [projectData]);

  const updateProjectHandler = async (projectData: any) => {
    await updateProjectAction(dispatch, projectData);
    setReload(true);
  };

  const uploadProjectFilesHandler = async (projectData: any) => {
    await uploadProjectFilesAction(projectData, dispatch);
    setReload(true);
  };
  return (
    <>
      {status == "success" && (
        <div className="mb-5" dir={t("dir")}>
          <CoverPicture />
          <section id="trending_causes_main" className="section_padding">
            <div className="container">
              <div className="row" id="counter">
                <div className="col-lg-8">
                  <ProjectDescription
                    project={projectData.value}
                    updateProjectHandler={updateProjectHandler}
                    canEdit={canEdit}
                  />
                  <ProjectImages
                    title={t('Images')}
                    photo={projectData.value.photo}
                    id={projectData.value._id}
                    uploadFilesHandler={uploadProjectFilesHandler}
                    canEdit={canEdit}
                  />
                  <ProjectVideo
                    title={t("video")}
                    video={projectData.value.video}
                    id={projectData.value._id}
                    uploadFilesHandler={uploadProjectFilesHandler}
                    canEdit={canEdit}
                  />
                  <ProjectDocument
                    title={t("Document")}
                    file={projectData.value.PDF}
                    id={projectData.value._id}
                    uploadFilesHandler={uploadProjectFilesHandler}
                    canEdit={canEdit}
                  />
                  <ProjectDetails
                    title={t('DurabilitéTitle')}
                    response={projectData.value.durable}
                    description={projectData.value.durableDetails}
                    frenchDescription={projectData.value.frenchdurableDetails}
                    updateProjectHandler={updateProjectHandler}
                    project={projectData.value}
                    canEdit={canEdit}
                  />
                  <ProjectDetails
                    title={t("UnicitéTitle")}
                    response={projectData.value.isUnique ? t("yes") : t("no")}
                    description={projectData.value.uniqueDetails}
                    frenchDescription={projectData.value.frenchuniqueDetails}
                    updateProjectHandler={updateProjectHandler}
                    project={projectData.value}
                    canEdit={canEdit}
                  />
                  <ProjectDetails
                    title={t("InnovationTitle")}
                    response={projectData.value.isInnovative ? t("yes") : t("no")}
                    description={projectData.value.innovationDetails}
                    frenchDescription={
                      projectData.value.franchinnovationDetails
                    }
                    updateProjectHandler={updateProjectHandler}
                    project={projectData.value}
                    canEdit={canEdit}
                  />
                  <ProjectDetails
                    title={t("Impact_sur_environnement")}
                    response={projectData.value.impactOnEnviroment}
                    description={projectData.value.impactOnEnviromentDetails}
                    frenchDescription={
                      projectData.value.frenchimpactOnEnviromentDetails
                    }
                    updateProjectHandler={updateProjectHandler}
                    project={projectData.value}
                    canEdit={canEdit}
                  />
                  <ProjectDetails
                    title={t('Valeur_communauté')}
                    response={projectData.value.addedValueToCommunity}
                    description={projectData.value.addedValueToCommunityDetails}
                    frenchDescription={
                      projectData.value.frenchaddedValueToCommunityDetails
                    }
                    updateProjectHandler={updateProjectHandler}
                    project={projectData.value}
                    canEdit={canEdit}
                  />
                </div>

                <div className="col-lg-4">
                  <ProjectCampaign
                    projectData={projectData}
                    id={id}
                    status={status}
                    currentUserId={currentUserId}
                  />
                  <InformationUsers project={projectData.value} />
                  <ProjectInfos
                    project={projectData.value}
                    donator={donator}
                    updateProjectHandler={updateProjectHandler}
                    canEdit={canEdit}
                    currentLanguage={currentLanguage}
                  />
                  <ProjectEmployees
                    project={projectData.value}
                    updateProjectHandler={updateProjectHandler}
                    canEdit={canEdit}
                  />
                  <ProjectCollaborators
                    project={projectData.value}
                    updateProjectHandler={updateProjectHandler}
                    canEdit={canEdit}
                  />
                  <Links
                    project={projectData.value}
                    updateProjectHandler={updateProjectHandler}
                    canEdit={canEdit}
                  />
                  <RecentDonation id={id} />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <FooterComponent />
    </>
  );
};

export default ProfileProject;
