const CoverPicture = (props: any) => {
  return (
    <section id="common_banner_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="commn_banner_page"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoverPicture;
