import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  getCampaign,
  updateCampaign,
  uploadCampaignFiles,
} from "../../../../reducers/campaign";
import { FooterComponent } from "../../../../components/footer";
import CoverPicture from "../../../donator/Profile/components/CoverPicture";
import CampaignInfos from "./CampaignInfos";
import RecentDonation from "./recentDonation";
import ProjectImages from "../../../project/profileProject/components/ProjectImages";
import ProjectDocument from "../../../project/profileProject/components/ProjectDocument";
import ProjectVideo from "../../../project/profileProject/components/ProjectVideo";
import { useTranslation } from "react-i18next";
import CampaignDescription from "./CampaignDescription";
import CampaignObjectif from "./CampaignObjectif";

const getCampaignAction = async (id: any, dispatch: any) => {
  return await dispatch(getCampaign(id)).unwrap();
};
const updateCampaignAction = async (dispatch: any, campaignData: any) => {
  return await dispatch(updateCampaign(campaignData)).unwrap();
};
const uploadCampaignFilesAction = async (formData: any, dispatch: any) => {
  return await dispatch(uploadCampaignFiles(formData)).unwrap();
};
const ProfileCampaign = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentUserId, setCurrentuserId] = useState("");
  const [reload, setReload] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const project = useSelector(
    (state: any) => state.projectDetails.Project.data
  );
  const CampaignF = useSelector(
    (state: any) => state.Campaign.getCampaign.data
  );
  const status = useSelector((state: any) => state.Campaign.getCampaign.status);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const res: any = jwt_decode(token);
      const id = res.id;
      setCurrentuserId(id);
    }
    getCampaignAction(id, dispatch);
  }, [id, reload]);
  const updateCampaignHandler = async (campaignData: any) => {
    await updateCampaignAction(dispatch, campaignData);
    setReload(true);
  };
  const uploadCampaignFilesHandler = async (projectData: any) => {
    await uploadCampaignFilesAction(projectData, dispatch);
    setReload(true);
  };
  useEffect(() => {
    if (project && project.value) {
      const token = localStorage.getItem("token");
      if (token) {
        const res: any = jwt_decode(token);
        const id = res.id;
        if (project.value.porteur._id === id) {
          setCanEdit(true);
        }
      }
    }
  }, [project]);
  return (
    <>
      {CampaignF && CampaignF.value && CampaignF.value[0] && (
        <div className="mb-5" dir={t("dir")}>
          <CoverPicture />
          {status == "success" && (
            <section id="trending_causes_main" className="section_padding">
              <div className="container">
                <div className="row" id="counter">
                  <div className="col-lg-8">
                    <CampaignDescription
                      campaign={CampaignF.value[0]}
                      updateCampaignHandler={updateCampaignHandler}
                      canEdit={canEdit}
                    />
                    <ProjectImages
                      title={t('Images')}
                      photo={CampaignF.value[0].image}
                      id={CampaignF.value[0]._id}
                      uploadFilesHandler={uploadCampaignFilesHandler}
                      canEdit={canEdit}
                    />
                    <ProjectVideo
                      title={t('video')}
                      video={CampaignF.value[0].video}
                      id={CampaignF.value[0]._id}
                      uploadFilesHandler={uploadCampaignFilesHandler}
                      canEdit={canEdit}
                    />
                    <ProjectDocument
                      title={t("Document")}
                      file={CampaignF.value[0].pdf}
                      id={CampaignF.value[0]._id}
                      uploadFilesHandler={uploadCampaignFilesHandler}
                      canEdit={canEdit}
                    />
                  </div>
                  <div className="col-lg-4">
                    <CampaignObjectif CampaignF={CampaignF.value[0]} id={id} />
                    <CampaignInfos
                      campaign={CampaignF.value[0]}
                      updateCampaignHandler={updateCampaignHandler}
                      canEdit={canEdit}
                    />
                    <RecentDonation id={CampaignF.value[0]._id} />
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
      <FooterComponent />
    </>
  );
};

export default ProfileCampaign;
