import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import PartnerItem from "../../../components/PartnerItem/PartnerItem";

const Partners = () => {
  const plugins = [
    "infinite",
    {
      resolve: autoplayPlugin,
      options: {
        interval: 2000,
      },
    },
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides: 4,
      },
    },
  ];
  const breakpoints = {
    640: {
      plugins: [
        "infinite",
        {
          resolve: autoplayPlugin,
          options: {
            interval: 2000,
          },
        },
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 2,
          },
        },
      ],
    },
    900: {
      plugins: [
        "infinite",
        {
          resolve: autoplayPlugin,
          options: {
            interval: 2000,
          },
        },
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 3,
          },
        },
      ],
    },
  };
  return (
    <section id="partner_area" className="pt-5 pb-5">
      <div className="container">
      <div style={{ textAlign: 'center', fontSize: '20px', padding:'1%',marginBottom:'2%' }}> <h4>Ant'like est un projet conçu,  appuyé et mis en œuvre par le bureau régional de la Fondation Hanns
      Seidel à Tunis (Tunisie, Algérie, Libye), en collaboration avec l'association Nova CD².</h4></div>
       
        <div className="row">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              justifyContent: "center",
              flexFlow: "row",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <Carousel
              plugins={plugins}
              breakpoints={breakpoints}
              animationSpeed={2000}
            >
             
              <PartnerItem image={"company3.png"}/>
              <PartnerItem image={"company4.png"}/>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
