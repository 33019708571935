import Api from "../config/axios";
import config from "../config/config";

export const getProjectsByUserService = async (data: any) => {
  const url = config.apiUrl + "/project/getByPorteur/" + data.id;
  const paginationConfig: any = {
    page: data.page,
    pageSize: data.pageSize,
  };
  try {
    return await Api.get(url, {
      params: paginationConfig,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getAllProjects = async (filterData: any) => {
  const url = config.apiUrl + "/project/"; //http://localhost:3030/project/
  try {
    return await Api.get(url, {
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getBestProjects = async (filterData: any) => {
  const url = config.apiUrl + "/project/Get/BestProject/";
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectbyId = async (token: any) => {
  const url = config.apiUrl + "/project/" + token;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getcategory = async (id: any) => {
  const url = config.apiUrl + `/categoryproj/${id}`;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getSupportedProjectsByUserService = async (token: any) => {
  const url = config.apiUrl + "/project/getByDonation/" + token;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const addProjectService = async (data: any) => {
  const url = config.apiUrl + "/project/";

  try {
    return await Api.post(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const uploadProjectFiles = async (idProject: any, files: any) => {
  const url = config.apiUrl + "/project/adddocs/" + idProject;
  const formData = new FormData();
  files.forEach((file: File) => formData.append("docs", file));
  try {
    return await Api.post(url, formData);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateProjectService = async (data: any) => {
  const url = config.apiUrl + "/project/" + data._id;

  try {
    return await Api.put(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  getProjectsByUserService,
  getSupportedProjectsByUserService,
  addProjectService,
  uploadProjectFiles,
  getAllProjects,
  getcategory,
  getProjectbyId,
  updateProjectService,
  getBestProjects
};
