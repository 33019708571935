import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { informationUser } from "../../../../reducers/projectDetails";
import UserInformation from "../../../../components/UserInformation/UserInformation";

const informations = async (dispatch: any, data: any) => {
  return await dispatch(informationUser(data)).unwrap();
};
const InformationUsers = (props: any) => {
  const dispatch = useDispatch();
  const information = useSelector(
    (state: any) => state.projectDetails.information.data
  );

  useEffect(() => {
    informations(dispatch, props.project.porteur._id);
  }, []);

  return <UserInformation information={information.value}></UserInformation>;
};
export default InformationUsers;
