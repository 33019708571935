import { useState } from "react";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { wordCounter } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep23 = (props: any) => {
  const { t } = useTranslation();

  const [isUnique, setSetUnique] = useState(
    props.project.isUnique !== undefined
      ? props.project.isUnique
        ? "true"
        : "false"
      : "true"
  );
  const [uniqueDetails, setUniqueDetails] = useState(
    props.project.uniqueDetails ? props.project.uniqueDetails : ""
  );
  const [uniqueDetailsError, setUniqueDetailsError] = useState("");

  const handleIsUniqueChange = (event: any) => {
    setSetUnique(event.target.value);
    if (event.target.value === "false") {
      setUniqueDetailsError("");
      setUniqueDetails("");
    }
  };
  const handleUniqueDetailsChange = (event: any) => {
    setUniqueDetails(event.target.value);
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();

    if (isUnique === "true" && !wordCounter(uniqueDetails, 10)) {
      setUniqueDetailsError(t("Veuillez_taper_au_moins_10_mots"));
      return;
    } else {
      setUniqueDetailsError("");
    }
    props.project.isUnique = isUnique === "true" ? true : false;
    props.project.uniqueDetails = uniqueDetails;
    props.updateProject(props.project);
    props.nextHandler(5);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(3)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">{t("Unicité")}</h5>
        <span className="mt-4 mb-4">{t("unique")}</span>
        <div className="col-12">
          <SelectInput
            selectedCategory={isUnique}
            selectList={[
              { _id: true, name: t("yes") },
              { _id: false, name: t("no") },
            ]}
            onChange={handleIsUniqueChange}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("précisions")}
            onChange={handleUniqueDetailsChange}
            error={uniqueDetailsError}
            disabled={isUnique === "false"}
            value={uniqueDetails}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep23;
