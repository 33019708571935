import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProjectItem from "../../../../components/ProjectItem/ProjectItem";
import { getSupportedProjectsByUser } from "../../../../reducers/project";

const getSupportedProjectsByUserAction = async (dispatch: any, data: any) => {
  return await dispatch(getSupportedProjectsByUser(data)).unwrap();
};
const SupportedProjects = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(
    (state: any) => state.Project.supportedProjects.status
  );
  const supportedProjects = useSelector(
    (state: any) => state.Project.supportedProjects.data
  );

  const getSupportedProjectsByUser = async () => {
    const token = props.currentUserId;
    await getSupportedProjectsByUserAction(dispatch, token);
  };

  useEffect(() => {
    getSupportedProjectsByUser();
  }, []);

  return (
    <div className="mt-5">
      <div className="row" id="counter">
        {status == "success" &&
          supportedProjects.value.length > 0 &&
          supportedProjects.value.map((item: {}) => {
            return (
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                <ProjectItem campaign={item} />
              </div>
            );
          })}
        {status == "success" && supportedProjects.value.length === 0 && (
          <p className="mt-2">{t("Aucun_Projet")}</p>
        )}
      </div>
    </div>
  );
};
export default SupportedProjects;
