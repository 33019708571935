import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuadraticCampaignsByCorporate } from "../../../../reducers/quadraticCampaign";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import { regions, regionsArabe } from "../../../../utils/constants";
import _ from "lodash";
import i18n from "../../../../translations/i18n";

const getQuadraticCampaignsByCorporateAction = async (
  dispatch: any,
  id: string
) => {
  return await dispatch(getQuadraticCampaignsByCorporate(id)).unwrap();
};

const QuadraticCampaigns = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const quadraticCampaigns = useSelector(
    (state: any) => state.QuadraticCampaign.quadraticCampaignsByCorporate.data
  );
  const getQuadraticCampaignsByCorporateHandler = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res: any = jwt_decode(token);
      const id = res.id;
      await getQuadraticCampaignsByCorporateAction(dispatch, id);
    }
  };

  useEffect(() => {
    getQuadraticCampaignsByCorporateHandler();
  }, []);
  const getRegionName = (region: any) => {
    const x = _.find(regionsArabe, { _id: region });
    if (x && currentLanguage === "ar") {
      return x.name;
    } else {
      return region;
    }
  };
  return (
    <div className="mt-5">
      {quadraticCampaigns && quadraticCampaigns.value.length > 0 && (
        <div className="row">
          {quadraticCampaigns.value.map((item: any, index: any) => (
            <div className="col-6" key={index}>
              <div
                className="card mb-2 pointer"
                onClick={() => navigate("/quadratic-campaign:" + item._id)}
              >
                {item.status === "Created" && (
                  <div className="card-header">{t("attente")}</div>
                )}
                {item.status === "Simulation" && (
                  <div className="card-header">{t("Simulé")} </div>
                )}
                {item.status === "ACCEPTED" && (
                  <div className="card-header">{t("Accepté")} </div>
                )}
                {item.status === "REFUSED" && (
                  <div className="card-header">{t("Refusé")}</div>
                )}

                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {t("Date")}: {moment(item.date).format("DD-MM-YYYY")}
                  </li>
                  <li className="list-group-item">
                    {t("Montant")}: {item.montant} {t("tnd")}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    {t("regions")}:{" "}
                    {item.region.length > 0 &&
                      item.region.map((item: string, index: any) => (
                        <span key={index} className="item-1 me-1">
                          {getRegionName(item)}
                        </span>
                      ))}{" "}
                    {item.region.length === 0 && <span>{t("aucun")}</span>}
                  </li>
                  <li className="list-group-item">
                    {t("Secteurs")}:{" "}
                    {item.categories.length > 0 &&
                      item.categories.map((item: any, index: any) => (
                        <span key={index} className="item-1 me-1">
                          {item.name}
                        </span>
                      ))}
                    {item.categories.length === 0 && <span>{t("aucun")}</span>}
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
      {quadraticCampaigns && quadraticCampaigns.value.length === 0 && (
        <div className="row">
          <div>{t("Auncu_campagne_quadratique")}</div>
        </div>
      )}
    </div>
  );
};
export default QuadraticCampaigns;
