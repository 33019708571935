import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkIsValidEmail,
  checkIsValidPassword,
} from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import FormCheck from "./FormCheck";
import SelectFileButton from "../../../components/SelectFileBtn/SelectFileBtn";
import FormCheck2 from "./FormCheck2";
import {
  addInvestorAgent,
  getInvestors,
  uploadDocuments,
} from "../../../reducers/investorAgent";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import SelectInput from "../../../components/SelectInput/SelectInput";
import Spinner from "../../../components/Spinner/Spinner";

const addInvestorAgentAction = async (dispatch: any, data: any) => {
  return await dispatch(addInvestorAgent(data)).unwrap();
};
const uploadDocumentsAction = async (dispatch: any, data: any) => {
  return await dispatch(uploadDocuments(data)).unwrap();
};
const getInvestorsAction = async (dispatch: any, data: any) => {
  return await dispatch(getInvestors(data)).unwrap();
};
const EntrepriseSignUpForm = (props: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hiddenRePassword, setHiddenRePassword] = useState(true);
  const [type, setType] = useState("Entreprise");
  const [rne, setRne] = useState<File[]>([]);
  const [justificatif, setJustificatif] = useState<File[]>([]);
  const [cinRecto, setCinRecto] = useState<File[]>([]);
  const [cinVerso, setCinVerso] = useState<File[]>([]);
  const [role, setRole] = useState("");

  const [emailError, setEmailError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [rneError, setRneError] = useState("");
  const [justificatifError, setJustificatifError] = useState("");
  const [cinRectoError, setCinRectoError] = useState("");
  const [cinVersoError, setCinVersoError] = useState("");
  const [next, setNext] = useState(false);
  const [roleError, setRoleError] = useState("");

  const [newEntreprise, setNewEntreprise] = useState(false);
  const [showVerificationEmailMessage, setShowVerificationEmailMessage] =
    useState(false);
  const status = useSelector(
    (state: any) => state.InvestorAgent.addInvestorAgent.status
  );
  const error = useSelector(
    (state: any) => state.InvestorAgent.addInvestorAgent.error
  );
  const result = useSelector(
    (state: any) => state.InvestorAgent.addInvestorAgent.data
  );
  const statusUpload = useSelector(
    (state: any) => state.InvestorAgent.uploadDocuments.status
  );

  const investors = useSelector(
    (state: any) => state.InvestorAgent.investors.data
  );

  useEffect(() => {
    if (status === "success" && statusUpload === "success") {
      setShowVerificationEmailMessage(true);
    } else if (status === "failed" && error === "email déja existe") {
      setEmailError("Email existe déjà");
    } else if (status === "failed" || statusUpload === "failed") {
      setMessage("Une erreur est survenue");
    }
  }, [status, statusUpload]);

  const handleEmailChange = (event: any) => {
    const newEmail = event.target.value;
    if (!newEmail.trim()) {
      setEmailError(t("Ce_champ_est_obligatoire"));
    } else {
      const isValidEmail = checkIsValidEmail(newEmail);
      setEmailError(isValidEmail ? '' : t("Lemail_nest_pas_valide"));
    }
    setEmail(newEmail);
  };

  const handleFullNameChange = (event: any) => {
    const newFullName = event.target.value;
    if (!newFullName.trim()) {
      setFullNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setFullNameError("");
    }
    setFullName(newFullName);
  };
  const handleNameChange = async (event: any) => {
    const newName = event.target.value;
    if (!newName.trim()) {
      setNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setNameError("");
    }
    setName(newName);
  };

  const newEntrepriseHandler = (e: any, x: boolean) => {
    e.preventDefault();
    setNewEntreprise(x);
    setName("");
  };

  const getInvestorsHandler = async () => {
    const data = { name: "" };
    await getInvestorsAction(dispatch, data);
  };

  useEffect(() => {
    getInvestorsHandler();
  }, []);

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    // Check if the password is empty
    if (!newPassword.trim()) {
      // Show a required message or perform any necessary action
      setPasswordError(t("Ce_champ_est_obligatoire"));
    } else {
      // Validate the password format
      const isValidPassword = checkIsValidPassword(newPassword);
  
      // Update the error state based on the validation result
      setPasswordError(isValidPassword ? '' : t("mdp"));
    }
  
    // Set the password state with the entered value
    setPassword(newPassword);
  };
  

  
  const handleRePasswordChange = (event: any) => {
    setRePassword(event.target.value);
    // Check if the passwords match
    const passwordsMatch = event.target.value === password;
    // Update the error state based on the validation result
    setRePasswordError(passwordsMatch ? '' : t("mdp_necorrespondpas"));
  };
  const handleTypeChange = (type: any) => {
    setType(type);
  };
  const handleRoleChange = (role: any) => {
    setRole(role);
  };

  const validateForm = () => {
    let invalidForm = false;
    const emptyFields =
      password === "" &&
      email === "" &&
      name === "" &&
      type === "" &&
      role === "" &&
      fullName === "";

    if (emptyFields) {
      setMessage(t("Veuillez_remplir_tous_les_champs"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }

    if (!email) {
      invalidForm = true;
      setEmailError(t("Ce_champ_est_obligatoire"));
    } else if (!checkIsValidEmail(email)) {
      invalidForm = true;
      setEmailError(t("Lemail_nest_pas_valide"));
    } else {
      invalidForm = false;
      setEmailError("");
    }

    if (!password) {
      invalidForm = true;
      setPasswordError(t("Ce_champ_est_obligatoire"));
    } else if (!checkIsValidPassword(password)) {
      invalidForm = true;
      setPasswordError(t("mdp"));
    } else {
      setPasswordError("");
    }
    if (password !== rePassword) {
      invalidForm = true;
      setRePasswordError(t("mdp_necorrespondpas"));
    } else {
      setRePasswordError("");
    }

    if (!name) {
      invalidForm = true;
      setNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setNameError("");
    }
    if (!fullName) {
      invalidForm = true;
      setFullNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setFullNameError("");
    }

    if (type === "") {
      invalidForm = true;
      setTypeError(t("Ce_champ_est_obligatoire"));
    } else {
      setTypeError("");
    }
    if (role === "") {
      invalidForm = true;
      setRoleError(t("Ce_champ_est_obligatoire"));
    } else {
      setRoleError("");
    }
    return invalidForm;
  };

  const validateForm2 = () => {
    let invalidForm = false;
    if (!rne.length && role === "Gérant") {
      invalidForm = true;
      setRneError(t("Obligatoire"));
    } else {
      setRneError("");
    }
    if (!justificatif.length) {
      invalidForm = true;
      setJustificatifError(t("Obligatoire"));
    } else {
      setJustificatifError("");
    }
    if (!cinRecto.length) {
      invalidForm = true;
      setCinRectoError(t("Obligatoire"));
    } else {
      setCinRectoError("");
    }
    if (!cinVerso.length) {
      invalidForm = true;
      setCinVersoError(t("Obligatoire"));
    } else {
      setCinVersoError("");
    }
    return invalidForm;
  };

  const handleRNEChange = (file: any) => {
    const files = file.target.files;
    setRne([...files]);
    if (files) {
      setRneError("");
    }
  };
  const handleJustificatifChange = (file: any) => {
    const files = file.target.files;
    setJustificatif([...files]);
    if (files) {
      setJustificatifError("");
    }
  };
  const handleCinRectoChange = (file: any) => {
    const files = file.target.files;
    setCinRecto([...files]);
    if (files) {
      setCinRectoError("");
    }
  };
  const handleCinVersoChange = (file: any) => {
    const files = file.target.files;
    setCinVerso([...files]);
    if (files) {
      setCinVersoError("");
    }
  };
  const removeRne = (index: number) => {
    const res = [...rne];
    res.splice(index, 1);
    setRne(res);
  };
  const removeJustificatif = (index: number) => {
    const res = [...justificatif];
    res.splice(index, 1);
    setJustificatif(res);
  };
  const removeCinRecto = (index: number) => {
    const res = [...cinRecto];
    res.splice(index, 1);
    setCinRecto(res);
  };
  const removeCinVerso = (index: number) => {
    const res = [...cinVerso];
    res.splice(index, 1);
    setCinVerso(res);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    let investorAgentData = {
      investors: name,
      email: email,
      name: fullName,
      type: type,
      password: password,
      roleAgent: role,
    };
    await addInvestorAgentAction(dispatch, investorAgentData);
    setNext(true);
  };

  const uploadDocuments = async (e: any) => {
    e.preventDefault();
    setShowVerificationEmailMessage(false);
    setEmailError("");
    setMessage("");
    const invalidForm = validateForm2();
    if (invalidForm) {
      return;
    }
    if (result.value) {
      const filesToAdd = [...rne, ...justificatif, ...cinRecto, ...cinVerso];
      const data = { id: result.value._id, files: filesToAdd };
      await uploadDocumentsAction(dispatch, data);
    }
  };

  return (
    <form dir={t("dir")}>
      <br />
      {!next && (
        <div>
          <Input
            name="fullname"
            type="text"
            placeholder={t("Nom_et_Prénom")}
            onChange={handleFullNameChange}
            error={fullNameError}
          />
          <Input
        name="email"
        type="email"
        placeholder={t("Email")}
        value={email}
        onChange={handleEmailChange}
        error={emailError}
      />
      <Input
        name="password"
        type={hiddenPassword ? "password" : "text"}
        placeholder={t("Mot_de_passe")}
        value={password}
        onChange={handlePasswordChange}
        error={passwordError}
            icon={
              hiddenPassword ? (
                <i className="fa fa-eye-slash" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye" aria-hidden="true"></i>
              )
            }
            onClickIcon={() => setHiddenPassword((current) => !current)}
          />
          <Input
          name="repassword"
          type={hiddenRePassword ? "password" : "text"}
          placeholder={t("Confirmer_mot_passe")}
          value={rePassword}
          onChange={handleRePasswordChange}
          error={rePasswordError}
            icon={
              hiddenRePassword ? (
                <i className="fa fa-eye-slash" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye" aria-hidden="true"></i>
              )
            }
            onClickIcon={() => setHiddenRePassword((current) => !current)}
          />
          <FormCheck
            onChange={handleTypeChange}
            type={type}
            error={typeError}
          />

          {newEntreprise && (
            <Input
              name="name"
              type="text"
              placeholder={
                type === "Entreprise"
                  ? t("Nom_de_lEntreprise")
                  : t("Nom_de_lONG")
              }
              onChange={handleNameChange}
              error={nameError}
            />
          )}
          {!newEntreprise && investors && investors.value.length > 0 && (
            <SelectInput
              selectedCategory={name}
              defaultValue={
                type === "Entreprise"
                  ? { _id: "", name: "Entreprise" }
                  : { _id: "", name: "ONG" }
              }
              selectList={investors.value}
              onChange={handleNameChange}
              error={nameError}
              value="name"
            />
          )}
          {!newEntreprise && (
            <div className="mb-3">
              {" "}
              <i
                className="fa fa-plus me-2 pointer"
                aria-hidden="true"
                style={{ color: "#0081af" }}
                onClick={(e) => newEntrepriseHandler(e, true)}
              ></i>
              <a
                href=""
                onClick={(e) => newEntrepriseHandler(e, true)}
                className="pointer"
              >
                {type === "Entreprise"
                  ? t("Nouvelle_entreprise")
                  : t("Nouvelle_ONG")}
              </a>
            </div>
          )}
          {newEntreprise && (
            <div className="mb-3">
              <a
                href=""
                onClick={(e) => newEntrepriseHandler(e, false)}
                className="pointer"
              >
                {type === "Entreprise"
                  ? t("Choisir_une_entreprise")
                  : t("Choisir_une_ONG")}
              </a>
            </div>
          )}
          <FormCheck2
            onChange={handleRoleChange}
            role={role}
            error={roleError}
          />
          <p className="text-danger text-center mt-3 mb-3">{message}</p>
          <Button
            cssClass="d-grid"
            title={t("Suivant")}
            onClick={handleSubmit}
          />
          {status === "loading" && (
            <div className="mt-3" style={{ textAlign: "center" }}>
              <Spinner />
            </div>
          )}
        </div>
      )}
      {next && (
        <div>
          {role === "Gérant" && (
            <>
              <SelectFileButton
                title={t("Télécharger_RNE")}
                onChange={handleRNEChange}
                accept={"application/pdf"}
                error={rneError}
              />
              {rne.length > 0 && (
                <div className="mt-4 mb-4 flex">
                  {rne.map((file: File, index) => {
                    return (
                      <div className="selected-file me-2">
                        <span className="me-2" key={index}>
                          {file.name}
                        </span>
                        <i
                          className="fa fa-times-circle pointer"
                          aria-hidden="true"
                          onClick={() => removeRne(index)}
                        ></i>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
          <SelectFileButton
            title={t("Télécharger_justificatif")}
            onChange={handleJustificatifChange}
            accept={"application/pdf"}
            error={justificatifError}
          />
          {justificatif.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {justificatif.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeJustificatif(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <SelectFileButton
            title={t("Télécharger_CIN_Recto")}
            onChange={handleCinRectoChange}
            accept={"image/*"}
            error={cinRectoError}
          />
          {cinRecto.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {cinRecto.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeCinRecto(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <SelectFileButton
            title={t("Télécharger_CIN_verco")}
            onChange={handleCinVersoChange}
            accept={"image/*"}
            error={cinVersoError}
          />
          {cinVerso.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {cinVerso.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeCinVerso(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <p className="text-danger text-center mt-3 mb-3">{message}</p>
          {showVerificationEmailMessage && (
            <div className="alert alert-success text-center mt-3 mb-3" role="alert">
            {t("validation_de_votre_compte")}
          </div>
          )}
          <Button
            cssClass="d-grid"
            title={t("Inscrivez_vous")}
            onClick={uploadDocuments}
          />
          {statusUpload === "loading" && (
            <div className="mt-3" style={{ textAlign: "center" }}>
              <Spinner />
            </div>
          )}
        </div>
      )}
    </form>
  );
};
export default EntrepriseSignUpForm;
