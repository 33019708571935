import React, {  useRef, useEffect } from 'react';
 import Lottie from 'react-lottie';
 //import animationData from '../../animation-files/animationAnt.json'
 import animationData from '../../animation-files/antAnimationV2.json'
 import './AntAnimation.css';
 
 const AntAnimation = () => {
  const animationRef = useRef<any>(null); // Add a ref to reference the Lottie instance

   const defaultOptions = {
     loop: true,
     autoplay: true,
     animationData: animationData,
   };

   useEffect(() => {
    if (animationRef.current) {
      animationRef.current.setSpeed(0.01); // Adjust the speed (0.5 is half speed)
    }
  }, []); // Empty dependency array means it runs once when the component mounts

 
   return (
     <div className="ant-animation-container">
       <Lottie options={defaultOptions} 
       ref={animationRef} // Set the ref to the Lottie component
       />
       </div>
   );
 };
 
 export default AntAnimation;
 