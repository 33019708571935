import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectService from "../services/ProjectDetailsService";
import { useEffect, useState } from "react";

export const project = createAsyncThunk(
  "user/project",
  async (id: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.ProjectService(id);
      if (status === 200) {
        return data;
    } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const informationUser = createAsyncThunk(
  "user/information",
  async (id: any, thunkAPI) => {
    try {
      const { status, data } = await ProjectService.InformationUserService(id);
      if (status === 200) {                                      
        return data;
    } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  Project: {
    status: "idle",
    data: {
      email: "",
      name: "",
    },
    error: {},
  },
  information: {
    status: "idle",
    data: {
      email: "",
      name: "",
    },
    error: {},
  },
};
const ProjectSlice = createSlice({
  name: "signin-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [project.pending.type]: (state) => {
      state.Project = {
        status: "loading",
        data: {
          email: "",
          name: "",
        },
        error: {},
      };
    },
    [project.fulfilled.type]: (state, action) => {
      state.Project = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [project.rejected.type]: (state, action) => {
      state.Project = {
        status: "failed",
        data: action.payload,
        error: {
        
        },
      };
    },
    [informationUser.pending.type]: (state) => {
      state.information = {
        status: "loading",
        data: {
          email: "",
          name: "",
        },
        error: {},
      };
    },
    [informationUser.fulfilled.type]: (state, action) => {
      state.information = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [informationUser.rejected.type]: (state, action) => {
      state.information = {
        status: "failed",
        data: action.payload,
        error: {
        
        },
      };
    },
  },
});

export default ProjectSlice.reducer;
