import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { DonationComponent } from "./screens/donation";
import { FooterComponent } from "./components/footer";
import { HeaderComponent } from "./components/header";
import SignUp from "./screens/register";
import SignIn from "./screens/login";
import Profile from "./screens/donator/Profile";
import { ProfileProject } from "./screens/project/profileProject";
import CreateProject from "./screens/project/createProject";
import ProfileCampaign from "./screens/campaign/detailsCampaign/components/ProfileCampaign";
import Home from "./screens/home";
import MesProjects from "./screens/project/mesProjects/components/Projects";
import EnCoursComponent from "./screens/en_cours/components/encours.component";
import AllProjects from "./screens/project/allProjects";
import CreateCampaign from "./screens/campaign/createCampaign";
import Donationsucess from "./screens/donation/components/donationsucess";
import CorporateProfile from "./screens/corporate/Profile";
import CreateQuadraticCampaign from "./screens/quadraticCampaign/createQuadraticCampaign";
import QuadraticCampaignDetails from "./screens/quadraticCampaign/quadraticCampaignDetails";
import EmailVerification from "./screens/emailVerification";
import ForgotPassword from "./screens/forgotPassword";
import ResetPassword from "./screens/resetPassword";
import DonationFail from "./screens/donation/components/donationFail";
import AboutUs from "./screens/about/aboutComponent";

function App() {
  const launchDate = new Date('2023-07-16T00:00:00Z'); // Set your launch date here
  const currentDate = new Date();

  const isBeforeLaunch = currentDate < launchDate;
  return (
    <div className="App">
      {isBeforeLaunch ? null : <HeaderComponent />}
      

      <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/AllProjects" element={<AllProjects />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/profile-donator" element={<Profile />} />
              <Route path="/profile_project/:id" element={<ProfileProject />} />
              <Route path="/project-create" element={<CreateProject />} />
              <Route path="/donation/:idProject" element={<DonationComponent />} />
              <Route path="/campaign-create" element={<CreateCampaign />} />
              <Route path="/campaign::id" element={<ProfileCampaign />} />
              <Route path="/encours" element={<EnCoursComponent />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/Felecitation::token" element={<Donationsucess />} />
              <Route path="/DonationFail" element={<DonationFail />} />
              <Route path="/MesProjects" element={<MesProjects />} />
              <Route path="/corporate-profile" element={<CorporateProfile />} />
              <Route path="/create-quadratic-campaign" element={<CreateQuadraticCampaign />} />
              <Route path="/quadratic-campaign::id" element={<QuadraticCampaignDetails />} />
              <Route path="/register-email-verification::token" element={<EmailVerification />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password::token" element={<ResetPassword />} />
            </Routes>
          </Router>

    </div>
  );
}

export default App;
