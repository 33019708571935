const Textarea = (props: any) => {
  return (
    <div className="mb-3">
      <div className="input-group">
        <textarea
          rows={props.rows}
          className="form-control"
          placeholder={props.placeholder}
          onChange={props.onChange}
          disabled={props.disabled}
          value={props.value}
        ></textarea>
      </div>
      {props.error && (
        <small className="text-danger mt-3 mb-3">{props.error}</small>
      )}
    </div>
  );
};
export default Textarea;
