import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AccueilService from '../services/AccueilService';
export const getBestCategoriesProjects = createAsyncThunk(
    "project/getBestCategoriesProjects",
    async (x: any, thunkAPI) => {
      try {
        const { status, data } = await AccueilService.getCategoriesProjects(x);
        if (status === 201 || status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
export const getRecentProjects = createAsyncThunk(
    "project/getRecentProjects",
    async (x: any, thunkAPI) => {
      try {
        const { status, data } = await AccueilService.getRecentProjects(x);
        if (status === 201 || status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
export const getCommonInfos = createAsyncThunk(
	'Acceuil/get-common-infos',
	async (regions: any, thunkAPI) => {
		try {
			const { status, data } = await AccueilService.getCommonInfosService(regions);
			if (status === 201 || status === 200) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

const initialState = {
	getCommonInfos: {
		status: 'idle',
		data: null,
		error: {},
	},
    getrecentProjects: {
		status: 'idle',
		data: null,
		error: {},
	},
	getCategoryProjects: {
		status: 'idle',
		data: null,
		error: {},
	},
};
const DashboardSlice = createSlice({
	name: 'Acceuil',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getCommonInfos.pending.type]: (state) => {
			state.getCommonInfos = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getCommonInfos.fulfilled.type]: (state, action) => {
			state.getCommonInfos = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getCommonInfos.rejected.type]: (state, action) => {
			state.getCommonInfos = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
        [getRecentProjects.pending.type]: (state) => {
			state.getrecentProjects = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getRecentProjects.fulfilled.type]: (state, action) => {
			state.getrecentProjects = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getRecentProjects.rejected.type]: (state, action) => {
			state.getrecentProjects = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
		[getBestCategoriesProjects.pending.type]: (state) => {
			state.getCategoryProjects = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getBestCategoriesProjects.fulfilled.type]: (state, action) => {
			state.getCategoryProjects = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getBestCategoriesProjects.rejected.type]: (state, action) => {
			state.getCategoryProjects = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
	},
});

export default DashboardSlice.reducer;
