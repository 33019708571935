import Title from "./components/Title";
import CreateProjectForm from "./components/CreateProjectForm";
import "./CreateProject.css";
import { t } from "i18next";

import { FooterComponent } from "../../../components/footer";

const CreateProject = () => {
  return (
    <>
      <section>
        <div className="container">
          <Title title={t('Créer_un_projet')} />
          <CreateProjectForm />
        </div>
      </section>
      <FooterComponent />
    </>
  );
};
export default CreateProject;
