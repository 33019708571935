import FormStep20 from "./FormStep20";
import FormStep21 from "./FormStep21";
import FormStep22 from "./FormStep22";
import FormStep23 from "./FormStep23";
import FormStep24 from "./FormStep24";
import FormStep25 from "./FormStep25";
import FormStep26 from "./FormStep26";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep2 = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      {props.next === 1 && (
        <FormStep20
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
      {props.next === 2 && (
        <FormStep21
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
      {props.next === 3 && (
        <FormStep22
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
      {props.next === 4 && (
        <FormStep23
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
      {props.next === 5 && (
        <FormStep24
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
      {props.next === 6 && (
        <FormStep25
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
      {props.next === 7 && (
        <FormStep26
          project={props.project}
          updateProject={props.updateProject}
          nextHandler={props.nextHandler}
        />
      )}
    </>
  );
};
export default FormStep2;
