import Title from "../../../components/Title/Title";
import CreateCampaignForm from "./components/CreateCampaignForm";
import { useLocation } from "react-router-dom";
import { FooterComponent } from "../../../components/footer";

import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
const CreateCampaign = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const { projectId } = state || {};
  return (
    <>
      <section>
        <div className="container">
          <Title title={t("Créer_un_Campagne")} />
          <CreateCampaignForm projectId={projectId} />
        </div>
      </section>
      <FooterComponent />
    </>
  );
};
export default CreateCampaign;
