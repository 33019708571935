import FormStep31 from "./FormStep31";
import FormStep32 from "./FormStep32";

const FormStep3 = (props: any) => {
  return (
    <>
      {props.next === 8 && (
        <FormStep31
          nextHandler={props.nextHandler}
          project={props.project}
          updateProject={props.updateProject}
          validate={props.validate}
        />
      )}
      {props.next === 9 && (
        <FormStep32
          nextHandler={props.nextHandler}
          project={props.project}
          updateProject={props.updateProject}
        />
      )}
    </>
  );
};
export default FormStep3;
