import Links from "./Links";
import ProfilePhoto from "./ProfilePhoto";
import UserInfos from "./UserInfos";
import { useTranslation } from "react-i18next";

const ProfileDetails = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="contact_form_Wrapper" style={{ height: "100%" }}>
      <div className="text-center">
        <ProfilePhoto id={props.id} profilePhoto={props.user.profilePhoto} />
        <UserInfos
          user={props.user}
          id={props.id}
          editUserHandler={props.editUserHandler}
        />
      </div>
      <Links
        user={props.user}
        id={props.id}
        editUserHandler={props.editUserHandler}
      />
      <div className="mt-5 text-center">
        <span>
          {t("inscription")} : {props.user.dateInscription}
        </span>
      </div>
    </div>
  );
};
export default ProfileDetails;
