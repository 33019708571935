import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const Campaigns = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {props.campaigns && props.campaigns.value.length > 0 && (
        <>
          <table className="table table-striped" dir={t("dir")}>
            <thead>
              <tr>
                <th scope="col">{t("Projet")}</th>
                <th scope="col">{t("Campagne")}</th>
                <th scope="col">{t("objectif")}</th>
                <th scope="col">{t("collected_amount")}</th>
                <th scope="col">{t("Progression")}</th>
                <th scope="col">{t("nb_donations")}</th>
                <th scope="col">{t("Date_de_fin")}</th>
                {props.showAmount && props.amounts && (
                  <th scope="col">{t("amount_to_give")}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {props.campaigns &&
                props.campaigns.value.length > 0 &&
                props.campaigns.value.map((item: any, index: any) => (
                  <tr key={index}>
                    <th scope="row">
                      <a
                        href=""
                        onClick={(e: any) => {
                          e.preventDefault();

                          navigate(`/profile_project/${item.project._id}`, {
                            state: item.project._id,
                          });
                        }}
                        className="pointer"
                      >
                        {props.currentLanguage === "ar"
                          ? item.project.titre
                            ? item.project.titre
                            : item.project.frenchTitre
                          : item.project.frenchTitre
                          ? item.project.frenchTitre
                          : item.project.titre}
                      </a>
                    </th>
                    <td>
                      <a
                        href=""
                        onClick={(e: any) => {
                          e.preventDefault();
                          navigate(`/campaign:${item.idCampaigne}`, {
                            state: item.idCampaigne,
                          });
                        }}
                        className="pointer"
                      >
                        {props.currentLanguage === "ar"
                          ? item.nomCampaigne
                            ? item.nomCampaigne
                            : item.nomCampaigneFrench
                          : item.nomCampaigneFrench
                          ? item.nomCampaigneFrench
                          : item.nomCampaigne}
                      </a>
                    </td>
                    <td>
                      {item.amountToCollect} {t("tnd")}
                    </td>
                    <td>
                      {item.cureentCap} {t("tnd")}
                    </td>
                    <td>{item.goals}</td>
                    <td>{item.nbDonations}</td>
                    <td>{moment(item.endDate).format("DD-MM-YYYY")}</td>
                    {props.showAmount && props.amounts && (
                      <td>
                        {props.amounts.map((i: any) => {
                          if (i.idCampaign === item.idCampaigne) {
                            return i.QF;
                          }
                        })}{" "}
                        {t("tnd")}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
export default Campaigns;
