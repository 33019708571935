const PartnerItem = (props: any) => {
  return (
    <div
      className="owl-item cloned"
      style={{ width: "225.333px", marginRight: "10px" }}
    >
      <div className="partner_logo">
        <a href={props.link ? props.link : ""} target="_blank">
          <img src={"assets/img/companies/" + props.image} alt="img" />
        </a>
      </div>
    </div>
  );
};
export default PartnerItem;
