import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../components/Input/Input";
import Textarea from "../../../../components/Textarea/Textarea";
import { addCampaign } from "../../../../reducers/campaign";
import {
  blockInvalidChar,
  getNumberOfDays,
  wordCounter,
} from "../../../../utils/functions";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import jwt_decode from "jwt-decode";
import i18n from "i18next";
import Spinner from "../../../../components/Spinner/Spinner";

const addCampaignAction = async (formData: any, dispatch: any) => {
  return await dispatch(addCampaign(formData)).unwrap();
};
const FormStep1 = (props: any) => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const dispatch = useDispatch();
  const [typeStartDate, setTypeStartDate] = useState("text");
  const [typeEndDate, setTypeEndDate] = useState("text");
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [goals, setGoals] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState<Number | undefined>(undefined);

  const [titleError, setTitleError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [goalsError, setGoalsError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const [message, setMessage] = useState("");
  const status = useSelector((state: any) => state.Campaign.addCampaign.status);
  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };
  const handleAmountChange = (event: any) => {
    setAmount(event.target.value);
  };
  const handleGoalsChange = (event: any) => {
    setGoals(event.target.value);
  };
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    if (endDate && event.target.value) {
      const days = getNumberOfDays(
        new Date(event.target.value),
        new Date(endDate)
      );
      setDuration(days);
    } else {
      setDuration(undefined);
    }
  };
  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    if (startDate && event.target.value) {
      const days = getNumberOfDays(
        new Date(startDate),
        new Date(event.target.value)
      );
      setDuration(days);
    } else {
      setDuration(undefined);
    }
  };
  const validateForm = () => {
    let invalidForm = false;
    const emptyFields =
      title === "" &&
      amount === undefined &&
      goals === "" &&
      !startDate &&
      !endDate;

    if (emptyFields) {
      setMessage(t("Veuillez_remplir_tous_les_champs"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }

    if (!title) {
      invalidForm = true;
      setTitleError(t("Ce_champ_est_obligatoire"));
    } else {
      setTitleError("");
    }

    if (amount === undefined) {
      invalidForm = true;
      setAmountError(t("Ce_champ_est_obligatoire"));
    } else {
      setAmountError("");
    }

    if (!startDate) {
      invalidForm = true;
      setStartDateError(t("Ce_champ_est_obligatoire"));
    } else {
      setStartDateError("");
    }
    if (!endDate) {
      invalidForm = true;
      setEndDateError(t("Ce_champ_est_obligatoire"));
    } else {
      setEndDateError("");
    }

    if (!goals) {
      invalidForm = true;
      setGoalsError(t("Ce_champ_est_obligatoire"));
    } else if (!wordCounter(goals, 50)) {
      invalidForm = true;
      setGoalsError(t("Veuillez_taper_au_moins_50_mots"));
    } else {
      setGoalsError("");
    }

    return invalidForm;
  };
  const validate = async (e: any) => {
    e.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const campaign = {
      titre: title,
      maxCap: parseFloat(amount),
      goal: goals,
      startDate: startDate,
      delayDays: duration,
      project: props.projectId,
      porteur: "",
      Language: "",
    };
    const token = localStorage.getItem("token");
    if (token) {
      const currentUser: any = jwt_decode(token);
      campaign.porteur = currentUser.id;
    }
    campaign.Language = currentLanguage;
    await addCampaignAction(campaign, dispatch);
    props.next();
  };

  return (
    <>
      <div className="row">
        <h3 className="text-center mb-5">{t("Etape1_Campagne")}</h3>
        <div className="col-lg-12">
          <Input
            name="title"
            type="text"
            placeholder={t("Nom_de_la_campagne")}
            onChange={handleTitleChange}
            error={titleError}
            value={title}
          />
        </div>
        <div className="col-lg-12">
          <Input
            name="amount"
            type="number"
            placeholder={t("Montant_à_collecter")}
            onChange={handleAmountChange}
            error={amountError}
            value={amount}
            icon={"DT"}
            onKeyDown={blockInvalidChar}
            min={0}
          />
        </div>
        <div className="col-lg-12">
          <Input
            name="startdate"
            type={typeStartDate}
            placeholder={t("Date_de_début")}
            onChange={handleStartDateChange}
            onFocus={() => setTypeStartDate("date")}
            onBlur={() => setTypeStartDate("text")}
            error={startDateError}
            value={startDate}
            min={Moment().add(7, "days").format("YYYY-MM-DD")}
          />
        </div>
        <div className="col-lg-12">
          <Input
            name="enddate"
            type={typeEndDate}
            placeholder={t("Date_de_fin")}
            onChange={handleEndDateChange}
            onFocus={() => setTypeEndDate("date")}
            onBlur={() => setTypeEndDate("text")}
            error={endDateError}
            value={endDate}
            min={
              startDate
                ? Moment(startDate).format("YYYY-MM-DD")
                : Moment().add(7, "days").format("YYYY-MM-DD")
            }
          />
        </div>
        <div className="col-lg-12">
          <Input
            name="duration"
            type="text"
            placeholder={t("Durée")}
            value={
              duration !== undefined
                ? duration > 1
                  ? duration + " jours"
                  : duration + " jour"
                : ""
            }
            disabled={true}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("Objectifs_de_la_compagne")}
            onChange={handleGoalsChange}
            error={goalsError}
            value={goals}
          />
        </div>
        <p className="text-danger text-center mt-3 mb-3">{message}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="btn btn_theme"
          onClick={validate}
          disabled={status === "loading"}
        >
          {t("Valider")}
        </button>
      </div>
      {status === "loading" && (
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};
export default FormStep1;
