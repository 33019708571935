import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FooterComponent } from "../../../components/footer";
import {
  getQuadraticCampaignById,
  updateStatusQuadraticCampaign,
} from "../../../reducers/quadraticCampaign";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import i18n from "i18next";
import { regionsArabe } from "../../../utils/constants";
import _ from "lodash";
import Button from "../../../components/Button/Button";

const getQuadraticCampaignDetailsAction = async (dispatch: any, id: string) => {
  return await dispatch(getQuadraticCampaignById(id)).unwrap();
};
const updateStatusQuadraticCampaignAction = async (
  dispatch: any,
  data: any
) => {
  return await dispatch(updateStatusQuadraticCampaign(data)).unwrap();
};

const QuadraticCampaignDetails = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const quadraticCampaign = useSelector(
    (state: any) => state.QuadraticCampaign.quadraticCampaignById.data
  );
  const statusUpdate = useSelector(
    (state: any) => state.QuadraticCampaign.updateStatusQuadraticCampaign.status
  );
  const getQuadraticCampaignDetailsHandler = async () => {
    if (id) {
      await getQuadraticCampaignDetailsAction(dispatch, id);
    }
  };
  useEffect(() => {
    getQuadraticCampaignDetailsHandler();
  }, []);

  useEffect(() => {
    if (quadraticCampaign && quadraticCampaign.value) {
      if (quadraticCampaign.value.status === "Created") {
        setStatus(t("attente"));
        return;
      }
      if (quadraticCampaign.value.status === "Accepted") {
        setStatus(t("Accepté"));
        return;
      }
      if (quadraticCampaign.value.status === "Refused") {
        setStatus(t("Refusé"));
        return;
      }
      if (quadraticCampaign.value.status === "Simulation") {
        setStatus(t("Simulé"));
        return;
      }
    }
  }, [quadraticCampaign]);
  const getRegionName = (region: any) => {
    const x = _.find(regionsArabe, { _id: region });
    if (x && currentLanguage === "ar") {
      return x.name;
    } else {
      return region;
    }
  };
  const launchCampaign = async () => {
    const data = {
      id: id,
      status: "Created",
    };
    await updateStatusQuadraticCampaignAction(dispatch, data);
    getQuadraticCampaignDetailsHandler();
  };
  return (
    <>
      {quadraticCampaign && quadraticCampaign.value && (
        <section className="mt-4" dir={t("dir")}>
          <div className="container mt-4">
            <button
              className="btn btn_theme back-btn mb-4"
              onClick={() => navigate("/corporate-profile")}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            <div className="row mb-4">
              {quadraticCampaign.value.status === "Simulation" && (
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    cssClass="mb-3"
                    title={t("Lancer_une_campagne_quadratique")}
                    onClick={launchCampaign}
                    icon={
                      statusUpdate === "loading" ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null
                    }
                  />
                </div>
              )}
              <div className="col">
                <div className="card">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      {t("Date")}:{" "}
                      {moment(quadraticCampaign.value.date).format(
                        "DD-MM-YYYY"
                      )}
                    </li>
                    <li className="list-group-item">{status}</li>
                    <li className="list-group-item">
                      {t("Montant")}: {quadraticCampaign.value.amount}{" "}
                      {t("tnd")}
                    </li>
                    <li className="list-group-item">
                      {" "}
                      <h6 className="mb-2"> {t("regions")}</h6>
                      <div>
                        {quadraticCampaign.value.regions &&
                          quadraticCampaign.value.regions.length > 0 &&
                          quadraticCampaign.value.regions.map(
                            (item: any, index: any) => (
                              <span key={index} className="item-1 me-1">
                                {getRegionName(item)}
                              </span>
                            )
                          )}
                        {quadraticCampaign.value.regions &&
                          quadraticCampaign.value.regions.length === 0 && (
                            <span className="item-1 me-1">{t("aucun")}</span>
                          )}
                      </div>
                    </li>
                    <li className="list-group-item">
                      <h6 className="mb-2"> {t("Secteurs")}</h6>
                      {quadraticCampaign.value.categories &&
                        quadraticCampaign.value.categories.length > 0 &&
                        quadraticCampaign.value.categories.map(
                          (item: any, index: any) => (
                            <span key={index} className="item-1 me-1">
                              {item.name}
                            </span>
                          )
                        )}
                      {quadraticCampaign.value.categories &&
                        quadraticCampaign.value.categories.length === 0 && (
                          <span className="item-1 me-1">{t("aucun")}</span>
                        )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      {t("Nombre_des_employés")}:{" "}
                      {quadraticCampaign.value.nbEmps}
                    </li>
                    <li className="list-group-item">
                      {t("Nombre_des_femmes")}:{" "}
                      {quadraticCampaign.value.nbEmpWomen}
                    </li>
                    <li className="list-group-item">
                      {t("Nombre_des_hommes")}:{" "}
                      {quadraticCampaign.value.nbEmpsMen}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{t("Projet")}</th>
                  <th scope="col">{t("Campagne")}</th>
                  <th scope="col">{t("objectif")}</th>
                  <th scope="col">{t("collected_amount")}</th>
                  <th scope="col">{t("Progression")}</th>
                  <th scope="col">{t("Date_de_fin")}</th>
                  <th scope="col">{t("Montant_Donné")}</th>
                </tr>
              </thead>
              <tbody>
                {quadraticCampaign.value.campaigns &&
                  quadraticCampaign.value.campaigns.length > 0 &&
                  quadraticCampaign.value.campaigns.map(
                    (item: any, index: any) => (
                      <tr key={index}>
                        <th scope="row">
                          <a
                            href=""
                            onClick={(e: any) => {
                              e.preventDefault();

                              navigate(`/profile_project/${item.project._id}`, {
                                state: item.project._id,
                              });
                            }}
                            className="pointer"
                          >
                            {currentLanguage === "ar"
                              ? item.project.titre
                                ? item.project.titre
                                : item.project.frenchTitre
                              : item.project.frenchTitre
                              ? item.project.frenchTitre
                              : item.project.titre}
                          </a>
                        </th>
                        <td>
                          <a
                            href=""
                            onClick={(e: any) => {
                              e.preventDefault();
                              navigate(`/campaign:${item.id}`, {
                                state: item.id,
                              });
                            }}
                            className="pointer"
                          >
                            {currentLanguage === "ar"
                              ? item.name
                                ? item.name
                                : item.frenchtitre
                              : item.frenchtitre
                              ? item.frenchtitre
                              : item.name}
                          </a>
                        </td>
                        <td>
                          {item.goal} {t("tnd")}
                        </td>
                        <td>
                          {item.currentAmount} {t("tnd")}
                        </td>
                        <td>{item.progress} %</td>
                        <td>{moment(item.endDate).format("DD-MM-YYYY")}</td>
                        <td>
                          {item.qfAmount} {t("tnd")}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </section>
      )}
      <FooterComponent />
    </>
  );
};
export default QuadraticCampaignDetails;
