import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CategoryCard from './../../../../components/Card/categoryCard';
import './Categories.css';
import { getBestCategoriesProjects } from "../../../../reducers/acceuil";
import i18n from "i18next";




const cardsData = [
    { icon: 'assets/img/categoryIcon/sport.png', CategoryName: 'Sport', projectCount: 2 },
    {  icon: 'assets/img/categoryIcon/agricult.png', CategoryName: 'Agriculture', projectCount: 0 },
    { icon: 'assets/img/categoryIcon/agro.png', CategoryName: 'Agroalimentaire', projectCount: 0 },
    {  icon: 'assets/img/categoryIcon/service.png', CategoryName: 'Services aux entreprises', projectCount: 0 }
];

const getBestCategoriesProjectsAction = async (dispatch: any) => {
    return await dispatch(getBestCategoriesProjects({})).unwrap();
  };
  
const Categories = () => {
    const categoriesToShow: any = [
        "Transports",
        "Environment",
        "Santé",
        "Nouvelles technologies",
      ];
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
    const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cssClasses = ["bg_one", "bg_two", "bg_three", "bg_four"];
    const projectCategories = useSelector(
         (state: any) => state.ProjectCategory.categories.data
        );
    const [selectedCategory, setSelectedCategory] = useState([]);

  const CategorieProjectsdata = useSelector((state: any) =>
    state.Acceuil.getCategoryProjects.data
      ? state.Acceuil.getCategoryProjects.data.value
      : []
  );
  useEffect(() => {
    getBestCategoriesProjectsAction(dispatch);
  }, [dispatch]);

 

  useEffect(() => {
    if (projectCategories) {
      const list = projectCategories.filter((item: any) =>
        categoriesToShow.includes('Nouvelles technologies')
      );
      setSelectedCategory(list);
    }
  }, [projectCategories]);




    return (
      <div className="colorClassCat ">
        <div className="hexagon-grid">
            <div className="title"><h1>Soutenez les projets par catégorie</h1></div>
            <div className="line-cat">
              <img src="assets\img\about\RectangleBlue.png" alt="line"  />
              </div>
            <div className="hexagon-grid-container">
                {cardsData.map((card, index) => (
                    <CategoryCard 
                        key={index}
                        icon={card.icon}
                        CategoryName={card.CategoryName}
                        projectCount={card.projectCount}
                        
                    />
                ))}
            </div>
        </div>
        </div>
    );
};

export default Categories;
