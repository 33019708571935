import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import './ComingSoon.css';
const ComingSoon: React.FC = () => {
    const calculateTimeLeft = () => {
        

      const difference = +new Date('2024-07-16T00:00:00Z') - +new Date();
      let timeLeft = {};
  
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
      return timeLeft;
    };
  
    const [timeLeft, setTimeLeft] = useState<{ [key: string]: number }>(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearTimeout(timer);
    }, [timeLeft]);
  
    const timerComponents: JSX.Element[] = [];
    const { t } = useTranslation();
  
    Object.keys(timeLeft).forEach((interval) => {
        timerComponents.push(
          <div key={interval} className="time-container">
            <div className="hexagon-coming ">
              <div className="hexagon-inner">
                <div className="time">{timeLeft[interval]}</div>
              </div>
              <div className="label">
              {interval === 'days' ? t('day_s'): ''}
              {interval === 'hours' ? t('hour_s') :''}
              {interval === 'minutes' ? t('min_s') :''}
              {interval === 'seconds' ? t('second_s') :''}
            </div>
            </div>
          </div>
        );
      });
    
      return (
        <div className="coming-soon-container">
          <div className="background-image"></div>
          <h1>COMING SOON</h1>
          <div className="countdown">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
        </div>
      );
    };
    
    export default ComingSoon;