import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";

const ProfileMenu = (props: any) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <ul className="profile-menu">
        <li>
          <a
            className={props.showBio ? "active pointer" : "pointer"}
            onClick={props.showBioHandler}
          >
                        {t('Bio')}

          </a>
        </li>
        <li>
          <a
            className={props.showQuadraticCampaigns ? "active pointer" : "pointer"}
            onClick={props.showQuadraticCampaignsHandler}
          >
{t('historique_des_compagnes_quadratique')}          </a>
        </li>
      </ul>
    </div>
  );
};
export default ProfileMenu;
