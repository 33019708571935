import config from "../../config/config";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
const UserInformation = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      {props.information && (
        <div className="sidebar_first" dir={t("dir")}>
          <div className="project_organizer_wrapper sidebar_boxed">
            <div className="project_organizer_img">
              <img
                src={
                  props.information.profilePhoto
                    ? config.apiUrl +
                      "/donator/getFile/" +
                      props.information.profilePhoto
                    : "../../assets/img/avatar.png"
                }
                alt="img"
              />
            </div>

            <div className="project_organizer_text">
              <h5>{t("Créateur")} :</h5>
              <h3>{props.information.name}</h3>
              <p>{props.information.position}</p>
              <ul>
                <li>
                  <img src="../../assets/img/icon/email-color.png" alt="icon" />{" "}
                  {t("Email")}: <span>{props.information.email}</span>
                </li>
                <li>
                  <img src="../../assets/img/icon/map_color.png" alt="icon" />{" "}
                  {t("Gouvernorat")}:{" "}
                  <span>{props.information.gouvernorat}</span>
                </li>
                {props.information.telephone && (
                  <li>
                    <img
                      src="../../assets/img/icon/phon-color.png"
                      alt="icon"
                    />{" "}
                    {t("Telephone")}: <span>{props.information.telephone}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UserInformation;
