import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProjectFilter from "../../../../components/ProjectFilter/ProjectFilter";
import ProjectItem from "../../../../components/ProjectItem/ProjectItem";
import { getAllProjects } from "../../../../reducers/project";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const getAllProjectsAction = async (dispatch: any, data: any) => {
  return await dispatch(getAllProjects(data)).unwrap();
};
const AllProjects = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const listInnerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const defaultCategory = location.state && location.state.category ? location.state.category : null;
  const [category, setCategory] = useState(
    defaultCategory ? defaultCategory._id : ""
  );
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const status = useSelector((state: any) => state.Project.getProjects.status);
  const AllProjects = useSelector((state: any) =>
    state.Project.getProjects.data ? state.Project.getProjects.data.value : []
  );
  const totalPages = useSelector((state: any) =>
    state.Project.getProjects.data
      ? state.Project.getProjects.data.totalPages
      : 0
  );
  const [pageSize, setPageSize] = useState(6);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllProjects = async (data: any = {}) => {
    await getAllProjectsAction(dispatch, data);
  };

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
    } else if (status === "failed") {
      setIsLoading(false);
    } else if (status === "loading") {
      setIsLoading(true);
    }
  }, [status]);

  useEffect(() => {
    const data = {
      page: currentPage,
      pageSize: pageSize,
      region: region === "Autre" ? country : region,
      category: category,
    };
    getAllProjects(data);
  }, [category, currentPage, region, country]);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (totalPages >= currentPage + 1) {
          setCurrentPage((current) => current + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (AllProjects.length > 0 && status === "success") {
      console.log('all projects', AllProjects)
      const t = [...data, ...AllProjects];
      setData(t);
    }
  }, [status]);

  return (
    <div
      className=" mb-5"
      ref={listInnerRef}
      onScroll={handleScroll}
      style={{
        height: "80vh",
        overflowY: "scroll",
      }}
      dir={t("dir")}
    >
      <div className="container" style={{ height: "100%" }}>
        <ProjectFilter
          category={category}
          region={region}
          country={country}
          setCategory={setCategory}
          setRegion={setRegion}
          setCountry={setCountry}
          setCurrentPage={setCurrentPage}
          setData={setData}
        />
        {data.length > 0 && status === "success" && (
          <div
            className="row"
            id="counter"
            style={{ height: "100%", marginBottom: "20px" }}
          >
            {data.length > 0 &&
              data.map((item: any) => (
                <div
                  key={item._id}
                  className="col-lg-4 col-md-6 col-sm-12 col-12 mb-4 pb-2"
                >
                  <ProjectItem campaign={item} />
                </div>
              ))}
            {isLoading && (
              <div
                className="spinner-border text-primary col-12"
                role="status"
                style={{ margin: " 0 auto" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        )}

        {data.length === 0 && status === "success" && (
          <section id="subscribe_area">
            <div className="container">
              <div className="subscribe_wrapper">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="subscribe_text text-center mt-5 mb-5">
                      <h3 style={{ fontWeight: "300" }}>{t("Aucun_Projet")}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};
export default AllProjects;
