import { useState } from "react";
import { FooterComponent } from "../../components/footer";
import EntrepriseSignUpForm from "./components/EntrepriseSignUpForm";
import SignUpForm from "./components/SignUpForm";
import TopButtons from "./components/TopButtons";
import "./Register.css";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
const SignUp = () => {
  const { t } = useTranslation();

  const [showEntrepriseSignUpForm, setShowEntrepriseSignUpForm] =
    useState(false);
  const showEntrepriseSignUpFormHandler = (show: boolean) => {
    setShowEntrepriseSignUpForm(show);
  };
  return (
    <>
      <section className="author_form_area ">
        <section className="container-fluid justify-content-center">
          <h3 className="text-center">{t('Rejoignez_nous')}</h3>
          <br />
          <TopButtons
            onClick={showEntrepriseSignUpFormHandler}
            showEntrepriseSignUpForm={showEntrepriseSignUpForm}
          />
          {!showEntrepriseSignUpForm && <SignUpForm />}
          {showEntrepriseSignUpForm && <EntrepriseSignUpForm />}
        </section>
      </section>
      <FooterComponent />
    </>
  );
};
export default SignUp;
