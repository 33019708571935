import Api from "../config/axios";
import config from "../config/config";

export const signupService = async (userData: {}) => {
  const url = config.apiUrl + "/donator/";

  try {
    return await Api.post(url, { userData });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateUserStatusService = async (id: string) => {
  const url = config.apiUrl + "/users/updateUserStatusSignUp/" + id;

  try {
    return await Api.put(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  signupService,
  updateUserStatusService,
};
