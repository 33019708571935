import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RecentDonation from "../../project/profileProject/components/recentDonation";
import Input from "../../../components/Input/Input";
import jwt_decode from "jwt-decode";
import campaign, { getCampaign } from "../../../reducers/campaign";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { informationUser } from "../../../reducers/projectDetails";
import { adddonation } from "../../../reducers/donation";
import { blockInvalidChar } from "../../../utils/functions";
import config from "../../../config/config";
import { FooterComponent } from "../../../components/footer";

const DonationComponent = () => {
  const [token, settoken] = useState<any>();
  const [idToken, setidToken] = useState();
  const { t } = useTranslation();

  const datatoken = useSelector((state: any) => state.Signin.signin.data);
  const params = useParams();
  const getCampaignAction = async ( id: any,dispatch: any,) => {
    return await dispatch(getCampaign(id)).unwrap();
  };
  const informations = async (dispatch: any, data: any) => {
    return await dispatch(informationUser(data)).unwrap();
  };
  const addDonation = async (dispatch: any, data: any) => {
    return await dispatch(adddonation(data)).unwrap();
  };
  const dispatch = useDispatch();
  const [userList, setuserList] = useState<any>([]);

  const status = useSelector(
    (state: any) => state.Campaign.getCampaign.status);
  const data = useSelector((state: any) => state.projectDetails.Project.data);
  const Campaign = useSelector((state: any) => state.Campaign.getCampaign.data);

  var UserInformation: any;
  const [montant, setMontant] = useState(Number);
  const [montantError, setmontantError] = useState("");
  const [porteur, setporteur] = useState();
const [isSubmitting, setIsSubmitting] = useState(false);
  const handleNbEmpWomanChange = (event: any) => {
    setMontant(event.target.value);
  };
  var id:any;
  useEffect(() => {
    var tok=localStorage.getItem("token")
    // settoken(tok);
   
    if (tok) {

      var result: any = jwt_decode(tok);
      settoken(result.id);
      id=result.id
    } else {
    }
        getCampaignAction(params.idProject,dispatch)


      informations(dispatch, data.value.porteur._id).then((res) => {
        UserInformation = res.value;
        setuserList(UserInformation);
      })
      .catch(function (error) {
      });
  }, []);

 const handleSubmit = async (event:any) => {
   event.preventDefault();
   if (isSubmitting) return;
   setIsSubmitting(true);

   if (montant === null || montant <= 0) {
     
     setIsSubmitting(false);
     return;
   }
   
   const payload = {
     donator: token,
     compaign: Campaign.value[0]._id,
     montant: montant,
   };

   await addDonation(dispatch, payload)
     .then((res) => {
      console.log('res nchlh khir');
       window.location.href = res.value;
     }) 
     .catch((error) => {
       console.log('chneya halhkeya');
     })
     .finally(() => {
       
       setIsSubmitting(false);
     });
 };

  return (
    <>
      <section id="make_donation_area" className="section_padding"         dir={t("dir")}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
              <div className="section_heading">
                <h2>
                  <span className="color_big_heading">
            {t('Faites_un_don_maintenant')}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row" id="counter">
            <div className="col-lg-8">
              <div className="details_wrapper_area">
                <div className="donet_amount_area doner_content_pbottom">
                  <h3 className='mt-3'>{t('Montant')} :</h3>
                  <div className="input_donet_amount">
                    <Input
                      type={"number"}
                      onChange={handleNbEmpWomanChange}
                      error={montantError}
                      onKeyDown={blockInvalidChar}
                      min={0}
                      value={montant}
                    />{" "}
                    <span>{t("tnd")}</span>
                  </div>
                </div>
                <div className="payment_amount_submit">
                  <button className="btn btn_theme btn_md"  onClick={handleSubmit}>{t('Faire_un_don')}</button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar_first">
                <div className="project_organizer_wrapper sidebar_boxed">
                  <div className="project_organizer_img">
                    <img
                   src={
                    userList.profilePhoto
                      ? config.apiUrl +
                        "/donator/getFile/" +
                        userList.profilePhoto
                      : "../../assets/img/avatar.png"
                  }
                      alt="img"
                    />
                  </div>

                  <div className="project_organizer_text">
                  <h5>{t('Créateur')} :</h5>
                    <h3>{userList.name}</h3>
                    <p>{userList.position}</p>
                    <ul>
                      <li>
                        <img
                          src="../../assets/img/icon/email-color.png"
                          alt="icon"
                        />{" "}
                         {t('Email')}: <span>{userList.email}</span>
                      </li>
                      <li>
                        <img src="../../assets/img/icon/map_color.png" alt="icon" />{" "}
                        {t('Gouvernorat')}:{" "}
                        <span>
                          {userList.gouvernorat}
                        </span>
                      </li>
                      <li>
                      {userList.telephone && (    <li><img src="../../assets/img/icon/phon-color.png" alt="icon"/> {t('Telephone')}: <span>{userList.telephone}</span>
                                            </li>)}
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <RecentDonation /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent/>
    </>
  );
};
export default DonationComponent;
