import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "../../../../translations/i18n";
import './Banner.css';




const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="banner">
        <div className="banner-content">
            <div className="text-section">
                <div className="logo-section">
                  <img src="assets\img\logoSup.png" alt="Ant Like Logo" className="banner-logo" />
                </div>
                <p className="subtitle"><h2>Ensemble, nous sommes plus forts<br/>unissez-vous à notre colonie de soutien </h2></p>
                <Link to="/AllProjects"> 
                <button className="donate-button">{t("Faire_un_don")}</button>
                </Link>
            </div>
        </div>
    </div>
);
};

export default Banner;
