import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";

const ProjectCreationExplanation = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <h3 className="text-center mb-5">{t("how_create_project")}</h3>
        <p className="mb-3">{t("explanation_1")}</p>
        <p className="mb-3">{t("explanation_2")} </p>
        <p className="mb-3">
          <ol>
            <li>{t("general_informations")}</li>
            <li>{t("durability")}</li>
            <li>{t("employability")}</li>
            <li>{t("innovation")}</li>
            <li>{t("uniqueness")}</li>
            <li>{t("environmental_impact")}</li>
            <li>{t("indirect_jobs_and_service_providers")}</li>
            <li>{t("added_value_community")}</li>
            <li>{t("cofounder_project_team")}</li>
            <li>{t("supporting_materials_promotional_content")}</li>
          </ol>
        </p>
        <p className="mb-3">{t("explanation_3")}</p>
        <p className="mb-3">{t("explanation_4")}</p>
        <p className="mb-3">{t("explanation_5")}</p>
        <div
          className="mb-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn btn_theme"
            onClick={() => props.showExplanationHandler(false)}
          >
            {t("Suivant")}
          </button>
        </div>
      </div>
    </>
  );
};
export default ProjectCreationExplanation;
