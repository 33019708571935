import { FooterComponent } from "../../components/footer";
import SignInForm from "./components/SignInForm";

const SignIn = () => {
  return (
    <>
      <section className="author_form_area ">
        <section className="container-fluid justify-content-center">
          <SignInForm />
        </section>
      </section>
      <FooterComponent />
    </>
  );
};
export default SignIn;
