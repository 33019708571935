import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import jwt_decode from "jwt-decode";
import { addProject } from "../../../../reducers/project";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import i18n from "i18next";
import ProjectCreationExplanation from "./ProjectCreationExplanation";

const addProjectAction = async (formData: any, dispatch: any) => {
  return await dispatch(addProject(formData)).unwrap();
};
const CreateProjectForm = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [next, setNext] = useState(0);
  const [project, setProject] = useState<any>({});
  const [progress, setProgress] = useState(20);
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const [showExplanation, setShowExplanation] = useState(true);

  const nextHandler = (step: number) => {
    setNext(step);
  };

  const showExplanationHandler = (show: boolean) => {
    setShowExplanation(show);
  };

  const updateProject = (p: any) => {
    setProject(p);
  };

  const validate = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res: any = jwt_decode(token);
      project.porteur = res.id;
    }
    project.Language = currentLanguage;
    await addProjectAction(project, dispatch);
    setNext(9);
  };

  useEffect(() => {
    const p = Math.floor((next / 10) * 100);
    setProgress(p);
  }, [next]);

  return (
    <div className="row" id="counter" dir={t("dir")}>
      <div className="col-lg-8" style={{ margin: "0 auto" }}>
        <div className="details_wrapper_area">
          {showExplanation && (
            <ProjectCreationExplanation
              showExplanationHandler={showExplanationHandler}
            />
          )}
          {!showExplanation && (
            <form>
              <div className="donet_amount_form_area doner_content_pbottom">
                {!next && (
                  <FormStep1
                    project={project}
                    updateProject={updateProject}
                    next={nextHandler}
                    showExplanationHandler={showExplanationHandler}
                    currentLanguage={currentLanguage}
                  />
                )}
                <FormStep2
                  next={next}
                  nextHandler={nextHandler}
                  project={project}
                  updateProject={updateProject}
                />
                <FormStep3
                  next={next}
                  nextHandler={nextHandler}
                  project={project}
                  updateProject={updateProject}
                  validate={validate}
                />
                {next === 10 && (
                  <div className="mt-4 mb-4 text-center">
                    <h3 className="mb-4">{t("Félecitations")}</h3>
                    <h5>
                      {t("succes")} {""}
                    </h5>
                    <br></br>
                    <p>
                      {t("equipe_admin")} {""}
                    </p>
                    <p>
                      {t("emailandtel")} {""}
                    </p>
                    <p>{t("Notez_bien")}</p>
                  </div>
                )}
                <ProgressBar progress={progress} />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateProjectForm;
