import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProjectItem from "../../../../components/ProjectItem/ProjectItem";
import { getProjectsByUser } from "../../../../reducers/project";

const getProjectsByUserAction = async (dispatch: any, data: any) => {
  return await dispatch(getProjectsByUser(data)).unwrap();
};
const Projects = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listInnerRef = useRef<HTMLDivElement>(null);
  const status = useSelector((state: any) => state.Project.projects.status);
  const projects = useSelector((state: any) =>
    state.Project.projects.data ? state.Project.projects.data.value : []
  );
  const totalPages = useSelector((state: any) =>
    state.Project.projects.data ? state.Project.projects.data.totalPages : 0
  );
  const [pageSize, setPageSize] = useState(6);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
    } else if (status === "failed") {
      setIsLoading(false);
    } else if (status === "loading") {
      setIsLoading(true);
    }
  }, [status]);
  const getProjectsByUser = async () => {
    const token = props.currentUserId;
    const data = {
      id: token,
      page: currentPage,
      pageSize: pageSize,
    };
    await getProjectsByUserAction(dispatch, data);
  };

  useEffect(() => {
    getProjectsByUser();
  }, [currentPage]);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (totalPages >= currentPage + 1) {
          setCurrentPage((current) => current + 1);
        }
      }
    }
  };
  useEffect(() => {
    if (projects.length > 0 && status === "success") {
      const t = [...data, ...projects];
      setData(t);
    }
  }, [status]);
  const goToCreateProject = (e: any) => {
    e.preventDefault();
    navigate("/project-create");
  };
  return (
    <>
      <div
        className="mt-5"
        ref={listInnerRef}
        onScroll={handleScroll}
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <div className="container" style={{ height: "100%" }}>
          {data.length > 0 && (
            <div
              className="row"
              id="counter"
              style={{ height: "100%", marginBottom: "20px" }}
            >
              {data.map((item: any) => {
                const x = item.camp
                  ? { ...item.camp, project: item.proj }
                  : { project: item.proj };

                return (
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                    <ProjectItem campaign={x} />
                  </div>
                );
              })}
              {isLoading && (
                <div
                  className="spinner-border text-primary col-12"
                  role="status"
                  style={{ margin: " 0 auto" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          )}
          {data.length === 0 && status === "success" && (
            <a className="mt-2 pointer" href="" onClick={goToCreateProject}>
              <i
                className="fa fa-plus me-2"
                aria-hidden="true"
                style={{ color: "#0081af" }}
              ></i>
              <span className="me-2">{t("add_project")}</span>
            </a>
          )}
        </div>
      </div>
    </>
  );
};
export default Projects;
