import React from 'react';
import { useTranslation } from 'react-i18next';
import "./ContributionDetails.css";

const ContributionDetailsComponent = () => {
    const { t } = useTranslation();
    return (
<section id="aboutus_id" className="section_padding">
        <span>
          <img src="assets\img\about\Vector.png" alt="vector" style={{ width: '1556px', height: '131px',color:'#606060' }} />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
            <div className="info-box-container  box">
               <h2>{t('contribuez')}</h2>
               <h4>{t('liberons')}</h4>
               <div className="info-boxes">
                <div className="info-box">
                  <img src="assets\img\about\engagement.png" alt="Engagement vers la durabilité" />
                  <h3>{t('engagement')}</h3>
                  </div>
                  <div className="info-box">
                    <img src="assets\img\about\inclusion.png" alt="Inclusion et Accessibilité" />
                    <h3>{t('inclusion_accessibilité')}</h3>
                  </div>
                  <div className="info-box">
                    <img src="assets\img\about\innovation.png" alt="Innovation Permanente" />
                    <h3>{t('innovation_permanente')}</h3>
                  </div>
                <div className="info-box">
                  <img src="assets\img\about\Cybersecurity.png" alt="Protection des données privées" />
                  <h3>{t('protection_données')}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
</section>
    );
  };
  
  export default ContributionDetailsComponent;