import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import QuadraticCampaignService from "../services/QuadraticCampaignService";

export const createQuadraticCampaign = createAsyncThunk(
  "quadratic-campaign/create",
  async (quadraticCampaignData: any, thunkAPI) => {
    try {
      const { status, data } =
        await QuadraticCampaignService.createQuadraticCampaign(
          quadraticCampaignData
        );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const launchQuadraticCampaign = createAsyncThunk(
  "quadratic-campaign/launch",
  async (quadraticCampaignData: any, thunkAPI) => {
    try {
      const { status, data } =
        await QuadraticCampaignService.launchQuadraticCampaign(
          quadraticCampaignData
        );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateStatusQuadraticCampaign = createAsyncThunk(
  "quadratic-campaign/update-status",
  async (quadraticCampaignData: any, thunkAPI) => {
    try {
      const { status, data } =
        await QuadraticCampaignService.updateStatusQuadraticCampaign(
          quadraticCampaignData
        );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuadraticCampaignsByCorporate = createAsyncThunk(
  "quadratic-campaign/get-by-corporate",
  async (id: string, thunkAPI) => {
    try {
      const { status, data } =
        await QuadraticCampaignService.getQuadraticCampaignsByCorporate(id);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuadraticCampaignById = createAsyncThunk(
  "quadratic-campaign/get-by-id",
  async (id: string, thunkAPI) => {
    try {
      const { status, data } =
        await QuadraticCampaignService.getQuadraticCampaignById(id);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  createQuadraticCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
  launchQuadraticCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
  updateStatusQuadraticCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
  quadraticCampaignsByCorporate: {
    status: "idle",
    data: null,
    error: {},
  },
  quadraticCampaignById: {
    status: "idle",
    data: null,
    error: {},
  },
};
const QuadraticCampaignSlice = createSlice({
  name: "quadratic-campaign",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [createQuadraticCampaign.pending.type]: (state) => {
      state.createQuadraticCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [createQuadraticCampaign.fulfilled.type]: (state, action) => {
      state.createQuadraticCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [createQuadraticCampaign.rejected.type]: (state, action) => {
      state.createQuadraticCampaign = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [launchQuadraticCampaign.pending.type]: (state) => {
      state.launchQuadraticCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [launchQuadraticCampaign.fulfilled.type]: (state, action) => {
      state.launchQuadraticCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [launchQuadraticCampaign.rejected.type]: (state, action) => {
      state.launchQuadraticCampaign = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [updateStatusQuadraticCampaign.pending.type]: (state) => {
      state.updateStatusQuadraticCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateStatusQuadraticCampaign.fulfilled.type]: (state, action) => {
      state.updateStatusQuadraticCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateStatusQuadraticCampaign.rejected.type]: (state, action) => {
      state.updateStatusQuadraticCampaign = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [getQuadraticCampaignsByCorporate.pending.type]: (state) => {
      state.quadraticCampaignsByCorporate = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getQuadraticCampaignsByCorporate.fulfilled.type]: (state, action) => {
      state.quadraticCampaignsByCorporate = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getQuadraticCampaignsByCorporate.rejected.type]: (state, action) => {
      state.quadraticCampaignsByCorporate = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [getQuadraticCampaignById.pending.type]: (state) => {
      state.quadraticCampaignById = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getQuadraticCampaignById.fulfilled.type]: (state, action) => {
      state.quadraticCampaignById = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getQuadraticCampaignById.rejected.type]: (state, action) => {
      state.quadraticCampaignById = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
  },
});

export default QuadraticCampaignSlice.reducer;
