import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FileService from "../services/FileService";

export const getFile = createAsyncThunk(
  "get/file",
  async (dataFile: any, thunkAPI) => {
    try {
      const { status, data } = await FileService.getFileService(
        dataFile
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


const initialState = {
  getFile: {
    status: "idle",
    data: null,
    error: {},
  },
 
};
const FileSlice = createSlice({
  name: "File",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getFile.pending.type]: (state) => {
      state.getFile = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getFile.fulfilled.type]: (state, action) => {
      state.getFile = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getFile.rejected.type]: (state, action) => {
      state.getFile = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
   
  },
});

export default FileSlice.reducer;
