import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecentDonation from "../../../../components/RecentDonation/recentDonation";
import { getrecentDonation } from "../../../../reducers/recentdonationCampaign";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const getrecentDonationAction = async (id: any, dispatch: any) => {
  return dispatch(getrecentDonation(id)).unwrap();
};
const RecentDonations = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const donatorF = useSelector((state: any) =>
    state.RecentdonationCampaign.getdonationCampaign.data
      ? state.RecentdonationCampaign.getdonationCampaign.data.value
      : []
  );

  useEffect(() => {
    getrecentDonationAction(props.id, dispatch);
  }, []);
  return (
    <>
      {donatorF && (
        <div className="project_recentdonet_wrapper sidebar_boxed">
          <div className="sidebar_heading_main">
            <h3>{t("Donations_récentes")}</h3>
          </div>
          {donatorF &&
            donatorF.map((item: {}) => <RecentDonation donation={item} />)}
          {donatorF.length === 0 && (
            <a className="mt-2" href="">
              {t("pas_de_donation")}
            </a>
          )}
        </div>
      )}
    </>
  );
};
export default RecentDonations;
