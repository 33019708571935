import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RecentDonations from "../services/RecentDonationService";
import jwt_decode from "jwt-decode";

export const getrecentDonation = createAsyncThunk(
  "recentdonation/get",
  async (idprojects: string, thunkAPI) => {
    try {
      const { status, data } = await RecentDonations.RecentDonation(idprojects);
          console.log(data)
          return data;
      
     } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  getdonationCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
};
const DonationSlice = createSlice({
  name: "get-recentDonation",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getrecentDonation.pending.type]: (state) => {
      state.getdonationCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getrecentDonation.fulfilled.type]: (state, action) => {
      state.getdonationCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getrecentDonation.rejected.type]: (state, action) => {
      state.getdonationCampaign = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
 
  },
});

export default DonationSlice.reducer;
