import { useState } from "react";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { wordCounter } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep22 = (props: any) => {
  const { t } = useTranslation();

  const [isInnovative, setIsInovative] = useState(
    props.project.isInnovative !== undefined
      ? props.project.isInnovative
        ? "true"
        : "false"
      : "true"
  );
  const [innovationDetails, setInnovationDetails] = useState(
    props.project.innovationDetails ? props.project.innovationDetails : ""
  );
  const [innovationDetailsError, setInnovationDetailsError] = useState("");

  const handleIsInnovativeChange = (event: any) => {
    setIsInovative(event.target.value);
    if (event.target.value === "false") {
      setInnovationDetailsError("");
      setInnovationDetails("");
    }
  };
  const handleInnovationDetailsChange = (event: any) => {
    setInnovationDetails(event.target.value);
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();
    if (isInnovative === "true" && !wordCounter(innovationDetails, 10)) {
      setInnovationDetailsError(t("Veuillez_taper_au_moins_10_mots"));
      return;
    } else {
      setInnovationDetailsError("");
    }
    props.project.isInnovative = isInnovative === "true" ? true : false;
    props.project.innovationDetails = innovationDetails;
    props.updateProject(props.project);
    props.nextHandler(4);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(2)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">{t("Innovation")}</h5>
        <span className="mt-4 mb-4">
          {t("innovant")} <br />
          {t("Exemple")}
        </span>
        <div className="col-12">
          <SelectInput
            selectedCategory={isInnovative}
            selectList={[
              { _id: "true", name: t("yes") },
              { _id: "false", name: t("no") },
            ]}
            onChange={handleIsInnovativeChange}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("précisions")}
            onChange={handleInnovationDetailsChange}
            disabled={isInnovative === "false"}
            error={innovationDetailsError}
            value={innovationDetails}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep22;
