import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RecentDonations from "../services/RecentDonationService";
import jwt_decode from "jwt-decode";

export const getrecentDonation = createAsyncThunk(
  "recentdonation/get",
  async (idprojects: string, thunkAPI) => {
    try {
      const { status, data } = await RecentDonations.RecentDonation(idprojects);
          console.log(data)
          return data;
      
     } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getrecentDonationbyproject = createAsyncThunk(
  "recentdonation/get",
  async (idproject: string, thunkAPI) => {
    try {
      const { status, data } = await RecentDonations.RecentDonationbyProject(idproject);
      if (status === 200) {
         console.log('recentdonation',data)
          return data;
        
      } 
      if(status==404){
        return null;
      }
      else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
 
  getdonationbyproject: {
    status: "idle",
    data: null,
    error: {},
  },
  getdonation: {
    status: "idle",
    data: null,
    error: {},
  },
};
const DonationSlice = createSlice({
  name: "get-recentDonation",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getrecentDonation.pending.type]: (state) => {
      state.getdonation = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getrecentDonation.fulfilled.type]: (state, action) => {
      state.getdonation = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getrecentDonation.rejected.type]: (state, action) => {
      state.getdonation = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getrecentDonationbyproject.pending.type]: (state) => {
      state.getdonationbyproject = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getrecentDonationbyproject.fulfilled.type]: (state, action) => {
      state.getdonationbyproject = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getrecentDonationbyproject.rejected.type]: (state, action) => {
      state.getdonationbyproject = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
  },
});

export default DonationSlice.reducer;
