import { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
import Button from "../Button/Button";

const GoogleLoginBtn = (props: any) => {
  const GOOGLE_CLIENT_ID =
    "872948798744-df1eqadmkv0nmsu9dmeu27pt8c2o330d.apps.googleusercontent.com";

  const navigate = useNavigate();

  useEffect(() => {
    // function start() {
    //   gapi.auth2.init({
    //     clientId: GOOGLE_CLIENT_ID,
    //     scope: "",
    //   });
    // }
    // gapi.load("client:auth2", start);
  });
  const onSuccess = (res: any) => {
    if(props.login != undefined)
    {
    props.login({userData:{email:res.profileObj.email,password:""}});
    }
    else if (props.googleOath != undefined)
    {
      let userData = {
        name: res.profileObj.familyName +" "+res.profileObj.givenName ,
        email: res.profileObj.email,
        password: "",
        gouvernorat: "",
        walletPubKey: "0xCCdA32c8Ba20D64fF1A1C89f078F7E66bc8161ac",
        walletPrivKey:
          "0xbce77d20a0d2f18194f39a4d199c0138787b6cf5dc6d6ed6776f5ca28ef9df09",
        id_google : res.profileObj.googleId
      };
    props.googleOath(userData)
    }
  };
  const onFailure = (error: any) => {
    console.log("LOGIN Failed res : ", error);
  };
  return (
    <GoogleLogin
      render={(renderProps) => (
        <Button
          cssClass="d-grid"
          type="submit"
          title="Continuer avec Google"
          onClick={renderProps.onClick}
        />
      )}
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={onFailure}
    ></GoogleLogin>

    
  );
};
export default GoogleLoginBtn;
