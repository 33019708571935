const BottomButton = (props: any) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <button className="btn btn_navber me-2" onClick={props.firstBtnAction}>
        {props.firstBtnTitle}
      </button>
      <button className="btn btn_theme me-2" onClick={props.secondBtnAction}>
        {props.secondBtnTitle}
      </button>
    </div>
  );
};
export default BottomButton;
