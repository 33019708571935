import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RecentDonation from "../../project/profileProject/components/recentDonation";
import Input from "../../../components/Input/Input";
import jwt_decode from "jwt-decode";
import campaign, { getCampaign } from "../../../reducers/campaign";

import { informationUser } from "../../../reducers/projectDetails";
import { adddonations } from "../../../reducers/donation";
import { blockInvalidChar } from "../../../utils/functions";
import { useTranslation } from "react-i18next";

import "../../../translations/i18n";

const DonationFail = () => {
  const { t } = useTranslation();
  const params = useParams();

  useEffect(() => {
 
    
  }, []);
  return (
    <>
      <section>
        <div className="container">
          <div className="row" id="counter">
            <div className="col-lg-8" style={{ margin: "0 auto" }}>
              <div className="details_wrapper_area">
                <form>
                  <div className="donet_amount_form_area doner_content_pbottom">
                    <div className="mt-4 mb-4 text-center">
                      <h3 className="mb-4"> Oops!  </h3>
                      <h5>Votre donation a été échoué, veuillez vérifier votre solde et réessayer plus tard!</h5>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DonationFail;
