import { useEffect, useState } from "react";
import SelectFileButton from "../../../../components/SelectFileBtn/SelectFileBtn";
import config from "../../../../config/config";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import { getFileService } from "../../../../services/FileService";

const ProjectImages = (props: any) => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [photos, setPhotos] = useState<File[]>([]);
  const [photosError, setPhotosError] = useState("");
  const [picture, setPicture] = useState("");
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const hideEditHandler = () => {
    setShowEdit(false);
  };
  const handlePhotosChange = (file: any) => {
    const files = file.target.files;
    const allFiles = [...photos, ...files];
    const list: any = [];
    allFiles.forEach((file) => {
      let exist = false;
      list.forEach((elt: any) => {
        if (file.name === elt.name) {
          exist = true;
        }
      });
      if (!exist) {
        list.push(file);
      }
    });
    setPhotos(list);
    if (files) {
      setPhotosError("");
    }
  };
  const removePhoto = (index: number) => {
    const res = [...photos];
    res.splice(index, 1);
    setPhotos(res);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  const validate = async () => {
    if (!photos.length) {
      setPhotosError(t("Il_faut_ajouter_au_moins_une_image_à_votre_project"));
      return;
    } else {
      setPhotosError("");
    }
    const filesToAdd = [...photos];
    const data = { id: props.id, files: filesToAdd };
    props.uploadFilesHandler(data);
    setShowEdit(false);
  };
  const getFileHandler = async (file: any) => {
    const { status, data } = await getFileService(file);
    if (status === 201 || status === 200) {
      setPicture(
        config.apiUrl + "/project/getFile/" + props.photo
      );
    } else {
      setPicture("");
    }
  };
  useEffect(() => {
    getFileHandler(props.photo);
  }, [props.photo]);
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{props.title}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
        {showEdit && props.canEdit && (
          <i
            className={"fa fa-times-circle pointer"}
            style={{
              color: "#818090",
            }}
            onClick={hideEditHandler}
          ></i>
        )}
      </div>
      {picture && !showEdit && (
        <div className="row popup-gallery mt-3">
          <div className="col-lg-4 co-md-6 col-sm-12 col-12">
            <div className="gallery_item">
              <img
                src={config.apiUrl + "/project/getFile/" + props.photo}
                alt="img"
              />
            </div>
          </div>
        </div>
      )}
      {!picture && !showEdit && (
        <div className="row popup-gallery mt-3">
          <h5>Aucune image</h5>
        </div>
      )}
      {showEdit && (
        <div className="mt-3">
          <SelectFileButton
            title={t("Télécharger")}
            onChange={handlePhotosChange}
            accept={"image/*"}
            error={photosError}
          />

          {photos.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {photos.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removePhoto(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectImages;
