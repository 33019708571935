import { useDispatch, useSelector } from "react-redux";
import ProjectItem from "../../../../components/ProjectItem/ProjectItem";
import { getProjectsByUser } from "../../../../reducers/project";
import { useEffect, useRef, useState } from "react";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

const getProjectsByUserAction = async (dispatch: any, data: any) => {
  return await dispatch(getProjectsByUser(data)).unwrap();
};
const Projects = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listInnerRef = useRef<HTMLDivElement>(null);
  const status = useSelector((state: any) => state.Project.projects.status);
  const projects = useSelector((state: any) =>
    state.Project.projects.data ? state.Project.projects.data.value : []
  );
  const totalPages = useSelector((state: any) =>
    state.Project.projects.data ? state.Project.projects.data.totalPages : 0
  );
  const [pageSize, setPageSize] = useState(6);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
    } else if (status === "failed") {
      setIsLoading(false);
    } else if (status === "loading") {
      setIsLoading(true);
    }
  }, [status]);
  const getProjectsByUser = async () => {
    var tok = localStorage.getItem("token");
    if (tok) {
      var result: any = jwt_decode(tok);
      const data = {
        id: result.id,
        page: currentPage,
        pageSize: pageSize,
      };
      await getProjectsByUserAction(dispatch, data);
    }
  };

  useEffect(() => {
    getProjectsByUser();
  }, [currentPage]);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (totalPages >= currentPage + 1) {
          setCurrentPage((current) => current + 1);
        }
      }
    }
  };
  useEffect(() => {
    if (projects && projects.length > 0 && status === "success") {
      const t = [...data, ...projects];
      setData(t);
    }
  }, [status]);
  return (
    <>
      <div
        className="mb-5"
        ref={listInnerRef}
        onScroll={handleScroll}
        style={{
          height: "80vh",
          overflowY: "scroll",
        }}
        dir={t("dir")}
      >
        <div className="container" style={{ height: "100%" }}>
          {data.length > 0 && status === "success" && (
            <div
              className="row"
              id="counter"
              style={{ height: "100%", marginBottom: "20px" }}
            >
              {data.map((item: any) => {
                const x = item.camp
                  ? { ...item.camp, project: item.proj }
                  : { project: item.proj };
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 col-12 pb-2"
                    key={item._id}
                  >
                    <ProjectItem campaign={x} />
                  </div>
                );
              })}
              {isLoading && (
                <div
                  className="spinner-border text-primary col-12"
                  role="status"
                  style={{ margin: " 0 auto" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          )}
          {data.length === 0 && status === "success" && (
            <section id="subscribe_area" className="mt-5">
              <div className="container">
                <div className="subscribe_wrapper">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="subscribe_text text-center mt-5 mb-5">
                        <h3 style={{ fontWeight: "300" }}>
                          {t("Aucun_Projet")}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};
export default Projects;
