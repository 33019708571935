import Api from '../config/axios';
import config from '../config/config';

export const getCommonInfosService = async (regions: any) => {
	const url = config.apiUrl + '/project/Dash/Kapi';
	try {
		return await Api.post(url, {regions});
	} catch (error) {
		throw new Error('An error occured');
	}
};
export const getRecentProjects = async (regions: any) => {
	const url = config.apiUrl + '/project/Get/RecentProject/';
	try {
		return await Api.get(url);
	} catch (error) {
		throw new Error('An error occured');
	}
};
export const getCategoriesProjects = async (regions: any) => {
	const url = config.apiUrl + '/project/Get/BestCategory/';
	try {
		return await Api.get(url);
	} catch (error) {
		throw new Error('An error occured');
	}
};
export default { getCommonInfosService,getRecentProjects,getCategoriesProjects };
