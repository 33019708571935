import './statistics.css'; // This will be your CSS file for styling
import { useTranslation } from "react-i18next";
import { getCommonInfos } from "../../../reducers/acceuil";
import "../../../translations/i18n";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect  } from "react";


const getCommonInfosAction = async (dispatch: any, regions: string[]) => {
	return Promise.resolve(await dispatch(getCommonInfos(regions)).unwrap());
};
const StatisticsComponent: React.FC = () => {

    const dispatch = useDispatch();

  const commonInfos = useSelector((state: any) => state.Acceuil.getCommonInfos.data);

  const { t } = useTranslation();
  useEffect(() => {
		const regions: any = [];
		getCommonInfosAction(dispatch, regions);

	}, [dispatch]);
  return (
    <div className="stats-container">
      <h2>{t('plateforme_financement')}</h2>
      <div className="stats-grid">
        <div className="stats-item">
        <img src="assets/img/icon/camp2.png" alt="icon" />
          {commonInfos &&  <h2 className="counter">{commonInfos.value.nbCampaignAccepted}</h2>}
          <p>{t('Campagnes_réalisées')}</p>
        </div>
        <div className="stats-item">
        <img src="assets/img/icon/hand.png" alt="icon" />
           {commonInfos &&  <h2 className="counter">{commonInfos.value.nbDonations}</h2>}
            <p>{t('Donations_Faites')}</p>
        </div>
        <div className="stats-item">
        <img src="assets/img/icon/mone1.png" alt="icon"  />
            {commonInfos &&  <h2 className="counter">{commonInfos.value.balance}</h2>}
            <p>{t('Montant_total_collectés')}</p>
        </div>
        <div className="stats-item">
        <img src="assets/img/icon/camp.png" alt="icon" />
            {commonInfos &&  <h2 className="counter">{commonInfos.value.nbQfAccepted}</h2>}
            <p>{t("Financement_quadratique")}</p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsComponent;
