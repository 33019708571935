import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import InvestorAgentService from "../services/InvestorAgentService";

export const addInvestorAgent = createAsyncThunk(
  "investorAgent/add",
  async (dataInvestorAgent: any, thunkAPI) => {
    try {
      const { status, data } = await InvestorAgentService.addInvestorAgent(
        dataInvestorAgent
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadDocuments = createAsyncThunk(
  "investorAgent/upload-documents",
  async (dataInvestorAgent: any, thunkAPI) => {
    try {
      const { status, data } = await InvestorAgentService.uploadDocuments(
        dataInvestorAgent
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getInvestors = createAsyncThunk(
  "investorAgent/get-investors",
  async (dataInvestorAgent: any, thunkAPI) => {
    try {
      const { status, data } = await InvestorAgentService.getInvestors(
        dataInvestorAgent
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getInvestorAgentDetails = createAsyncThunk(
  "investorAgent/get-details",
  async (id: string, thunkAPI) => {
    try {
      const { status, data } =
        await InvestorAgentService.getInvestorAgentDetails(id);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const editInvestorAgent = createAsyncThunk(
  "investorAgent/edit",
  async (investorAgentData: string, thunkAPI) => {
    try {
      const { status, data } =
        await InvestorAgentService.editInvestorAgent(investorAgentData);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const editCorporatePhoto = createAsyncThunk(
  "investor/edit-photo",
  async (investorData: string, thunkAPI) => {
    try {
      const { status, data } =
        await InvestorAgentService.editCorporatePhoto(investorData);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



const initialState = {
  addInvestorAgent: {
    status: "idle",
    data: null,
    error: {},
  },
  uploadDocuments: {
    status: "idle",
    data: null,
    error: {},
  },
  investors: {
    status: "idle",
    data: null,
    error: {},
  },
  investorAgent: {
    status: "idle",
    data: null,
    error: {},
  },
  editInvestorAgent: {
    status: "idle",
    data: null,
    error: {},
  },
  editCorporatePhoto: {
    status: "idle",
    data: null,
    error: {},
  },
};
const InvestorAgentSlice = createSlice({
  name: "investorAgent",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addInvestorAgent.pending.type]: (state) => {
      state.addInvestorAgent = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [addInvestorAgent.fulfilled.type]: (state, action) => {
      state.addInvestorAgent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addInvestorAgent.rejected.type]: (state, action) => {
      state.addInvestorAgent = {
        status: "failed",
        data: action.payload,
        error: action.payload.message,
      };
    },
    [uploadDocuments.pending.type]: (state) => {
      state.uploadDocuments = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [uploadDocuments.fulfilled.type]: (state, action) => {
      state.uploadDocuments = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [uploadDocuments.rejected.type]: (state, action) => {
      state.uploadDocuments = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [getInvestors.pending.type]: (state) => {
      state.investors = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getInvestors.fulfilled.type]: (state, action) => {
      state.investors = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getInvestors.rejected.type]: (state, action) => {
      state.investors = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getInvestorAgentDetails.pending.type]: (state) => {
      state.investorAgent = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getInvestorAgentDetails.fulfilled.type]: (state, action) => {
      state.investorAgent = {
        status: "success",
        data: action.payload,
        error: {}
      };
    },
    [getInvestorAgentDetails.rejected.type]: (state, action) => {
      state.investorAgent = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [editInvestorAgent.pending.type]: (state) => {
      state.editInvestorAgent = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [editInvestorAgent.fulfilled.type]: (state, action) => {
      state.editInvestorAgent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [editInvestorAgent.rejected.type]: (state, action) => {
      state.editInvestorAgent = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [editCorporatePhoto.pending.type]: (state) => {
      state.editCorporatePhoto = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [editCorporatePhoto.fulfilled.type]: (state, action) => {
      state.editCorporatePhoto = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [editCorporatePhoto.rejected.type]: (state, action) => {
      state.editCorporatePhoto = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
  },
});

export default InvestorAgentSlice.reducer;
