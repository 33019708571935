import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import {
  launchQuadraticCampaign,
  updateStatusQuadraticCampaign,
} from "../../../../reducers/quadraticCampaign";
import Campaigns from "./Campaigns";
import jwt_decode from "jwt-decode";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import moment from "moment";
import { regionsArabe } from "../../../../utils/constants";
import _ from "lodash";
import i18n from "i18next";

const launchQuadraticCampaignAction = async (dispatch: any, data: any) => {
  return await dispatch(launchQuadraticCampaign(data)).unwrap();
};
const updateStatusQuadraticCampaignAction = async (
  dispatch: any,
  data: any
) => {
  return await dispatch(updateStatusQuadraticCampaign(data)).unwrap();
};
const Simulation = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const date = new Date();
  const navigate = useNavigate();
  const [amount, setAmount] = useState();
  const [amountError, setAmountError] = useState("");
  const [validateAmount, setValidateAmount] = useState(false);
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const [cssClass, setCssClass] = useState("");
  const result = useSelector(
    (state: any) => state.QuadraticCampaign.launchQuadraticCampaign.data
  );
  const status = useSelector(
    (state: any) => state.QuadraticCampaign.launchQuadraticCampaign.status
  );
  const statusUpdate = useSelector(
    (state: any) => state.QuadraticCampaign.updateStatusQuadraticCampaign.status
  );
  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
    setValidateAmount(false);
  };
  const validateAmountHandler = async () => {
    if (!amount) {
      setAmountError(t("Obligatoire"));
      return;
    }
    setAmountError("");
    setCssClass("");
    setValidateAmount(true);
    const ids: any = [];
    props.campaigns.value.map((item: any) => ids.push(item.idCampaigne));
    const data = {
      campaignsId: ids,
      amount: amount,
      regions: props.regions,
      categories: props.categories,
      agentInvestor: "",
    };
    const token = localStorage.getItem("token");
    if (token) {
      const result: any = jwt_decode(token);
      data.agentInvestor = result.id;
    }
    await launchQuadraticCampaignAction(dispatch, data);
  };
  const launchCampaign = async () => {
    if (!amount) {
      setAmountError("Obligatoire");
      return;
    }
    if (amount && !validateAmount) {
      setCssClass("red-border");
      return;
    }

    if (result && result.idCampaignQuadratique) {
      const data = {
        id: result.idCampaignQuadratique,
        status: "Created",
      };

      await updateStatusQuadraticCampaignAction(dispatch, data);
    }
  };

  const [componentRef, setComponentRef] = useState<any>(null);
  const getRegionName = (region: any) => {
    const x = _.find(regionsArabe, { _id: region });
    if (x && currentLanguage === "ar") {
      return x.name;
    } else {
      return region;
    }
  };
  return (
    <>
      {statusUpdate !== "success" && (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              name="amount"
              type="text"
              placeholder={t("Ajouter_Somme")}
              onChange={handleAmountChange}
              error={amountError}
              cssClass="flex-grow"
            />
            <Button
              cssClass={"ml me-2 mb-3 " + cssClass}
              title={t("simulation")}
              onClick={validateAmountHandler}
              icon={
                status === "loading" ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null
              }
            />
          </div>
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ReactToPrint
              content={() => componentRef}
              trigger={() => (
                <Button cssClass="ml me-2 mb-3" title={t("Télecharger_PDF")} />
              )}
            />
          </div>
          <section
            className="mb-4"
            style={{ display: "flex" }}
            ref={(response) => setComponentRef(response)}
          >
            <div className="row">
              <div className="col-12 mb-4">
                <div className="card">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      {t("Date")}:{" "}
                      {moment(date.toString()).format("DD-MM-YYYY")}
                    </li>
                    <li className="list-group-item">{t("Simulé")}</li>
                    {amount !== undefined && (
                      <li className="list-group-item">
                        {t("Montant")}: {amount} {t("tnd")}
                      </li>
                    )}
                    <li className="list-group-item">
                      {" "}
                      <h6 className="mb-2"> {t("regions")}</h6>
                      <div>
                        {props.regions &&
                          props.regions.length > 0 &&
                          props.regions.map((item: any, index: any) => (
                            <span key={index} className="item-1 me-1">
                              {getRegionName(item)}
                            </span>
                          ))}
                        {props.regions && props.regions.length === 0 && (
                          <span className="item-1 me-1">{t("aucun")}</span>
                        )}
                      </div>
                    </li>
                    <li className="list-group-item">
                      <h6 className="mb-2"> {t("Secteurs")}</h6>
                      {props.categories &&
                        props.categories.length > 0 &&
                        props.categories.map((item: any, index: any) => (
                          <span key={index} className="item-1 me-1">
                            {item.name}
                          </span>
                        ))}
                      {props.categories && props.categories.length === 0 && (
                        <span className="item-1 me-1">{t("aucun")}</span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col">
                <Campaigns
                  campaigns={props.campaigns}
                  showAmount={true}
                  amounts={result ? result.value : null}
                />
              </div>
            </div>
          </section>
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              cssClass="mb-3"
              title={t("Annuler")}
              onClick={props.annulate}
            />
            <Button
              cssClass="mb-3"
              title={t("Lancer_une_campagne_quadratique")}
              onClick={launchCampaign}
              icon={
                statusUpdate === "loading" ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null
              }
            />
          </div>
        </>
      )}
      {statusUpdate === "success" && (
        <div className="mt-4 mb-4 text-center">
          <h3 className="mb-4">{t("Félecitations")}</h3>
          <h5>
            {t("succes_create_quadratic_campaign")} {""}
          </h5>
          <a
            href=""
            onClick={() =>
              navigate("/quadratic-campaign:" + result.idCampaignQuadratique)
            }
            className="me-2 pointer"
          >
            {t("see_details")}
          </a>
        </div>
      )}
    </>
  );
};
export default Simulation;
