const Title = (props: any) => {
  return (
    <div className="row">
      <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
        <div className="section_heading">
          <h2>{props.title}</h2>
        </div>
      </div>
    </div>
  );
};
export default Title;
