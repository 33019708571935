import "./Input.css";

const Input = (props: any) => {
  return (
    <div className={"mb-3 " + props.cssClass}>
      <div className="input-group" style={{ alignItems: "center" }}>
        {props.label && <label className="me-2">{props.label}</label>}
        {props.fixedValue && (
          <div
            className="input-group-addon"
            style={{
              width: "unset",
              padding: "0 5px",
              color: "#6c757d96",
              fontSize: "12px",
            }}
          >
            {props.fixedValue}
          </div>
        )}
        {props.type !== "date" && (
          <input
            name={props.name}
            type={props.type}
            className={"form-control " + props.cssClass}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
            value={props.value}
            onKeyDown={props.onKeyDown}
            min={props.min}
            list={
              props.datalist && props.datalist.value.length > 0
                ? "data-list"
                : ""
            }
          />
        )}
        {props.type === "date" && (
          <input
            name={props.name}
            type={props.type}
            className={"form-control " + props.cssClass}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
            value={props.value}
            onKeyDown={props.onKeyDown}
            min={props.min}
          />
        )}
        {props.icon && (
          <div className="input-group-addon" onClick={props.onClickIcon}>
            {props.icon}
          </div>
        )}
        {props.datalist && props.datalist.value.length > 0 && (
          <datalist id="data-list">
            {props.datalist.value.map((item: any) => (
              <option value={item.name} />
            ))}
          </datalist>
        )}
      </div>
      <small className="text-danger mt-3 mb-3">{props.error}</small>
    </div>
  );
};
export default Input;
