import Api from "../config/axios";
import config from "../config/config";

export const getFileService = async (file: any) => {
  const url = config.apiUrl + "/project/getFile/" + file;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getFileService,
};
