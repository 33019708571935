import { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import {
  blockInvalidChar,
  isPositiveInteger,
} from "../../../../utils/functions";

const ProjectCollaborators = (props: any) => {
  const { t } = useTranslation();
  const [nbCollaboratorsMan, setNbCollaboratorsMan] = useState(
    props.project.nbCollaboratorsMan
      ? props.project.nbCollaboratorsMan
      : undefined
  );
  const [nbCollaboratorsWoman, setNbCollaboratorsWoman] = useState(
    props.project.nbCollaboratorsWoman
      ? props.project.nbCollaboratorsWoman
      : undefined
  );
  const [nbCollaboratorsManError, setNbCollaboratorsManError] = useState("");
  const [nbCollaboratorsWomanError, setNbCollaboratorsWomanError] =
    useState("");
  const [showEdit, setShowEdit] = useState(false);
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const handleNbCollaboratorsManChange = (event: any) => {
    setNbCollaboratorsMan(event.target.value);
  };
  const handleNbCollaboratorsWomanChange = (event: any) => {
    setNbCollaboratorsWoman(event.target.value);
  };
  const validateForm = () => {
    let invalidForm = false;

    if (!nbCollaboratorsMan) {
      invalidForm = true;
      setNbCollaboratorsManError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbCollaboratorsMan))) {
      setNbCollaboratorsManError(t("caractére_numérique"));
    } else {
      setNbCollaboratorsManError("");
    }

    if (!nbCollaboratorsWoman) {
      invalidForm = true;
      setNbCollaboratorsWomanError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbCollaboratorsWoman))) {
      setNbCollaboratorsWomanError(t("caractére_numérique"));
    } else {
      setNbCollaboratorsWomanError("");
    }

    return invalidForm;
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const project = { ...props.project };
    project.nbCollaboratorsMan = nbCollaboratorsMan;
    project.nbCollaboratorsWoman = nbCollaboratorsWoman;
    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  const annulate = () => {
    setShowEdit(false);
  };

  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{t("Nombre_des_collaborators")}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
      </div>
      {!showEdit && (
        <div className="social_icon_sidebar">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <p>
                {t("Nombre_des_hommes")} :{" "}
                {props.project.nbCollaboratorsMan}
              </p>
            </li>

            <li className="list-group-item">
              <p>
                {t("Nombre_des_femmes")} :{" "}
                {props.project.nbCollaboratorsWoman}
              </p>
            </li>
          </ul>
        </div>
      )}
      {showEdit && (
        <div className="social_icon_sidebar">
          {" "}
          <Input
            label={t("Homme")}
            type={"number"}
            onChange={handleNbCollaboratorsManChange}
            error={nbCollaboratorsManError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbCollaboratorsMan}
          />
          <Input
            label={t("Femme")}
            type={"number"}
            onChange={handleNbCollaboratorsWomanChange}
            error={nbCollaboratorsWomanError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbCollaboratorsWoman}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectCollaborators;
