import Api from "../config/axios";
import config from "../config/config";

export const signinService = async (userData: {}) => {
  const url = config.apiUrl + "/donator/login";

  try {
    return await Api.post(url, { userData });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const sendEmailForRestPasswordService = async (userData: {}) => {
  const url = config.apiUrl + "/users/sendEmail";
  try {
    return await Api.post(url, userData);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const restPasswordService = async (userData: any) => {
  const url = config.apiUrl + "/users/forgotpassword/" + userData.id;
  try {
    return await Api.put(url, userData);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  signinService,
  sendEmailForRestPasswordService,
  restPasswordService,
};
