import Api from "../config/axios";
import config from "../config/config";

export const getCategoriesService = async () => {
  const url = config.apiUrl + "/categoryproj/";

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  getCategoriesService,
};
