import Api from "../config/axios";
import config from "../config/config";

export const getTransactionsByUserService = async (token: any) => {
  const url = config.apiUrl + "/donation/web3/wallethistory/" + token;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
    getTransactionsByUserService,
};
