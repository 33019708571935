import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RecentDonation from "../../project/profileProject/components/recentDonation";
import Input from "../../../components/Input/Input";
import jwt_decode from "jwt-decode";
import campaign, { getCampaign } from "../../../reducers/campaign";

import { informationUser } from "../../../reducers/projectDetails";
import { adddonations } from "../../../reducers/donation";
import { blockInvalidChar } from "../../../utils/functions";
import { FooterComponent } from "../../../components/footer";
import { useTranslation } from "react-i18next";

import "../../../translations/i18n";
const addDonation = async (dispatch:any, data:any) => {
  console.log('Dispatching addDonation with data:', data);

  try {
    const response = await dispatch(adddonations(data)).unwrap();
    console.log('addDonation response:', response);
    return response;
  } catch (error) {
    
    throw error;
  }
};

const Donationsucess = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
    const [isDonationProcessed, setIsDonationProcessed] = useState(false);

  useLayoutEffect(() => {
    if (params.token && !isDonationProcessed) {
      let result: any = jwt_decode(params.token);
      addDonation(dispatch, {
        donator: result.donator,
        compaign: result.compaign,
        montant: result.montant,
      })
        .then(() => {
          setIsDonationProcessed(true);
        })
        .catch((error) => {
          console.error('Error processing donation:', error);
        });
    }
  }, [params.token, dispatch, isDonationProcessed]);
  return (
    <>
      <section>
        <div className="container">
          <div className="row" id="counter">
            <div className="col-lg-8" style={{ margin: "0 auto" }}>
              <div className="details_wrapper_area">
                <form>
                  <div className="donet_amount_form_area doner_content_pbottom">
                    <div className="mt-4 mb-4 text-center">
                      <h3 className="mb-4"> {t('Félecitations')}  </h3>
                      <h5>{t('sucessDonation')}</h5>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent/>
    </>
  );
};
export default Donationsucess;
