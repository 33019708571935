/**
 * Check if is a valid password
 * @param val
 * @returns
 */
export const checkIsValidPassword = (val) => {
  /**Has minimum 6 characters in length. Adjust it by modifying {6,}
    At least one uppercase English letter. You can remove this condition by removing (?=.*?[A-Z])
    At least one lowercase English letter.  You can remove this condition by removing (?=.*?[a-z])
    At least one digit. You can remove this condition by removing (?=.*?[0-9])
    At least one special character,  You can remove this condition by removing (?=.*?[#?!@$%^&*-]) */
  const pwdregex = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+:;/_]).{6,}$/;
  return val ? pwdregex.test(val) : false;
};

/**
 * Check if is a valid email
 * @param val
 * @returns
 */
export const checkIsValidEmail = (val) => {
  /** mail regex */
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  return val ? regex.test(val) : false;
};

export const isPositiveInteger = (value) => {
  if (Number.isInteger(value) && value >= 0) {
    return true;
  }
  return false;
};

export const wordCounter = (text, numberWords) => {
  const words = text
    .replace(/[',:;\-.?!<>+#*%(){}_&"«»’//]/g, " ")
    .split(" ")
    .filter((i) => i !== "");
  if (words.length < numberWords) {
    return false;
  } else {
    return true;
  }
};

export const getNumberOfDays = (startDate, endDate) => {
  const days = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  return days;
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const validatePhoneNumber = (input_str) => {
  var re = /^(00216|216|\+216)(9|5|4|2)([0-9]{7})$/;

  return re.test(input_str);
};
