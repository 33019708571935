import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import Textarea from "../../../../components/Textarea/Textarea";
import { wordCounter } from "../../../../utils/functions";
import i18n from "i18next";

const ProjectDescription = (props: any) => {
  const { t } = useTranslation();
  const [showTranslate, setShowTranslate] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  useEffect(() => {
    if (currentLanguage === "ar") {
      setTitle(props.project.titre ? props.project.titre : "");
      setDescription(
        props.project.description ? props.project.description : ""
      );
    }
    if (currentLanguage === "fr") {
      setTitle(props.project.frenchTitre ? props.project.frenchTitre : "");
      setDescription(
        props.project.frenchDescription ? props.project.frenchDescription : ""
      );
    }
  }, [currentLanguage]);
  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };
  const showEditHandler = () => {
    setShowEdit(true);
    if (currentLanguage === "ar") {
      setTitle(props.project.titre ? props.project.titre : "");
      setDescription(
        props.project.description ? props.project.description : ""
      );
    }
    if (currentLanguage === "fr") {
      setTitle(props.project.frenchTitre ? props.project.frenchTitre : "");
      setDescription(
        props.project.frenchDescription ? props.project.frenchDescription : ""
      );
    }
  };
  const showTranslateHandler = () => {
    setShowTranslate(true);
    if (currentLanguage === "fr") {
      setTitle(props.project.titre ? props.project.titre : "");
      setDescription(
        props.project.description ? props.project.description : ""
      );
    }
    if (currentLanguage === "ar") {
      setTitle(props.project.frenchTitre ? props.project.frenchTitre : "");
      setDescription(
        props.project.frenchDescription ? props.project.frenchDescription : ""
      );
    }
  };
  const annulate = () => {
    setShowEdit(false);
  };
  const annulateTranslate = () => {
    setShowTranslate(false);
  };
  const validateForm = () => {
    let invalidForm = false;
    if (!title) {
      invalidForm = true;
      setTitleError(t("Ce_champ_est_obligatoire"));
    } else {
      setTitleError("");
    }
    if (!description) {
      invalidForm = true;
      setDescriptionError(t("Ce_champ_est_obligatoire"));
    } else if (!wordCounter(description, 50)) {
      invalidForm = true;
      setDescriptionError(t("Veuillez_taper_au_moins_50_mots"));
    } else {
      setDescriptionError("");
    }
    return invalidForm;
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const project = { ...props.project };
    if (currentLanguage === "fr") {
      project.frenchTitre = title;
      project.frenchDescription = description;
    }
    if (currentLanguage === "ar") {
      project.titre = title;
      project.description = description;
    }
    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  const translate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const project = { ...props.project };
    if (currentLanguage === "fr") {
      project.titre = title;
      project.description = description;
    }
    if (currentLanguage === "ar") {
      project.frenchTitre = title;
      project.frenchDescription = description;
    }

    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      {!showEdit && !showTranslate && (
        <div
          className="mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 className="me-2">
            {currentLanguage === "ar"
              ? props.project.titre
                ? props.project.titre
                : props.project.frenchTitre
              : props.project.frenchTitre
              ? props.project.frenchTitre
              : props.project.titre}
          </h2>
          {props.canEdit && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className={"fa fa-language me-2 pointer"}
                style={{
                  color: "#818090",
                  fontSize: "20px",
                }}
                onClick={showTranslateHandler}
              ></i>
              <i
                className={"fa fa-edit me-2 pointer"}
                style={{
                  color: "#818090",
                }}
                onClick={showEditHandler}
              ></i>
            </div>
          )}
        </div>
      )}
      {!showEdit && !showTranslate && (
        <p>
          {currentLanguage === "ar"
            ? props.project.description
              ? props.project.description
              : props.project.frenchDescription
            : props.project.frenchDescription
            ? props.project.frenchDescription
            : props.project.description}
        </p>
      )}
      {(showEdit || showTranslate) && (
        <div>
          <Input
            name="title"
            type="text"
            placeholder={t("Nom_du_Project")}
            onChange={handleTitleChange}
            error={titleError}
            value={title}
          />
          <Textarea
            rows={7}
            placeholder={t("Descriptif_du_projet")}
            onChange={handleDescriptionChange}
            error={descriptionError}
            value={description}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={showEdit ? t("Valider") : "Traduire"}
            firstBtnAction={showEdit ? annulate : annulateTranslate}
            secondBtnAction={showEdit ? validate : translate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectDescription;
