import { useState } from "react";
import Input from "../../../../components/Input/Input";
import {
  blockInvalidChar,
  isPositiveInteger,
} from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep25 = (props: any) => {
  const { t } = useTranslation();

  const [nbCollaboratorsMan, setNbCollaboratorsMan] = useState(
    props.project.nbCollaboratorsMan
      ? props.project.nbCollaboratorsMan
      : undefined
  );
  const [nbCollaboratorsWoman, setNbCollaboratorsWoman] = useState(
    props.project.nbCollaboratorsWoman
      ? props.project.nbCollaboratorsWoman
      : undefined
  );
  const [nbCollaboratorsManError, setNbCollaboratorsManError] = useState("");
  const [nbCollaboratorsWomanError, setNbCollaboratorsWomanError] =
    useState("");
  const [message, setMessage] = useState("");

  const handleNbCollaboratorsManChange = (event: any) => {
    setNbCollaboratorsMan(event.target.value);
  };
  const handleNbCollaboratorsWomanChange = (event: any) => {
    setNbCollaboratorsWoman(event.target.value);
  };

  const validateForm = () => {
    let invalidForm = false;
    const emptyFields =
      nbCollaboratorsMan === "" && nbCollaboratorsWoman === "";

    if (emptyFields) {
      setMessage(t("Veuillez_remplir_tous_les_champs"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }

    if (!nbCollaboratorsMan) {
      invalidForm = true;
      setNbCollaboratorsManError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbCollaboratorsMan))) {
      setNbCollaboratorsManError(t("caractére_numérique"));
    } else {
      setNbCollaboratorsManError("");
    }

    if (!nbCollaboratorsWoman) {
      invalidForm = true;
      setNbCollaboratorsWomanError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbCollaboratorsWoman))) {
      setNbCollaboratorsWomanError(t("caractére_numérique"));
    } else {
      setNbCollaboratorsWomanError("");
    }

    return invalidForm;
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    props.project.nbCollaboratorsMan = nbCollaboratorsMan;
    props.project.nbCollaboratorsWoman = nbCollaboratorsWoman;
    props.updateProject(props.project);
    props.nextHandler(7);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(5)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">
          {t("Collaborataires_et_prestataires")}
        </h5>
        <span className="mt-4 mb-4">{t("Combien")} </span>
        <div className="col-12">
          <Input
            label={t("Femme")}
            type={"number"}
            onChange={handleNbCollaboratorsWomanChange}
            error={nbCollaboratorsWomanError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbCollaboratorsWoman}
          />
        </div>
        <div className="col-12">
          <Input
            label={t("Homme")}
            type={"number"}
            onChange={handleNbCollaboratorsManChange}
            error={nbCollaboratorsManError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbCollaboratorsMan}
          />
        </div>
        <p className="text-danger text-center mt-3 mb-3">{message}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep25;
