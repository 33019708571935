import "./about.css";
import { useTranslation } from "react-i18next";
import AntAnimation from '../animation/antComponent';
import DetailsComponent from '../details/detailsComponent';
import SubscriptionBox from '../subscription//SubscriptionBox';

const AboutUs = () => {
  const { t } = useTranslation();
  const styles = {
    width: '1312px',
    height: '68px',
    padding: '50px 0px 50px 0px',
    gap: '10px',
    
  };
    return (
      <section id="aboutus_id" className="section_padding">
        <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
                <div className="section_heading">
                    <span >
                      <img src="assets\img\about\aboutiamge1.png" alt="Company" style={{ width: '1140px', height: 'auto' }} />
                    </span>
                </div>
                <div >
                  <h1>
                    <p className="blueClass "style={{ marginBottom: '16px' }}>{t("A_propos")}</p>
                  </h1>
                </div>
              <div className="sizeClass colorClass spacing">
                <p><strong style={{ color: '#0072D7'}}>{t("our_vision")}</strong> {t("p1")}</p>
                <p >{t("p2")} </p>
                <p>{t("p3")}</p>
                <p>{t("p4")} .</p>
                <p><strong>{t("p5")}:</strong><br /> {t("p6")}</p>
                <p><h4 style={{ color: '#0072D7'}}>{t("p7")}</h4></p>  
              </div>
            </div>
            </div>
        </div>
        <span >
          <img src="assets\img\about\Layer.png" alt="layer" style={{ width: '1764px', height: '80.87px' }} />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
              <div className="section_heading" >
              <span >
                <img src="assets\img\about\Rectangle 7.png" alt="line" style={{ width: '1356px', height: '4px',color:'#E3E3E3' }} />
                </span>
              </div>
              <div className="hexagon-container">
                <div className="hexagon">
                  <div className="hexagon-content">
                    <img src="assets\img\about\engagement.png" alt="Icon 1" className="hexagon-icon" />
                    <h3>{t('engagement')}</h3>
                    <p>{t('engagement_p')}</p>
                  </div>
                </div>
                <div className="hexagon">
                   <div className="hexagon-content">
                    <img src="assets\img\about\inclusion.png" alt="Icon 2" className="hexagon-icon" /> 
                    <h3>{t('inclusion_accessibilité')}</h3>
                    <p>{t('inclusion_accessibilité_p_about')}</p>
                  </div>
                </div>
                <div className="hexagon">
                  <div className="hexagon-content">
                    <img src="assets\img\about\innovation.png" alt="Icon 3" className="hexagon-icon" />
                    <h3>{t('innovation_permanente')}</h3>
                    <p>{t('innovation_permanente_p')}</p>
                  </div>
                </div>
                <div className="hexagon">
                  <div className="hexagon-content">
                  <img src="assets\img\about\Cybersecurity.png" alt="Icon 4" className="hexagon-icon" />
                  <h3>{t('protection_données')}</h3>
                  
                  <p>{t('protection_données_p')}</p>
                  </div>
                </div>
              </div>
 
              <div className="section_heading" style={styles}>
              <span >
                <img src="assets\img\about\Rectangle 7.png" alt="line" style={{ width: '1356px', height: '4px',color:'#E3E3E3' }} />
                </span>
              </div>
              </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="">
            <AntAnimation/>
            <DetailsComponent/>

            </div>
          </div>
        </div>
      
        <span>
          <img src="assets\img\about\Vector.png" alt="vector" style={{ width: '1556px', height: '131px',color:'#606060' }} />
        </span>

        <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
                <SubscriptionBox />
              </div>
            </div>       
        </div>

        
        

    </section>
      
    );
  };
  export default AboutUs;