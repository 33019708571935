const Button = (props: any) => {
  return (
    <>
      <div className={props.cssClass}>
        <button
          className="btn btn_theme"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          type={props.type ? props.type : "button"}
          onClick={props.onClick}
        >
          {props.title}
          {props.icon}
        </button>
      </div>
    </>
  );
};
export default Button;
