import { useState } from "react";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import {
  countries,
  countriesArabe,
  regions,
  regionsArabe,
} from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import i18n from "i18next";

const UserInfos = (props: any) => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const [fullName, setFullName] = useState(props.user.firstname);
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState(props.user.email);
  const [emailError, setEmailError] = useState("");
  const [governorate, setGovernorate] = useState(props.user.gouvernerat);
  const [country, setCountry] = useState("");
  const [governorateError, setGovernorateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [job, setJob] = useState(props.user.job);
  const [showEditDetails, setShowEditDetails] = useState(false);

  const handleFullNameChange = (e: any) => {
    setFullName(e.target.value);
  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleGovernorateChange = (event: any) => {
    setGovernorate(event.target.value);
  };
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };
  const handleJobChange = (event: any) => {
    setJob(event.target.value);
  };
  const validateForm = () => {
    let invalidForm = false;

    if (!fullName) {
      invalidForm = true;
      setFullNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setFullNameError("");
    }

    if (!governorate) {
      invalidForm = true;
      setGovernorateError(t("Ce_champ_est_obligatoire"));
    } else {
      setGovernorateError("");
    }

    if (governorate === "Autre" && !country) {
      invalidForm = true;
      setCountryError(t("Ce_champ_est_obligatoire"));
    } else {
      setCountryError("");
    }

    return invalidForm;
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    if (props.user) {
      const user = { id: props.id, ...props.user };
      user.firstname = fullName;
      user.job = job;
      user.gouvernerat = governorate !== "Autre" ? governorate : country;
      props.editUserHandler(user);
      setShowEditDetails(false);
    }
  };
  const annulate = () => {
    setShowEditDetails(false);
    setFullName(props.user.firstname);
    setGovernorate(props.user.gouvernerat);
    setJob(props.user.job);
  };
  return (
    <div className="mt-5">
      <div className="mb-4" style={{ display: "flex", alignItems: "center" }}>
        <h5 className="me-2">{t("Details_du_profile")}</h5>
        <i
          className={!showEditDetails ? "fa fa-edit pointer" : "fa fa-times pointer"}
          style={{
            color: "#818090",
          }}
          onClick={() =>
            !showEditDetails ? setShowEditDetails(true) : annulate()
          }
        ></i>
      </div>
      <Input
        name="fullname"
        type="text"
        placeholder={t("Nom_et_Prénom")}
        onChange={handleFullNameChange}
        error={fullNameError}
        value={fullName}
        disabled={!showEditDetails}
      />
      <Input
        name="email"
        type="email"
        placeholder={t("Email")}
        onChange={handleEmailChange}
        error={emailError}
        value={email}
        disabled={true}
      />

      <SelectInput
        selectedCategory={governorate}
        defaultValue={{
          _id: "",
          name: t("Gouvernorat"),
        }}
        selectList={currentLanguage === "fr" ? regions : regionsArabe}
        onChange={handleGovernorateChange}
        error={governorateError}
        disabled={!showEditDetails}
      />
      {governorate === "Autre" && (
        <SelectInput
          selectedCategory={country}
          defaultValue={{ _id: "", name: t("pays") }}
          selectList={currentLanguage === "fr" ? countries : countriesArabe}
          onChange={handleCountryChange}
          error={countryError}
          disabled={!showEditDetails}
        />
      )}
      <Input
        name="job"
        type="text"
        placeholder={t("Profession")}
        onChange={handleJobChange}
        value={job}
        disabled={!showEditDetails}
      />
      {showEditDetails && (
        <BottomButton
          firstBtnTitle={t("Annuler")}
          secondBtnTitle={t("Valider")}
          firstBtnAction={annulate}
          secondBtnAction={validate}
        />
      )}
    </div>
  );
};
export default UserInfos;
