import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { sendEmailForResetPassword } from "../../reducers/signin";
import { checkIsValidEmail } from "../../utils/functions";
import { FooterComponent } from "../../components/footer";
const sendEmailForResetPasswordAction = async (dispatch: any, data: any) => {
  return await dispatch(sendEmailForResetPassword(data)).unwrap();
};
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const status = useSelector(
    (state: any) => state.Signin.sendEmailForResetPassword.status
  );
  const error = useSelector(
    (state: any) => state.Signin.sendEmailForResetPassword.error
  );

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };
  const sendEmail = async () => {
    if (!email) {
      setEmailError("Ce champ est obligatoire");
      return;
    } else if (!checkIsValidEmail(email)) {
      setEmailError("L'email n'est pas valide");
      return;
    } else {
      setEmailError("");
    }
    let userData = {
      email: email,
    };
    await sendEmailForResetPasswordAction(dispatch, userData);
  };

  useEffect(() => {
    if (status === "success") {
      setMessage("Un email a été envoyé à votre adresse email");
    } else if (status === "failed" && error.message === "user not found") {
      setMessage("Ce compte n'existe pas");
    } else if (status === "failed") {
      setMessage("Une erreur est survenue");
    }
  }, [status, error]);
  return (
    <>
      <section
        className="author_form_area"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <section className="container-fluid justify-content-center">
          <h3 className="text-center">
            Pour changer votre mot de passe, veuillez entrer votre adresse email
          </h3>
          <br />
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={handleEmailChange}
            />
            <small className="text-danger mt-3 mb-3">{emailError}</small>
          </div>
          <div className="d-grid">
            <button className="btn btn_theme " onClick={sendEmail}>
              Envoyer
            </button>
          </div>
          <p className="text-center mt-3 mb-3">{message}</p>
        </section>
      </section>
      <FooterComponent />
    </>
  );
};
export default ForgotPassword;
