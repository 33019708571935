import { useTranslation } from "react-i18next";

const ProgressBar = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="class-full-bar-box">
      <div
        className="skill-bar class-bar mt-4"
        data-width={props.progress + "%"}
      >
        <div
          className="skill-bar-inner class-bar-in"
          style={{
            width: props.progress + "%",
            overflow: "hidden",
            right: t("dir") === "rtl" ? 0 : "unset",
            left: t("dir") === "ltr" ? 0 : "unset" 
          }}
        ></div>
      </div>
    </div>
  );
};
export default ProgressBar;
