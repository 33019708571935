import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectFileButton from "../../../../components/SelectFileBtn/SelectFileBtn";
import { uploadProjectFiles } from "../../../../reducers/project";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import Spinner from "../../../../components/Spinner/Spinner";
const uploadProjectFilesAction = async (formData: any, dispatch: any) => {
  return await dispatch(uploadProjectFiles(formData)).unwrap();
};
const FormStep32 = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dataAddProject = useSelector(
    (state: any) => state.Project.addProject.data
  );
  const statusUpload = useSelector(
    (state: any) => state.Project.uploadFiles.status
  );
  const [photos, setPhotos] = useState<File[]>([]);
  const [documents, setDocuments] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [photosError, setPhotosError] = useState("");
  const [documentsError, setDocumentsError] = useState("");

  const handlePhotosChange = (file: any) => {
    const files = file.target.files;
    const allFiles = [...photos, ...files];
    const list: any = [];
    allFiles.forEach((file) => {
      let exist = false;
      list.forEach((elt: any) => {
        if (file.name === elt.name) {
          exist = true;
        }
      });
      if (!exist) {
        list.push(file);
      }
    });
    setPhotos(list);
    if (files) {
      setPhotosError("");
    }
  };
  const handleVideosChange = (file: any) => {
    const files = file.target.files;
    setVideos([...files]);
  };
  const handleDocumentsChange = (file: any) => {
    const files = file.target.files;
    setDocuments([...files]);
    if (files) {
      setDocumentsError("");
    }
  };

  const validateForm = () => {
    let invalidForm = false;
    if (!photos.length) {
      invalidForm = true;
      setPhotosError(t("Il_faut_ajouter_au_moins_une_image_à_votre_project"));
    } else {
      setPhotosError("");
    }
    if (!documents.length) {
      invalidForm = true;
      setDocumentsError(t("Il_faut_ajouter_un_document_pdf_à_votre_project"));
    } else {
      setDocumentsError("");
    }
    return invalidForm;
  };
  const addFilesToProject = async (e: any) => {
    e.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const filesToAdd = [...photos, ...videos, ...documents];
    const data = { id: dataAddProject.value._id, files: filesToAdd };
    await uploadProjectFilesAction(data, dispatch);
    props.nextHandler(10);
  };
  const removePhoto = (index: number) => {
    const res = [...photos];
    res.splice(index, 1);
    setPhotos(res);
  };
  const removeDocument = (index: number) => {
    const res = [...documents];
    res.splice(index, 1);
    setDocuments(res);
  };
  const removeVideo = (index: number) => {
    const res = [...documents];
    res.splice(index, 1);
    setVideos(res);
  };
  return (
    <>
      <div className="row">
        <h3 className="text-center mb-5">{t("FinaliserPDF")}</h3>
        <h5 className="text-center mb-5">{t("photos_vidéos_documents")} </h5>
        <div>
          <div>
            <span>{t("photos")}</span>
            <SelectFileButton
              multiple
              title={t("Télécharger")}
              onChange={handlePhotosChange}
              accept={"image/*"}
              error={photosError}
            />
          </div>

          {photos.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {photos.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removePhoto(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-5">
            <span>{t("PDF")}</span>
            <SelectFileButton
              title={t("Télécharger")}
              onChange={handleDocumentsChange}
              accept={"application/pdf"}
              error={documentsError}
            />
          </div>

          {documents.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {documents.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeDocument(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-5">
            <span>{t("Video")}</span>
            <SelectFileButton
              title={t("Télécharger")}
              onChange={handleVideosChange}
              accept={"video/*"}
            />
          </div>
          {videos.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {videos.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeVideo(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className="mt-4"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button className="btn btn_theme" onClick={addFilesToProject} disabled={statusUpload === "loading"}>
            {t("Terminer")}
          </button>
        </div>
        {statusUpload === "loading" && <Spinner/>}
      </div>
    </>
  );
};
export default FormStep32;
