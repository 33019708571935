import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../components/Input/Input";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { getProjectCategories } from "../../../../reducers/projectCategory";
import { countries, countriesArabe, regions, regionsArabe } from "../../../../utils/constants";
import { wordCounter } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const getCategoriesAction = async (dispatch: any) => {
  return await dispatch(getProjectCategories({})).unwrap();
};
const FormStep1 = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [title, setTitle] = useState(
    props.project.titre ? props.project.titre : ""
  );
  const [category, setCategory] = useState(
    props.project.category ? props.project.category : ""
  );
  const [region, setRegion] = useState(
    props.project.region ? props.project.region : ""
  );
  const [description, setDescription] = useState(
    props.project.description ? props.project.description : ""
  );
  const [country, setCountry] = useState("");

  const [titleError, setTitleError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [message, setMessage] = useState("");

  const projectCategories = useSelector(
    (state: any) => state.ProjectCategory.categories.data
  );

  const getCategories = async () => {
    await getCategoriesAction(dispatch);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };
  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };
  const handleRegionChange = (event: any) => {
    setRegion(event.target.value);
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };
  const validateForm = () => {
    let invalidForm = false;
    const emptyFields =
      title === "" && category === "" && region === "" && description === "";

    if (emptyFields) {
      setMessage(t("Veuillez_remplir_tous_les_champs"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }

    if (!title) {
      invalidForm = true;
      setTitleError(t("Ce_champ_est_obligatoire"));
    } else {
      setTitleError("");
    }

    if (!category) {
      invalidForm = true;
      setCategoryError(t("Ce_champ_est_obligatoire"));
    } else {
      setCategoryError("");
    }

    if (!region) {
      invalidForm = true;
      setRegionError(t("Ce_champ_est_obligatoire"));
    } else {
      setRegionError("");
    }

    if (region === "Autre" && !country) {
      invalidForm = true;
      setCountryError(t("Ce_champ_est_obligatoire"));
    } else {
      setCountryError("");
    }

    if (!description) {
      invalidForm = true;
      setDescriptionError(t("Ce_champ_est_obligatoire"));
    } else if (!wordCounter(description, 50)) {
      invalidForm = true;
      setDescriptionError(t("Veuillez_taper_au_moins_50_mots"));
    } else {
      setDescriptionError("");
    }

    return invalidForm;
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    props.project.titre = title;
    props.project.category = category;
    props.project.region = region === "Autre" ? country : region;
    props.project.description = description;
    props.updateProject(props.project);
    props.next(1);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.showExplanationHandler(true)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h3 className="text-center mb-5">{t("Informations_generales")}</h3>
        <div className="col-lg-12">
          <Input
            name="title"
            type="text"
            placeholder={t("Nom_du_Project")}
            onChange={handleTitleChange}
            error={titleError}
            value={title}
          />
        </div>
        <div className="col-lg-12">
          <SelectInput
            selectedCategory={category}
            defaultValue={{ _id: "", name: t("category") }}
            selectList={projectCategories}
            onChange={handleCategoryChange}
            error={categoryError}
            isCategory={true}
          />
        </div>
        <div className="col-lg-12">
          <SelectInput
            selectedCategory={region}
            defaultValue={{ _id: "", name: t("region") }}
            selectList={props.currentLanguage === "fr" ? regions: regionsArabe}
            onChange={handleRegionChange}
            error={regionError}
          />
          {region === "Autre" && (
            <SelectInput
              selectedCategory={country}
              defaultValue={{ _id: "", name: t("pays") }}
              selectList={props.currentLanguage === "fr" ? countries: countriesArabe}
              onChange={handleCountryChange}
              error={countryError}
            />
          )}
        </div>

        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("Descriptif_du_projet")}
            onChange={handleDescriptionChange}
            error={descriptionError}
            value={description}
          />
        </div>
        <p className="text-danger text-center mt-3 mb-3">{message}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep1;
