import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectFileButton from "../../../../components/SelectFileBtn/SelectFileBtn";
import { uploadCampaignFiles } from "../../../../reducers/campaign";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import Spinner from "../../../../components/Spinner/Spinner";
const uploadCampaignFilesAction = async (formData: any, dispatch: any) => {
  return await dispatch(uploadCampaignFiles(formData)).unwrap();
};
const FormStep2 = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const dataAddCampaign = useSelector(
    (state: any) => state.Campaign.addCampaign.data
  );
  const statusUpload = useSelector(
    (state: any) => state.Campaign.uploadFiles.status
  );
  const [photos, setPhotos] = useState<File[]>([]);
  const [documents, setDocuments] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [photosError, setPhotosError] = useState("");
  const [documentsError, setDocumentsError] = useState("");
  const [videosError, setVideosError] = useState("");

  const handlePhotosChange = (file: any) => {
    const files = file.target.files;
    const allFiles = [...photos, ...files];
    const list: any = [];
    allFiles.forEach((file) => {
      let exist = false;
      list.forEach((elt: any) => {
        if (file.name === elt.name) {
          exist = true;
        }
      });
      if (!exist) {
        list.push(file);
      }
    });
    setPhotos(list);
    if (files) {
      setPhotosError("");
    }
  };
  const handleVideosChange = (file: any) => {
    const files = file.target.files;
    setVideos([...files]);
    if (files) {
      setVideosError("");
    }
  };
  const handleDocumentsChange = (file: any) => {
    const files = file.target.files;
    setDocuments([...files]);
    if (files) {
      setDocumentsError("");
    }
  };

  const validateForm = () => {
    let invalidForm = false;
    if (!photos.length) {
      invalidForm = true;
      setPhotosError(t('Il_faut_ajouter_au_moins_une_image_à_votre_campagne'));
    } else {
      setPhotosError("");
    }
    if (!documents.length) {
      invalidForm = true;
      setDocumentsError(t('Il_faut_ajouter_un_document_pdf_à_votre_campagne'));
    } else {
      setDocumentsError("");
    }
    if (!videos.length) {
      invalidForm = true;
      setVideosError(t('Il_faut_ajouter_une_vidéo_à_votre_campagne'));
    } else {
      setVideosError("");
    }
    return invalidForm;
  };
  const addFilesToCampaign = async (e: any) => {
    e.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const filesToAdd = [...photos, ...videos, ...documents];
    const data = { id: dataAddCampaign.value, files: filesToAdd };
    await uploadCampaignFilesAction(data, dispatch);
    props.next();
  };
  const removePhoto = (index: number) => {
    const res = [...photos];
    res.splice(index, 1);
    setPhotos(res);
  };
  const removeDocument = (index: number) => {
    const res = [...documents];
    res.splice(index, 1);
    setDocuments(res);
  };
  const removeVideo = (index: number) => {
    const res = [...documents];
    res.splice(index, 1);
    setVideos(res);
  };
  return (
    <>
      <div className="row">
        <h3 className="text-center mb-5">
{t("photos_vidéos_documentsCampagne")}        </h3>
        <div>
          <div>
            <span>{t('CampagneVideo')}</span>
            <SelectFileButton
              title={"Télécharger"}
              onChange={handleVideosChange}
              accept={"video/*"}
              error={videosError}
            />
          </div>
          {videos.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {videos.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeVideo(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-5">
            <span>{t('Campagnephotos')}</span>
            <SelectFileButton
              multiple
              title={"Télécharger"}
              onChange={handlePhotosChange}
              accept={"image/*"}
              error={photosError}
            />
          </div>

          {photos.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {photos.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removePhoto(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-5">
            <span>
              {t('CampagnePdf')}
            </span>
            <SelectFileButton
              title={"Télécharger"}
              onChange={handleDocumentsChange}
              accept={"application/pdf"}
              error={documentsError}
            />
          </div>

          {documents.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {documents.map((file: File, index) => {
                return (
                  <div className="selected-file me-2">
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeDocument(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className="mt-4"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button className="btn btn_theme" onClick={addFilesToCampaign} disabled={statusUpload === "loading"}>
            {t('Terminer')}
          </button>
        </div>
        {statusUpload === "loading" && <Spinner/>}
      </div>
    </>
  );
};
export default FormStep2;
