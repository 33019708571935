import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DonatorService from "../services/DonatorService";
import jwt_decode from "jwt-decode";

export const getDonator = createAsyncThunk(
  "donator/get",
  async (email: string, thunkAPI) => {
    try {
      const { status, data } = await DonatorService.getDonatorService(email);
      if (status === 201 || status === 200) {
        if (data.token) {
          const result: any = jwt_decode(data.token);
          return result.resp;
        } else {
          return null;
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/update",
  async (userData: any, thunkAPI) => {
    try {
      const { status, data } = await DonatorService.editUserService(userData);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateProfilePhoto = createAsyncThunk(
  "user/update-profile-photo",
  async (userData: any, thunkAPI) => {
    try {
      const { status, data } = await DonatorService.editProfilePhotoService(
        userData
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUserFile = createAsyncThunk(
  "user/update-file",
  async (userData: any, thunkAPI) => {
    try {
      const { status, data } = await DonatorService.editUserFileService(
        userData
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  getDonator: {
    status: "idle",
    data: null,
    error: {},
  },
  updateUser: {
    status: "idle",
    data: null,
    error: {},
  },
  updateProfilePhoto: {
    status: "idle",
    data: null,
    error: {},
  },
  updateUserFile: {
    status: "idle",
    data: null,
    error: {},
  },
};
const DonatorSlice = createSlice({
  name: "get-donator",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getDonator.pending.type]: (state) => {
      state.getDonator = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getDonator.fulfilled.type]: (state, action) => {
      state.getDonator = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getDonator.rejected.type]: (state, action) => {
      state.getDonator = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [updateUser.pending.type]: (state) => {
      state.updateUser = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateUser.fulfilled.type]: (state, action) => {
      state.updateUser = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateUser.rejected.type]: (state, action) => {
      state.updateUser = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [updateProfilePhoto.pending.type]: (state) => {
      state.updateProfilePhoto = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateProfilePhoto.fulfilled.type]: (state, action) => {
      state.updateProfilePhoto = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateProfilePhoto.rejected.type]: (state, action) => {
      state.updateProfilePhoto = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [updateUserFile.pending.type]: (state) => {
      state.updateUserFile = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateUserFile.fulfilled.type]: (state, action) => {
      state.updateUserFile = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateUserFile.rejected.type]: (state, action) => {
      state.updateUserFile = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
  },
});

export default DonatorSlice.reducer;
