import { useState } from "react";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Textarea from "../../../../components/Textarea/Textarea";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const Bio = (props: any) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState(
    props.user ? props.user.description : ""
  );
  const [showEditDescription, setShowEditDescription] = useState(false);

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };
  const validate = () => {
    if (props.user) {
      const user = { id: props.id, ...props.user };
      user.description = description;
      props.editUserHandler(user);
      setShowEditDescription(false);
    }
  };
  const annulate = () => {
    setShowEditDescription(false);
    setDescription(props.user ? props.user.description : "");
  };
  return (
    <div className="mt-5">
      <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
        <h5 className="me-2">{t("Details")}</h5>
        {description && !showEditDescription && (
          <i
            className="fas fa-edit me-2 pointer"
            style={{ color: "#818090" }}
            onClick={() => setShowEditDescription(true)}
          ></i>
        )}
      </div>
      {!showEditDescription && !description && (
        <a
          className="mt-2 pointer"
          href=""
          onClick={(e: any) => {
            e.preventDefault();
            setShowEditDescription(true);
          }}
        >
          {t("Ajouter_une_biographie")}{" "}
        </a>
      )}
      {!showEditDescription && description && <p>{description}</p>}
      {showEditDescription && (
        <>
          <Textarea
            rows={4}
            placeholder={t("description")}
            onChange={handleDescriptionChange}
            value={description}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </>
      )}
    </div>
  );
};
export default Bio;
