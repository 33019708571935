import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecentDonation from "../../../../components/RecentDonation/recentDonation";
import { getrecentDonationbyproject } from "../../../../reducers/recentdonation";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const getrecentdonationbyProjectAction = async (id: any, dispatch: any) => {
  return await dispatch(getrecentDonationbyproject(id)).unwrap();
};
const RecentDonations = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const donator = useSelector((state: any) =>
    state.Donation.getdonationbyproject.data
      ? state.Donation.getdonationbyproject.data.value
      : []
  );
  console.log('donator',donator)
  useEffect(() => {
    getrecentdonationbyProjectAction(props.id, dispatch);
  }, []);

  return (
    <div className="project_recentdonet_wrapper sidebar_boxed">
      <div className="sidebar_heading_main">
        <h3>{t('Donations_récentes')}</h3>
      </div>
      {donator && donator.map((item: {}) => <RecentDonation donation={item} />)}
      {donator && donator.length === 0 && (
        <a className="mt-2" href="">
          {t('pas_de_donation')}
        </a>
      )}
    </div>
  );
};
export default RecentDonations;
