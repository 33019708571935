import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import SelectFileButton from "../../../../components/SelectFileBtn/SelectFileBtn";
import config from "../../../../config/config";
import { getFileService } from "../../../../services/FileService";

const ProjectDocument = (props: any) => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [documents, setDocuments] = useState<File[]>([]);
  const [documentsError, setDocumentsError] = useState("");
  const [document, setDocument] = useState("");
  const handleDocumentsChange = (file: any) => {
    const files = file.target.files;
    setDocuments([...files]);
    if (files) {
      setDocumentsError("");
    }
  };
  const removeDocument = (index: number) => {
    const res = [...documents];
    res.splice(index, 1);
    setDocuments(res);
  };
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const hideEditHandler = () => {
    setShowEdit(false);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  const validate = async () => {
    const filesToAdd = [...documents];
    const data = { id: props.id, files: filesToAdd };
    props.uploadFilesHandler(data);
    setShowEdit(false);
  };
  const getFileHandler = async (file: any) => {
    const { status, data } = await getFileService(file);
    if (status === 201 || status === 200) {
      setDocument(
        config.apiUrl + "/project/getFile/" + file
      );
    } else {
      setDocument("");
    }
  };
  useEffect(() => {
    getFileHandler(props.file);
  }, [props.file]);
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{props.title}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
        {showEdit && props.canEdit && (
          <i
            className={"fa fa-times-circle pointer"}
            style={{
              color: "#818090",
            }}
            onClick={hideEditHandler}
          ></i>
        )}
      </div>
      {document && !showEdit && (
        <div className="row popup-gallery mt-3">
          <div className="col-12">
            <div className="pdf_download_left">
              <img src="../../assets/img/icon/pdf.png" alt="icon" className="me-2"/>
              <a
                href={
                  props.file
                    ? config.apiUrl + "/project/getFile/" + props.file
                    : ""
                }
                target="_blank"
                className="me-2"
              >
                {t("Document")}
              </a>
            </div>
          </div>
        </div>
      )}
      {!document && !showEdit && (
        <div className="row popup-gallery mt-3">
          <h5>{t("no_document")}</h5>
        </div>
      )}
      {showEdit && (
        <div className="mt-3">
          <SelectFileButton
            title={t("Télécharger")}
            onChange={handleDocumentsChange}
            accept={"application/pdf"}
            error={documentsError}
          />
          {documents.length > 0 && (
            <div className="mt-4 mb-4 flex">
              {documents.map((file: File, index) => {
                return (
                  <div className="selected-file me-2" key={index}>
                    <span className="me-2" key={index}>
                      {file.name}
                    </span>
                    <i
                      className="fa fa-times-circle pointer"
                      aria-hidden="true"
                      onClick={() => removeDocument(index)}
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectDocument;
