import "./SelectInput.css";
import { useState } from "react";
import i18n from "i18next";

const SelectInput = (props: any) => {
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  return (
    <div className="mb-3">
      <div className="input-group" style={{ alignItems: "center" }}>
        {props.label && <label className="me-2">{props.label}</label>}
        <select
          value={props.selectedCategory}
          className={
            currentLanguage === "ar"
              ? "form-control arabic"
              : "form-control french"
          }
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {props.defaultValue && (
            <option key={props.defaultValue._id} value={props.defaultValue._id}>
              {props.defaultValue.name}
            </option>
          )}
          {props.selectList &&
            props.selectList.map((item: any) => (
              <option
                key={item._id}
                value={props.value === "name" ? item.name : item._id}
              >
                {props.isCategory && currentLanguage === "ar"
                ? item.arabicName
                : item.name}
              </option>
            ))}
        </select>
        <i className="fa fa-chevron-down"></i>
      </div>
      {props.error && (
        <small className="text-danger mt-3 mb-3">{props.error}</small>
      )}
    </div>
  );
};
export default SelectInput;
