import moment from "moment";
import { useTranslation } from "react-i18next";
import config from "../../config/config";

const RecentDonation = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="recent_donet_item">
      <div className="recent_donet_img">
        <img
          src={
            props.donation.profilePhoto
              ? config.apiUrl +
                "/donator/getFile/" +
                props.donation.profilePhoto
              : "../../assets/img/avatar.png"
          }
          alt="img"
          style={{ maxWidth: "80%!important", border: "1px solid #8080804d" }}
        />
      </div>
      <div className="recent_donet_text">
        <div className="sidebar_inner_heading">
          <h4>
            <a>{props.donation.name}</a>
          </h4>
          <h5>{props.donation.amount} {t("tnd")}</h5>
        </div>
        <h6> {moment(props.donation.createdAt).format("DD-MM-YYYY")} </h6>
      </div>
    </div>
  );
};
export default RecentDonation;
