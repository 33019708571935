import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectCampaign = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createCampaign = (projectId: any) => {
    navigate("/campaign-create", { state: { projectId } });
  };
  const campaign = async () => {
    navigate(`/campaign:${props.projectData.value.comps[0].id}`);
  };
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div className="sidebar_heading_main">
        <h3>{t("Campagne")}</h3>
      </div>
      <div className="social_icon_sidebar mt-3">
        {props.status == "success" && props.projectData.value.comps[0] && (
          <a
            onClick={() => {
              campaign();
            }}
            className="btn btn_md btn_theme pointer"
          >
            {t("Détails_de_la_campagne")}
          </a>
        )}
        {props.projectData.value.porteur._id === props.currentUserId &&
          props.status == "success" &&
          !props.projectData.value.comps[0] && (
            <a
              onClick={() => createCampaign(props.id)}
              className="btn btn_md btn_theme pointer"
            >
              {t("Créer_une_campagne")}
            </a>
          )}
      </div>
    </div>
  );
};
export default ProjectCampaign;
