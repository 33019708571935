import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { resetPassword } from "../../reducers/signin";
import { checkIsValidPassword } from "../../utils/functions";
import Input from "../../components/Input/Input";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { FooterComponent } from "../../components/footer";

const resetPasswordAction = async (dispatch: any, data: any) => {
  return await dispatch(resetPassword(data)).unwrap();
};
const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const status = useSelector((state: any) => state.Signin.resetPassword.status);
  const error = useSelector((state: any) => state.Signin.resetPassword.error);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hiddenRePassword, setHiddenRePassword] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");
  const [message, setMessage] = useState("");

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };
  const handleRePasswordChange = (event: any) => {
    setRePassword(event.target.value);
  };
  const resetPassword = async () => {
    if (!password) {
      setPasswordError("Ce champ est obligatoire");
      return;
    } else if (!checkIsValidPassword(password)) {
      setPasswordError(
        "Votre mot de passe doit comporter au moins 6 caractères et doit inclure une combinaison de chiffres, de lettres et de caractères spéciaux (!@%)"
      );
      return;
    } else {
      setPasswordError("");
    }

    if (password !== rePassword) {
      setRePasswordError("Votre mot de passe ne correspond pas");
      return;
    } else {
      setRePasswordError("");
    }
    if (token) {
      const result: any = jwt_decode(token);
      let userData = {
        id: result.id,
        newpassword: password,
        confirmnewpassword: rePassword,
      };
      await resetPasswordAction(dispatch, userData);
    }
  };

  useEffect(() => {
    if (status === "success") {
      setMessage("Modification de mot de passe a été effectué avec succès");
    } else if (
      status === "failed" &&
      error === "password is the same thanks to put a new password"
    ) {
      setMessage("Vous avez utilisé le même mot de passe");
    } else if (status === "failed") {
      setMessage("Une erreur est survenue");
    }
  }, [status]);

  return (
    <>
      <section
        className="author_form_area"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <section className="container-fluid justify-content-center">
          <h3 className="text-center">
            Veuillez entrer un nouveau mot de passe
          </h3>
          <br />
          <Input
            name="password"
            type={hiddenPassword ? "password" : "text"}
            placeholder="Mot de passe"
            onChange={handlePasswordChange}
            error={passwordError}
            icon={
              hiddenPassword ? (
                <i className="fa fa-eye-slash" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye" aria-hidden="true"></i>
              )
            }
            onClickIcon={() => setHiddenPassword((current) => !current)}
          />
          <Input
            name="repassword"
            type={hiddenRePassword ? "password" : "text"}
            placeholder="Confirmer mot de passe"
            onChange={handleRePasswordChange}
            error={rePasswordError}
            icon={
              hiddenRePassword ? (
                <i className="fa fa-eye-slash" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye" aria-hidden="true"></i>
              )
            }
            onClickIcon={() => setHiddenRePassword((current) => !current)}
          />
          <div className="d-grid">
            <button className="btn btn_theme " onClick={resetPassword}>
              Confirmer
            </button>
          </div>
          <p className="text-center mt-3 mb-3">{message}</p>
        </section>
      </section>
      <FooterComponent />
    </>
  );
};
export default ResetPassword;
