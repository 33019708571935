import { configureStore } from "@reduxjs/toolkit";
import donator from "../reducers/donator";
import donation from "../reducers/recentdonation";
import adddonation from "../reducers/donation";
import recentdonationCampaign from "../reducers/recentdonationCampaign";

import project from "../reducers/project";
import signup from "../reducers/signup";
import signin from "../reducers/signin";
import wallet from "../reducers/wallet";
import projectCategory from "../reducers/projectCategory";
import projectDetails from "../reducers/projectDetails";
import campaign from "../reducers/campaign";
import acceuil from "../reducers/acceuil";

import file from "../reducers/file";
import investorAgent from "../reducers/investorAgent";
import quadraticCampaign from "../reducers/quadraticCampaign";

export default configureStore({
  reducer: {
    Acceuil: acceuil,
    Signup: signup,
    Signin: signin,
    Donator: donator,
    AddDonation:adddonation,
    Donation: donation,
    RecentdonationCampaign:recentdonationCampaign,
    Project: project,
    wallet:wallet,
    projectDetails:projectDetails,
    ProjectCategory: projectCategory,
    Campaign: campaign,
    File:file,
    InvestorAgent: investorAgent,
    QuadraticCampaign: quadraticCampaign
    
  },
});
