const config = {
  //apiUrl: env.http + env.host + ":" + env.port,
  //local url
 // apiUrl: "http://172.18.176.1:8080/api/v2",
  
  //prod utrl
  apiUrl: "https://backend.antlike.org.tn/api/v2", 

};

export default config