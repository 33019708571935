import { useState } from "react";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { wordCounter } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep24 = (props: any) => {
  const { t } = useTranslation();

  const [impactOnEnviroment, setImpactOnEnviroment] = useState(
    props.project.impactOnEnviroment ? props.project.impactOnEnviroment : "good_impact"
  );
  const [impactOnEnviromentDetails, setImpactOnEnviromentDetails] = useState(
    props.project.impactOnEnviromentDetails
      ? props.project.impactOnEnviromentDetails
      : ""
  );
  const [
    impactOnEnviromentDetailsDetailsError,
    setImpactOnEnviromentDetailsError,
  ] = useState("");

  const handleImpactOnEnviromentChange = (event: any) => {
    console.log("aaaaa", event.target.value)
    setImpactOnEnviroment(event.target.value);
  };
  const handleImpactOnEnviromentDetailsChange = (event: any) => {
    setImpactOnEnviromentDetails(event.target.value);
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();
    if (!wordCounter(impactOnEnviromentDetails, 10)) {
      setImpactOnEnviromentDetailsError(t("Veuillez_taper_au_moins_10_mots"));
      return;
    } else {
      setImpactOnEnviromentDetailsError("");
    }
    props.project.impactOnEnviroment = impactOnEnviroment;
    props.project.impactOnEnviromentDetails = impactOnEnviromentDetails;
    props.updateProject(props.project);
    props.nextHandler(6);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(4)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">{t("Impact")}</h5>
        <span className="mt-4 mb-4">{t("project_impact")} </span>
        <div className="col-12">
          <SelectInput
            selectedCategory={impactOnEnviroment}
            selectList={[
              {
                _id: "good_impact",
                name: t("good_impact"),
              },
              { _id: "bad_impact", name: t("bad_impact") },
              { _id: "no_impact", name: t("no_impact") },
            ]}
            onChange={handleImpactOnEnviromentChange}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("précisions")}
            onChange={handleImpactOnEnviromentDetailsChange}
            error={impactOnEnviromentDetailsDetailsError}
            value={impactOnEnviromentDetails}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep24;
