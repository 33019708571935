import { useState } from "react";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Textarea from "../../../../components/Textarea/Textarea";
import { wordCounter } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep26 = (props: any) => {
  const { t } = useTranslation();

  const [addedValueToCommunity, setAddedValueToCommunity] = useState(
    props.project.addedValueToCommunity
      ? props.project.addedValueToCommunity
      : "no_value"
  );
  const [addedValueToCommunityDetails, setAddedValueToCommunityDetails] =
    useState(
      props.project.addedValueToCommunityDetails
        ? props.project.addedValueToCommunityDetails
        : ""
    );
  const [
    addedValueToCommunityDetailsError,
    setAddedValueToCommunityDetailsError,
  ] = useState("");
  const handleAddedValueToCommunityChange = (event: any) => {
    setAddedValueToCommunity(event.target.value);
  };
  const handleAddedValueToCommunityDetailsChange = (event: any) => {
    setAddedValueToCommunityDetails(event.target.value);
  };
  const nextStepHandler = (e: any) => {
    e.preventDefault();
    if (!wordCounter(addedValueToCommunityDetails, 10)) {
      setAddedValueToCommunityDetailsError(
        t("Veuillez_taper_au_moins_10_mots")
      );
      return;
    } else {
      setAddedValueToCommunityDetailsError("");
    }
    props.project.addedValueToCommunity = addedValueToCommunity;
    props.project.addedValueToCommunityDetails = addedValueToCommunityDetails;
    props.updateProject(props.project);
    props.nextHandler(8);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(6)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">{t("Valeur_Ajoutée")}</h5>
        <span className="mt-4 mb-4">{t("bénéficie")}</span>
        <div className="col-12">
          <SelectInput
            selectedCategory={addedValueToCommunity}
            selectList={[
              {
                _id: "no_value",
                name: t("no_value"),
              },
              { _id: "little_value", name: t("little_value") },
              { _id: "moyennement", name: t("moyennement") },
              { _id: "majority", name: t("majority") },
            ]}
            onChange={handleAddedValueToCommunityChange}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <Textarea
            rows={4}
            placeholder={t("précisions")}
            onChange={handleAddedValueToCommunityDetailsChange}
            error={addedValueToCommunityDetailsError}
            value={addedValueToCommunityDetails}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep26;
