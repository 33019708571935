import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import { getProjectCategories } from "../../../../reducers/projectCategory";
import {
  countries,
  countriesArabe,
  regions,
  regions2Arabe,
  regionsArabe,
} from "../../../../utils/constants";
import _ from "lodash";

const getCategoriesAction = async (dispatch: any) => {
  return await dispatch(getProjectCategories({})).unwrap();
};
const ProjectInfos = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [category, setCategory] = useState(
    props.project.category ? props.project.category : ""
  );
  const [region, setRegion] = useState(
    props.project.region ? props.project.region : ""
  );
  const [country, setCountry] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [addCofounder, setAddCoFounder] = useState(
    props.project.addCofounder !== undefined
      ? props.project.addCofounder
        ? "true"
        : "false"
      : "true"
  );
  const [coFounder, setCoFounder] = useState(
    props.project.coFounder ? props.project.coFounder : ""
  );
  const [coFounderError, setCoFounderError] = useState("");
  const [projectRegion, setProjectRegion] = useState("");
  const projectCategories = useSelector(
    (state: any) => state.ProjectCategory.categories.data
  );
  const handleAddCoFounderChange = (event: any) => {
    setAddCoFounder(event.target.value);
    if (event.target.value === "false") {
      setCoFounderError("");
    }
  };
  const handleCoFounderChange = (event: any) => {
    setCoFounder(event.target.value);
  };
  const getCategories = async () => {
    await getCategoriesAction(dispatch);
  };

  useEffect(() => {
    getCategories();
  }, []);
  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };
  const handleRegionChange = (event: any) => {
    setRegion(event.target.value);
  };
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const validateForm = () => {
    let invalidForm = false;
    if (addCofounder === "true" && !coFounder) {
      setCoFounderError(t("Ce_champ_est_obligatoire"));
      invalidForm = true;
    } else {
      setCoFounderError("");
    }
    return invalidForm;
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const project = { ...props.project };
    project.addCofounder = addCofounder === "true" ? true : false;
    project.coFounder = addCofounder === "true" ? coFounder : "";
    project.region = region ? region : country;
    project.category = category;
    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  useEffect(() => {
    if (props.currentLanguage === "ar") {
      const allRegions = regions2Arabe.concat(countriesArabe);
      const res = _.find(allRegions, { _id: props.project.region });
      if (res) {
        setProjectRegion(res.name);
      }
    } else {
      setProjectRegion(props.project.region);
    }
  }, [props.project]);
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{t("Autres")}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
      </div>
      {!showEdit && (
        <div className="social_icon_sidebar">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <p>
                {t("Financé_à")} : {props.project.currentAmount}
              </p>
            </li>

            <li className="list-group-item">
              <p>
                {t("Contributeurs")} : {props.donator.length}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("campaign_number")} : {props.project.comps.length}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("co_founder")} :{" "}
                {props.project.coFounder ? props.project.coFounder : t("none")}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("region")} : {projectRegion}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("category")} :{" "}
                {props.currentLanguage === "fr"
                  ? props.project.category.name
                  : props.project.category.arabicName}
              </p>
            </li>
          </ul>
        </div>
      )}
      {showEdit && (
        <div className="social_icon_sidebar">
          <SelectInput
            selectedCategory={addCofounder}
            selectList={[
              { _id: true, name: t("yes") },
              { _id: false, name: t("no") },
            ]}
            onChange={handleAddCoFounderChange}
          />
          <Input
            name="cofondateur"
            type="text"
            placeholder={t("Co_fondateur")}
            onChange={handleCoFounderChange}
            error={coFounderError}
            disabled={addCofounder === "false"}
            value={coFounder}
          />
          <SelectInput
            selectedCategory={category}
            defaultValue={{
              _id: category._id,
              name: category
                ? props.currentLanguage === "fr"
                  ? category.name
                  : category.arabicName
                : t("category"),
            }}
            selectList={projectCategories}
            onChange={handleCategoryChange}
            error={categoryError}
            isCategory={true}
          />
          <SelectInput
            selectedCategory={region}
            defaultValue={{ _id: "", name: t("region") }}
            selectList={props.currentLanguage === "fr" ? regions : regionsArabe}
            onChange={handleRegionChange}
            error={regionError}
          />
          {region === "Autre" && (
            <SelectInput
              selectedCategory={country}
              defaultValue={{ _id: "", name: t("pays") }}
              selectList={
                props.currentLanguage === "fr" ? countries : countriesArabe
              }
              onChange={handleCountryChange}
              error={countryError}
            />
          )}
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectInfos;
