import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WalletService from "../services/WalletService";

export const getTranscationsByUser = createAsyncThunk(
  "wallet/get-transcations-by-user",
  async (token: any, thunkAPI) => {
    try {
      const { status, data } = await WalletService.getTransactionsByUserService(
        token
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  transcations: {
    status: "idle",
    data: null,
    error: {},
  },
};
const WalletSlice = createSlice({
  name: "get-donator",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getTranscationsByUser.pending.type]: (state) => {
      state.transcations = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getTranscationsByUser.fulfilled.type]: (state, action) => {
      state.transcations = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getTranscationsByUser.rejected.type]: (state, action) => {
      state.transcations = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
  },
});

export default WalletSlice.reducer;
