import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import i18n from "i18next";
import { useState } from "react";
const Historique = (props: any) => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());

  return (
    <tr >
      <td>
        {currentLanguage === "fr"
          ? props.Historique.CompTitleFrench
            ? props.Historique.CompTitleFrench
            : props.Historique.CompTitle
          : props.Historique.CompTitle
          ? props.Historique.CompTitle
          : props.Historique.CompTitleFrench}{" "}
      </td>
      <td>{t("Donate")}</td>
      <td>{moment(props.Historique.createdAt).format("DD-MM-YYYY")}</td>
      <td>{props.Historique.amount} {t("tnd")}</td>
    </tr>
  );
};
export default Historique;
