import { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import {
  blockInvalidChar,
  isPositiveInteger,
} from "../../../../utils/functions";

const ProjectEmployees = (props: any) => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [nbEmpMan, setNbEmpMan] = useState(
    props.project.nbEmpMan ? props.project.nbEmpMan : undefined
  );
  const [nbEmpWoman, setNbEmpWoman] = useState(
    props.project.nbEmpWoman ? props.project.nbEmpWoman : undefined
  );
  const [nbEmpAge1, setNbEmpAge1] = useState(
    props.project.nbEmpAge1 ? props.project.nbEmpAge1 : undefined
  );
  const [nbEmpAge2, setNbEmpAge2] = useState(
    props.project.nbEmpAge2 ? props.project.nbEmpAge2 : undefined
  );
  const [nbEmpAge3, setNbEmpAge3] = useState(
    props.project.nbEmpAge3 ? props.project.nbEmpAge3 : undefined
  );
  const [nbEmpAge4, setNbEmpAge4] = useState(
    props.project.nbEmpAge4 ? props.project.nbEmpAge4 : undefined
  );
  const [nbEmpManError, setNbEmpManError] = useState("");
  const [nbEmpWomanError, setNbEmpWomanError] = useState("");
  const [nbEmpAge1Error, setNbEmpAge1Error] = useState("");
  const [nbEmpAge2Error, setNbEmpAge2Error] = useState("");
  const [nbEmpAge3Error, setNbEmpAge3Error] = useState("");
  const [nbEmpAge4Error, setNbEmpAge4Error] = useState("");
  const validateForm = () => {
    let invalidForm = false;
    if (!nbEmpMan) {
      invalidForm = true;
      setNbEmpManError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpMan))) {
      setNbEmpManError(t("caractére_numérique"));
    } else {
      setNbEmpManError("");
    }
    if (!nbEmpWoman) {
      invalidForm = true;
      setNbEmpWomanError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpWoman))) {
      setNbEmpWomanError(t("caractére_numérique"));
    } else {
      setNbEmpWomanError("");
    }
    if (!nbEmpAge1) {
      invalidForm = true;
      setNbEmpAge1Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge1))) {
      setNbEmpAge1Error(t("caractére_numérique"));
    } else {
      setNbEmpAge1Error("");
    }
    if (!nbEmpAge2) {
      invalidForm = true;
      setNbEmpAge2Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge2))) {
      setNbEmpAge2Error(t("caractére_numérique"));
    } else {
      setNbEmpAge2Error("");
    }
    if (!nbEmpAge3) {
      invalidForm = true;
      setNbEmpAge3Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge3))) {
      setNbEmpAge3Error(t("caractére_numérique"));
    } else {
      setNbEmpAge3Error("");
    }
    if (!nbEmpAge4) {
      invalidForm = true;
      setNbEmpAge4Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge4))) {
      setNbEmpAge4Error(t("caractére_numérique"));
    } else {
      setNbEmpAge4Error("");
    }
    return invalidForm;
  };
  const handleNbEmpManChange = (event: any) => {
    setNbEmpMan(event.target.value);
  };
  const handleNbEmpWomanChange = (event: any) => {
    setNbEmpWoman(event.target.value);
  };
  const handleNbEmpAge1Change = (event: any) => {
    setNbEmpAge1(event.target.value);
  };
  const handleNbEmpAge2Change = (event: any) => {
    setNbEmpAge2(event.target.value);
  };
  const handleNbEmpAge3Change = (event: any) => {
    setNbEmpAge3(event.target.value);
  };
  const handleNbEmpAge4Change = (event: any) => {
    setNbEmpAge4(event.target.value);
  };
  const showEditHandler = () => {
    setShowEdit(true);
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const project = { ...props.project };
    project.nbEmpMan = nbEmpMan;
    project.nbEmpWoman = nbEmpWoman;
    project.nbEmpAge1 = nbEmpAge1;
    project.nbEmpAge2 = nbEmpAge2;
    project.nbEmpAge3 = nbEmpAge3;
    project.nbEmpAge4 = nbEmpAge4;

    props.updateProjectHandler(project);
    setShowEdit(false);
  };
  const annulate = () => {
    setShowEdit(false);
  };
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{t("employees")}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit pointer"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
      </div>
      {!showEdit && (
        <div className="social_icon_sidebar">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <p>
                {t("Nombre_des_hommes")} : {props.project.nbEmpMan}
              </p>
            </li>

            <li className="list-group-item">
              <p>
                {t("Nombre_des_femmes")} : {props.project.nbEmpWoman}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("Entre_18_30")} {props.project.nbEmpAge1}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("Entre_30_45")} {props.project.nbEmpAge2}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("Entre_46_55")} {props.project.nbEmpAge3}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("Entre_55")} {props.project.nbEmpAge4}
              </p>
            </li>
          </ul>
        </div>
      )}
      {showEdit && (
        <div className="social_icon_sidebar">
          <Input
            label={t("Homme")}
            type={"number"}
            onChange={handleNbEmpManChange}
            error={nbEmpManError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpMan}
          />{" "}
          <Input
            label={t("Femme")}
            type={"number"}
            onChange={handleNbEmpWomanChange}
            error={nbEmpWomanError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpWoman}
          />{" "}
          <Input
            label={t("Entre_18_30")}
            type={"number"}
            onChange={handleNbEmpAge1Change}
            error={nbEmpAge1Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge1}
          />{" "}
          <Input
            label={t("Entre_30_45")}
            type={"number"}
            onChange={handleNbEmpAge2Change}
            error={nbEmpAge2Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge2}
          />{" "}
          <Input
            label={t("Entre_46_55")}
            type={"number"}
            onChange={handleNbEmpAge3Change}
            error={nbEmpAge3Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge3}
          />{" "}
          <Input
            label={t("Entre_55")}
            type={"number"}
            onChange={handleNbEmpAge4Change}
            error={nbEmpAge4Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge4}
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default ProjectEmployees;
