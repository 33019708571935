import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./CampaignObjectif.css";
const CampaignObjectif = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dateValidationMessage, setDateValidationMessage] = useState('');
  const [goal, setGoal] = useState(
    props.CampaignF.maxCap
      ? Math.floor((props.CampaignF.cureentCap / props.CampaignF.maxCap) * 100)
      : 0
  );
  const goTo = async () => {
    navigate(`/donation/${props.id}`);
  };
  const dateValidationOfCamp = async (startDate: any, delay: any) => {
    const today = new Date();
    const startDateCamp = new Date(startDate);
    const campDelay = new Date(delay);
    // Compare start date with today
    if (startDateCamp > today && campDelay > today) {
      return 'à venir';
    } else if (startDateCamp < today && campDelay > today) {
      return 'En cours';
    } else {
      return 'Expiré';
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const result = await dateValidationOfCamp(
        props.CampaignF.startDate,
        props.CampaignF.delay
      );
      setDateValidationMessage(result);
    };

    fetchData();
  }, [props.CampaignF.startDate, props.CampaignF.delay]);
  const calculateDaysAgo = (endDate: string) => {
    const end = new Date(endDate);
    const today = new Date();
    const difference = today.getTime() - end.getTime();
    return Math.floor(difference / (1000 * 3600 * 24));
  };

  const calculateDaysComing = (startDate: string) => {
    const start = new Date(startDate);
    const today = new Date();
    const difference = start.getTime()- today.getTime() ;
    return Math.floor(difference / (1000 * 3600 * 24));
  };

  return (
    <>
      {props.CampaignF && (
        <div
          className="share_causes_wrapper sidebar_boxed"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className="class-full-bar-box">
            <h3 className="h3-title">
              {t('objectif')} :
              <span>
                {' '}
                {props.CampaignF.maxCap ? props.CampaignF.maxCap : 0} {t('tnd')}
              </span>
            </h3>
            <div
              className="class-full-bar-percent"
              style={{
                right: t('dir') === 'ltr' ? 0 : 'unset',
                left: t('dir') === 'rtl' ? 0 : 'unset',
              }}
            >
              <h2>
                <span className="counting-data"></span>
                <span> {goal} %</span>
              </h2>
            </div>
            <div className="skill-bar class-bar" data-width={goal + '%'}>
              <div
                className="skill-bar-inner class-bar-in"
                style={{
                  width: `${Math.min(goal, 100)}%`,
                  overflow: 'hidden',
                  right: t('dir') === 'rtl' ? 0 : 'unset',
                  left: t('dir') === 'ltr' ? 0 : 'unset',
                }}
              ></div>
            </div>
          </div>

          { (
            <div className="details_top_btn mt-3">
              <button
                onClick={() => {
                  goTo();
                }}
                className={`btn btn_md btn_theme pointer ${
                  dateValidationMessage === 'à venir' ||
                  dateValidationMessage === 'Expiré'
                    ? 'disabled'
                    : ''
                }`}
                disabled={
                  dateValidationMessage === 'à venir' ||
                  dateValidationMessage === 'Expiré'
                }
                style={{ width: '100%' }}
              >
                {t('Faire_un_don')}{' '}
              </button>
              {dateValidationMessage === 'Expiré'   && (
                <div className="expired-message">
                  {t('campagne_exipred' )}{' '}
                  {calculateDaysAgo(props.CampaignF.delay)}  {t('Jours')}
                </div>
              )}
              {dateValidationMessage === 'à venir' && ( <div className="expired-message">
                 {t('campagne_in_coming' )}
                 {calculateDaysComing(props.CampaignF.startDate)}  {t('Jours')}
                 </div>
                 )}

            </div>
          )}
        </div>
      )}
    </>
  );
};
export default CampaignObjectif;
