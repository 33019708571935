import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CampaignService from "../services/CampaignService";

export const addCampaign = createAsyncThunk(
  "campaign/add",
  async (campaignData: any, thunkAPI) => {
    try {
      const { status, data } = await CampaignService.addCampaignService(
        campaignData
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getbyidProject = createAsyncThunk(
  "campaign/getbyidProject",
  async (token: any, thunkAPI) => {
    try {
      const { status, data } = await CampaignService.getCampaignsByidProject(token);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCampaign = createAsyncThunk(
  "campaign/get",
  async (token: any, thunkAPI) => {
    try {
      const { status, data } = await CampaignService.GettCampaignService(token);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCampaignsByRegionAndCategory = createAsyncThunk(
  "campaign/get-by-region-and-category",
  async (dataFilter: any, thunkAPI) => {
    try {
      const { status, data } =
        await CampaignService.getCampaignsByRegionAndCategory(dataFilter);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadCampaignFiles = createAsyncThunk(
  "campaign/ulpoad-files",
  async (uploadData: any, thunkAPI) => {
    try {
      const { status, data } = await CampaignService.uploadCampaignFiles(
        uploadData.id,
        uploadData.files
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateCampaign = createAsyncThunk(
  "campaign/update",
  async (campaignData: any, thunkAPI) => {
    try {
      const { status, data } = await CampaignService.updateProjectService(
        campaignData
      );
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  addCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
  uploadFiles: {
    status: "idle",
    data: null,
    error: {},
  },
  getCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
  getCampaignproject: {
    status: "idle",
    data: null,
    error: {},
  },
  campaignsByRegionAndCategory: {
    status: "idle",
    data: null,
    error: {},
  },
  updateCampaign: {
    status: "idle",
    data: null,
    error: {},
  },
};
const CampaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addCampaign.pending.type]: (state) => {
      state.addCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [addCampaign.fulfilled.type]: (state, action) => {
      state.addCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addCampaign.rejected.type]: (state, action) => {
      state.addCampaign = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [uploadCampaignFiles.pending.type]: (state) => {
      state.uploadFiles = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [uploadCampaignFiles.fulfilled.type]: (state, action) => {
      state.uploadFiles = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [uploadCampaignFiles.rejected.type]: (state, action) => {
      state.uploadFiles = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getCampaign.pending.type]: (state) => {
      state.getCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getCampaign.fulfilled.type]: (state, action) => {
      state.getCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCampaign.rejected.type]: (state, action) => {
      state.getCampaign = {
        status: "failed",
        data: action.payload,
        error: action.payload.error,
      };
    },
    [getCampaignsByRegionAndCategory.pending.type]: (state) => {
      state.campaignsByRegionAndCategory = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getCampaignsByRegionAndCategory.fulfilled.type]: (state, action) => {
      state.campaignsByRegionAndCategory = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCampaignsByRegionAndCategory.rejected.type]: (state, action) => {
      state.campaignsByRegionAndCategory = {
        status: "failed",
        data: action.payload,
        error: {},
      };
    },
    [updateCampaign.pending.type]: (state) => {
      state.updateCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateCampaign.fulfilled.type]: (state, action) => {
      state.updateCampaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateCampaign.rejected.type]: (state, action) => {
      state.updateCampaign = {
        status: "failed",
        data: action.payload,
        error: action.payload,
      };
    },
    [getbyidProject.pending.type]: (state) => {
      state.updateCampaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getbyidProject.fulfilled.type]: (state, action) => {
      state.getCampaignproject = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getbyidProject.rejected.type]: (state, action) => {
      state.getCampaignproject = {
        status: "failed",
        data: action.payload,
        error: action.payload,
      };
    },
  },
});

export default CampaignSlice.reducer;
