import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";

const TopButtons = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-6">
        <div className="d-grid">
          <button className={!props.showEntrepriseSignUpForm ? "btn btn_theme ": "btn btn_navber_custom"} onClick={() => props.onClick(false)}>{t('Donateur')}</button>
        </div>
      </div>
      <div className="col-6">
        <div className="d-grid">
          <button className={props.showEntrepriseSignUpForm ? "btn btn_theme ": "btn btn_navber_custom"} onClick={() => props.onClick(true)}>{t('Entreprise')}</button>
        </div>
      </div>
    </div>
  );
};
export default TopButtons;
