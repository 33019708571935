import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import { getProjectCategories } from "../../../../reducers/projectCategory";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const getCategoriesAction = async (dispatch: any) => {
  return await dispatch(getProjectCategories({})).unwrap();
};
const UserInfos = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [category, setCategory] = useState(
    props.user.value.investors.secteur ? props.user.value.investors.secteur : ""
  );
  const [fullName, setFullName] = useState(props.user.value.name);
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState(props.user.value.email);
  const [emailError, setEmailError] = useState("");
  const [job, setJob] = useState(props.user.value.roleAgent);
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [entrepriseName, setEntrepriseName] = useState(
    props.user.value.investors.name
  );
  const [entrepriseAddress, setEntrepriseAddress] = useState(
    props.user.value.investors.address ? props.user.value.investors.address : ""
  );
  const [entrepriseNameError, setEntrepriseNameError] = useState("");
  const [entrepriseAddressError, setEntrepriseAddressError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [jobError, setJobError] = useState("");

  const projectCategories = useSelector(
    (state: any) => state.ProjectCategory.categories.data
  );

  const getCategories = async () => {
    await getCategoriesAction(dispatch);
  };

  useEffect(() => {
    getCategories();
  }, []);
  const handleFullNameChange = (e: any) => {
    setFullName(e.target.value);
  };
  const handleEntrepriseNameChange = (e: any) => {
    setEntrepriseName(e.target.value);
  };
  const handleEntrepriseAddressChange = (e: any) => {
    setEntrepriseAddress(e.target.value);
  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleJobChange = (event: any) => {
    setJob(event.target.value);
  };
  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };
  const validateForm = () => {
    let invalidForm = false;

    if (!fullName) {
      invalidForm = true;
      setFullNameError(t("Ce_champ_est_obligatoire"));
    } else {
      setFullNameError("");
    }

    if (!job) {
      invalidForm = true;
      setJobError(t("Ce_champ_est_obligatoire"));
    } else {
      setJobError("");
    }

    if (!email) {
      invalidForm = true;
      setEmailError(t("Ce_champ_est_obligatoire"));
    } else {
      setEmailError("");
    }

    return invalidForm;
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const data = {
      id: props.id,
      name: fullName,
      roleAgent: job,
      email: email,
      address: entrepriseAddress,
      secteur: category,
      type: props.user.value.type,
      description: props.user.value.investors.description
        ? props.user.value.investors.description
        : "",
      linkedin: props.user.value.investors.linkedin
        ? props.user.value.investors.linkedin
        : "",
      facebook: props.user.value.investors.facebook
        ? props.user.value.investors.facebook
        : "",
      twitter: props.user.value.investors.twitter
        ? props.user.value.investors.twitter
        : "",
    };
    props.editUserHandler(data);
    setShowEditDetails(false);
  };
  const annulate = () => {
    setShowEditDetails(false);
    setFullName(props.user.value.name);
    setJob(props.user.value.job);
    setEmail(props.user.value.email);
    setEntrepriseAddress(props.user.value.investors.address);
    setCategory(props.user.value.investors.secteur);
  };
  return (
    <div className="mt-5" dir={t("dir")}>
      <div className="mb-4" style={{ display: "flex", alignItems: "center" }}>
        <h5 className="me-2">{t("Details_du_profile")}</h5>
        <i
          className={!showEditDetails ? "fa fa-edit me-2 pointer" : "fa fa-times me-2 pointer"}
          style={{
            color: "#818090",
          }}
          onClick={() =>
            !showEditDetails ? setShowEditDetails(true) : annulate()
          }
        ></i>
      </div>
      <Input
        name="fullname"
        type="text"
        placeholder={t("Nom_et_Prénom")}
        onChange={handleFullNameChange}
        error={fullNameError}
        value={fullName}
        disabled={!showEditDetails}
      />
      <Input
        name="entrepriseName"
        type="text"
        placeholder="Nom de l'entreprise"
        onChange={handleEntrepriseNameChange}
        error={entrepriseNameError}
        value={entrepriseName}
        disabled={!showEditDetails}
      />
      <Input
        name="entrepriseAddress"
        type="text"
        placeholder={t("Adresse_delentreprise")}
        onChange={handleEntrepriseAddressChange}
        error={entrepriseAddressError}
        value={entrepriseAddress}
        disabled={!showEditDetails}
      />
      <Input
        name="email"
        type="email"
        placeholder={t("Email")}
        onChange={handleEmailChange}
        error={emailError}
        value={email}
        disabled={true}
      />
      <Input
        name="job"
        type="text"
        placeholder="Profession"
        onChange={handleJobChange}
        value={job}
        disabled={true}
      />
      <SelectInput
        selectedCategory={category}
        defaultValue={{ _id: "", name: t("category") }}
        selectList={projectCategories}
        onChange={handleCategoryChange}
        error={categoryError}
        isCategory={true}
        disabled={!showEditDetails}
      />
      {showEditDetails && (
        <BottomButton
          firstBtnTitle={t("Annuler")}
          secondBtnTitle={t("Valider")}
          firstBtnAction={annulate}
          secondBtnAction={validate}
        />
      )}
    </div>
  );
};
export default UserInfos;
