import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import Input from "../../../../components/Input/Input";
import { blockInvalidChar } from "../../../../utils/functions";
import Moment from "moment";
import BottomButton from "../../../../components/BottomButton/BottomButton";

const CampaignInfos = (props: any) => {
  const { t } = useTranslation();
  const [delay, setDelay] = useState(0);
  const [typeStartDate, setTypeStartDate] = useState("text");
  const [typeEndDate, setTypeEndDate] = useState("text");
  const [showEdit, setShowEdit] = useState(false);
  const [amount, setAmount] = useState(props.campaign.maxCap);
  const [startDate, setStartDate] = useState(props.campaign.startDate);
  const [endDate, setEndDate] = useState(props.campaign.delay);
  const [amountError, setAmountError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const handleAmountChange = (event: any) => {
    setAmount(event.target.value);
  };
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
  };
  const validateForm = () => {
    let invalidForm = false;

    if (amount === undefined) {
      invalidForm = true;
      setAmountError(t("Ce_champ_est_obligatoire"));
    } else {
      setAmountError("");
    }

    if (!startDate) {
      invalidForm = true;
      setStartDateError(t("Ce_champ_est_obligatoire"));
    } else {
      setStartDateError("");
    }
    if (!endDate) {
      invalidForm = true;
      setEndDateError(t("Ce_champ_est_obligatoire"));
    } else {
      setEndDateError("");
    }

    return invalidForm;
  };
  const showEditHandler = () => {
    setShowEdit(true);
  };
  useEffect(() => {
    if (props.campaign) {
      const date1 = new Date().getTime();
      const date2 = new Date(props.campaign.delay).getTime();
      const date = date2 - date1;
      setDelay(Math.round(date / (1000 * 60 * 60 * 24)));
    }
  }, [props.campaign]);
  const annulate = () => {
    setShowEdit(false);
  };
  const validate = () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const campaign = { ...props.campaign };
    campaign.maxCap = amount;
    campaign.startDate = startDate;
    campaign.delay = endDate;
    props.updateCampaignHandler(campaign);
    setShowEdit(false);
  };
  return (
    <div className="share_causes_wrapper sidebar_boxed">
      <div
        className="sidebar_heading_main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{t("Autres")}</h3>
        {!showEdit && props.canEdit && (
          <i
            className={"fa fa-edit"}
            style={{
              color: "#818090",
            }}
            onClick={showEditHandler}
          ></i>
        )}
      </div>
      {!showEdit && (
        <div className="social_icon_sidebar">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <p>
                {t("objectif")} : {props.campaign.maxCap} { t("tnd")}
              </p>
            </li>

            <li className="list-group-item">
              <p>
                {t("Date_de_début")} :{" "}
                {moment(props.campaign.startDate).format("DD-MM-YYYY")}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("Date_de_fin")} :{" "}
                {moment(props.campaign.delay).format("DD-MM-YYYY")}
              </p>
            </li>
            <li className="list-group-item">
              <p>
                {t("remaining_days")} : {delay} { t("days")}
              </p>
            </li>
          </ul>
        </div>
      )}
      {showEdit && (
        <div className="social_icon_sidebar">
          <Input
            name="amount"
            type="number"
            placeholder={t("Montant_à_collecter")}
            onChange={handleAmountChange}
            error={amountError}
            value={amount}
            icon={"DT"}
            onKeyDown={blockInvalidChar}
            min={0}
          />{" "}
          <Input
            name="startdate"
            type={typeStartDate}
            placeholder={t("Date_de_début")}
            onChange={handleStartDateChange}
            onFocus={() => setTypeStartDate("date")}
            onBlur={() => setTypeStartDate("text")}
            error={startDateError}
            value={startDate}
            min={Moment().add(7, "days").format("YYYY-MM-DD")}
          />{" "}
          <Input
            name="enddate"
            type={typeEndDate}
            placeholder={t("Date_de_fin")}
            onChange={handleEndDateChange}
            onFocus={() => setTypeEndDate("date")}
            onBlur={() => setTypeEndDate("text")}
            error={endDateError}
            value={endDate}
            min={
              startDate
                ? Moment(startDate).format("YYYY-MM-DD")
                : Moment().add(7, "days").format("YYYY-MM-DD")
            }
          />
          <BottomButton
            firstBtnTitle={t("Annuler")}
            secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
    </div>
  );
};
export default CampaignInfos;
