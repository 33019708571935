import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useSelector } from "react-redux";
import { changeLanguage } from "i18next";

const HeaderComponent = () => {
  var id: any;
  const [token, settoken] = useState<any>();
  const [role, setRole] = useState();
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);
  const data = useSelector((state: any) => state.Signin.signin.data);
  useEffect(() => {
    var tok = localStorage.getItem("token");
    settoken(tok);
    if (token) {
      var result: any = jwt_decode(token);
      id = result.id;
      setRole(result.role);
    }
  });
  const logout = () => {
    localStorage.removeItem("token");
  };
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18Lang")
  );
  const { t } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    setCurrentLanguage(lang);
    changeLanguage(lang);
    window.location.reload();
  };

  useEffect(() => {
    if (!currentLanguage) {
     // changeLanguage("ar");
      changeLanguage("fr");
      window.location.reload();
    }
    //localStorage.setItem("i18Lang", currentLanguage ? currentLanguage : "ar");
    localStorage.setItem("i18Lang", currentLanguage ? currentLanguage : "fr");
  }, [currentLanguage]);

  return (
    <>
      <header className="main_header_arae" dir={t("dir")}>
        <div className="navbar-area">
          <div className="main-responsive-nav">
            <div className="container">
              <div
                className="main-responsive-menu mean-container"
                style={{ position: "relative" }}
              >
                <div className="mean-bar">
                  <a
                    href={""}
                    className="meanmenu-reveal"
                    style={{ right: 0, left: "auto" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowResponsiveMenu((current) => !current);
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                  {showResponsiveMenu && (
                    <nav className="mean-nav">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a href="/" className="nav-link">
                            {t("Accueil")}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/AllProjects" className="nav-link">
                            {t("Projets")}
                          </a>
                        </li>

                        {token && role === "Donator" && (
                          <li className="nav-item">
                            <a href="/MesProjects" className="nav-link">
                              {t("Mesprojets")}
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <a href="/MesProjects" className="nav-link">
                            {t("Mesprojets")}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/aboutUs" className="nav-link">
                            {t("A_propos")}
                          </a>
                        </li>
                    {/*<li className="nav-item ml-4">
                      <a href="/encours" className="nav-link">
                        {t("Contact")}
                      </a>
                    </li>*/}
                        {token && (
                          <>
                            {" "}
                            <li className="nav-item">
                              <a
                                href={
                                  role === "Donator"
                                    ? "/profile-donator"
                                    : "/corporate-profile"
                                }
                                className="nav-link"
                              >
                                {t("mon_profile")}
                              </a>
                            </li>{" "}
                            <li className="nav-item">
                              <a
                                href="/"
                                onClick={() => {
                                  logout();
                                }}
                                className="nav-link"
                              >
                                {t("Déconnexion")}
                              </a>
                            </li>
                          </>
                        )}
                        {!token && (
                          <>
                            {" "}
                            <li className="nav-item">
                              <a href="/login" className="nav-link">
                                {t("Login")}
                              </a>
                            </li>{" "}
                          </>
                        )}
                      </ul>
                    </nav>
                  )}
                </div>
                <div className="logo">
                  <a href="/">
                    <img src="assets/img/logo.png" alt="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main-navbar">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <div className="img2">
                  <a href="/">
                    {" "}
                    <img
                      className="img2"
                      src="assets/img/logo.png"
                      alt="logo"
                    />
                  </a>
                </div>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a href="/" className="nav-link">
                        {t("Accueil")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/AllProjects" className="nav-link">
                        {t("Projets")}
                      </a>
                    </li>

                    {token && role === "Donator" && (
                      <li className="nav-item">
                        <a href="/MesProjects" className="nav-link">
                          {t("Mesprojets")}
                        </a>
                      </li>
                    )}
                    <li className="nav-item">
                      <a href="/aboutUs" className="nav-link">
                        {t("A_propos")}
                      </a>
                    </li>
                     {/*<li className="nav-item ml-4">
                      <a href="/encours" className="nav-link">
                        {t("Contact")}
                      </a>
                    </li>*/}
                    {token && (
                      <li className="nav-item ml-2">
                        <a href="#" className="nav-link">
                          {t("mon_profile")}{" "}
                          <i className="fas fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <a
                              href={
                                role === "Donator"
                                  ? "/profile-donator"
                                  : "/corporate-profile"
                              }
                              className="nav-link"
                            >
                              {t("Details_du_profile")}
                            </a>
                          </li>
                          {/* <li className="nav-item">
                            <a href="event-details.html" className="nav-link">
                              {t("Paramètres")}
                            </a>
                          </li> */}
                          <li className="nav-item">
                            <a
                              href="/"
                              onClick={() => {
                                logout();
                              }}
                              className="nav-link"
                            >
                              {t("Déconnexion")}
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}

                  </ul>
                  {!token && (
                    <div className="others-options d-flex align-items-center">
                      <div className="option-item">
                        <a href="/login" className="btn btn_navber">
                          {t("Login")}
                        </a>
                      </div>
                    </div>
                  )}
                  {/* <ul className="navbar-nav"> 
                    <li className="me-2 nav-item pull-left">
                    <a
                      href="#!"
                      onClick={() => changeLanguageHandler("fr")}
                      style={
                        currentLanguage === "fr"
                          ? { color: "blue" }
                          : { color: "black" }
                      }
                    >
                      FR |
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      href="#!"
                      onClick={() => changeLanguageHandler("fr")}
                      style={
                        currentLanguage === "fr"
                          ? { color: "blue" }
                          : { color: "black" }
                      }
                    >
                      AR
                    </a>
                  </li>
                  </ul> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="search-overlay" dir={t("dir")}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="search-overlay-layer"></div>
            <div className="search-overlay-layer"></div>
            <div className="search-overlay-layer"></div>
            <div className="search-overlay-close">
              <span className="search-overlay-close-line"></span>
              <span className="search-overlay-close-line"></span>
            </div>
            <div className="search-overlay-form">
              <form>
                <input
                  type="text"
                  className="input-search"
                  placeholder="Search here..."
                />
                <button type="button">
                  <i className="fas fa-search"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
