import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Bio from "./components/Bio";
import CoverPicture from "./components/CoverPicture";
import ProfileDetails from "./components/ProfileDetails";
import ProfileMenu from "./components/ProfileMenu";
import jwt_decode from "jwt-decode";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import {
  editInvestorAgent,
  getInvestorAgentDetails,
} from "../../../reducers/investorAgent";
import QuadraticCampaigns from "./components/QuadraticCampaigns";
import { FooterComponent } from "../../../components/footer";

import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
const getInvestorAgentDetailsAction = async (dispatch: any, id: string) => {
  return await dispatch(getInvestorAgentDetails(id)).unwrap();
};

const updateInvestorAgentAction = async (dispatch: any, userData: any) => {
  return await dispatch(editInvestorAgent(userData)).unwrap();
};
const CorporateProfile = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const investorAgent = useSelector(
    (state: any) => state.InvestorAgent.investorAgent.data
  );
  const investorAgentEdit = useSelector(
    (state: any) => state.InvestorAgent.editInvestorAgent.data
  );
  const statusEdit = useSelector(
    (state: any) => state.InvestorAgent.editInvestorAgent.status
  );
  const [currentUserId, setCurrentuserId] = useState("");

  const getInvestorAgentHandler = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res: any = jwt_decode(token);
      const id = res.id;
      setCurrentuserId(id);
      await getInvestorAgentDetailsAction(dispatch, id);
    }
  };

  useEffect(() => {
    getInvestorAgentHandler();
  }, []);

  useEffect(() => {
    if (statusEdit === "success") {
      setInvestorAgentData(investorAgentEdit);
    }
  }, [statusEdit]);

  const [showBio, setShowBio] = useState(true);
  const [investorAgentData, setInvestorAgentData] = useState();
  const [showQuadraticCampaigns, setShowQuadraticCampaigns] = useState(false);

  const showBioHandler = () => {
    setShowBio(true);
    setShowQuadraticCampaigns(false);
  };

  const showQuadraticCampaignsHandler = () => {
    setShowQuadraticCampaigns(true);
    setShowBio(false);
  };

  const editInvestorAgentHandler = async (userData: any) => {
    await updateInvestorAgentAction(dispatch, userData);
  };

  useEffect(() => {
    setInvestorAgentData(investorAgent);
  }, [investorAgent]);

  return (
    <>
      {investorAgentData && (
        <>
          <section
            className="mt-4 mb-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              cssClass="me-2"
              title={t("Lancer_une_campagne_quadratique")}
              onClick={() => navigate("/create-quadratic-campaign")}
            />
          </section>
          <CoverPicture />
          <section id="contact_arae_main" className="mt-2" dir={t("dir")}>
            <div className="container">
              <div className="row">
                <div className="col-lg-4" style={{ height: "inherit" }}>
                  <ProfileDetails
                    user={investorAgentData ? investorAgentData : {}}
                    editUserHandler={editInvestorAgentHandler}
                    id={currentUserId}
                  />
                </div>
                <div className="col-lg-8">
                  <div
                    className="contact_form_Wrapper"
                    style={{ height: "100%" }}
                  >
                    <ProfileMenu
                      showBio={showBio}
                      showBioHandler={showBioHandler}
                      showQuadraticCampaigns={showQuadraticCampaigns}
                      showQuadraticCampaignsHandler={
                        showQuadraticCampaignsHandler
                      }
                    />
                    {showBio && (
                      <Bio
                        user={investorAgentData}
                        editUserHandler={editInvestorAgentHandler}
                        id={currentUserId}
                      />
                    )}
                    {showQuadraticCampaigns && <QuadraticCampaigns />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <FooterComponent />
    </>
  );
};
export default CorporateProfile;
