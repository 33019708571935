import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SignUpService from "../services/SignUpService";

export const signupUser = createAsyncThunk(
  "user/signup",
  async (userData: any, thunkAPI) => {
    try {
      const { status, data } = await SignUpService.signupService(userData);
      if (status === 201 || status === 200) {
        localStorage.setItem("data",JSON.stringify(data))

        return data;
      } else if (status === 400 && data === "email does exist") {
        return thunkAPI.rejectWithValue({ error: "email does exist" });
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateStatusUser = createAsyncThunk(
  "user/update-status",
  async (id: string, thunkAPI) => {
    try {
      const { status, data } = await SignUpService.updateUserStatusService(id);
      if (status === 201 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  signup: {
    status: "idle",
    data: {
      email: "",
      name: "",
    },
    error: {},
  },
  updateStatusUser: {
    status: "idle",
    data: {},
    error: {},
  },
};
const SignUpSlice = createSlice({
  name: "signup-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [signupUser.pending.type]: (state) => {
      state.signup = {
        status: "loading",
        data: {
          email: "",
          name: "",
        },
        error: {},
      };
    },
    [signupUser.fulfilled.type]: (state, action) => {
      state.signup = {
        status: "success",
        data: {
          name: action.payload.name,
          email: action.payload.email,
        },
        error: {},
      };
    },
    [signupUser.rejected.type]: (state, action) => {
      state.signup = {
        status: "failed",
        data: {
          email: "",
          name: "",
        },
        error: action.payload.error,
      };
    },
    [updateStatusUser.pending.type]: (state) => {
      state.updateStatusUser = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateStatusUser.fulfilled.type]: (state, action) => {
      state.updateStatusUser = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateStatusUser.rejected.type]: (state, action) => {
      state.updateStatusUser = {
        status: "failed",
        data: {},
        error: "",
      };
    },
  },
});

export default SignUpSlice.reducer;
