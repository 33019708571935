import "./FormCheck.css";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
const FormCheck2 = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      {" "}
      <div className="form-group mb-3" style={{ display: "flex" }}>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault1"
            id="flexRadioDefault3"
            onChange={() => props.onChange("Gérant")}
            checked={props.role === "Gérant"}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault3">
            {t('Gérant')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault1"
            id="flexRadioDefault4"
            checked={props.role === "Empolyé"}
            onChange={() => props.onChange("Empolyé")}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault4">
                         {t('Employé')}
          </label>
        </div>
      </div>
      <small className="text-danger mt-3 mb-3">{props.error}</small>
    </div>
  );
};
export default FormCheck2;
