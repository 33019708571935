import Api from "../config/axios";
import config from "../config/config";

export const getDonatorService = async (email: string) => {
  const url = config.apiUrl + "/donator/getbyemail/" + email;

  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const editUserService = async (userData: any) => {
  const url = config.apiUrl + "/donator/updateDonatorById/" + userData.id;
  const data = {
    name: userData.firstname,
    description: userData.description,
    linkedin: userData.linkedin,
    facebook: userData.facebook,
    twitter: userData.twitter,
    gouvernorat: userData.gouvernerat,
    email: userData.email,
    city: "",
    job: userData.job
  };
  try {
    return await Api.put(url, data);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const editProfilePhotoService = async (userData: any) => {
  const url = config.apiUrl + "/donator/uploadPhotos/" + userData.id;
  try {
    const formData = new FormData();
    userData.files.forEach((file: File) => formData.append("upload", file));
    try {
      return await Api.post(url, formData);
    } catch (error) {
      throw new Error("An error occured");
    }
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const editUserFileService = async (userData: any) => {
  const url = config.apiUrl + "/donator/AddPdf/" + userData.id;
  try {
    const formData = new FormData();
    formData.append("file", userData.file);
    try {
      return await Api.post(url, formData);
    } catch (error) {
      throw new Error("An error occured");
    }
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getDonatorService,
  editUserService,
  editProfilePhotoService,
  editUserFileService,
};
