import { useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { updateStatusUser } from "../../reducers/signup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FooterComponent } from "../../components/footer";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
const updateUserStatusAction = async (dispatch: any, id: string) => {
  return await dispatch(updateStatusUser(id)).unwrap();
};
const EmailVerification = () => {
  const { t } = useTranslation();

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const status = useSelector(
    (state: any) => state.Signup.updateStatusUser.status
  );
  const verifiyEmailHandler = async () => {
    if (token) {
      const result: any = jwt_decode(token);
      await updateUserStatusAction(dispatch, result.id);
    }
  };
  useEffect(() => {
    if (status === "success") {
      navigate("/login");
    } else if (status === "failed") {
      setMessage("Une erreur est survenue");
    }
  }, [status]);
  return (
    <>
      <section
        className="author_form_area"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <section className="container-fluid justify-content-center">
          <h3 className="text-center">{t("Vérification")}</h3>
          <br />
          <div className="d-grid">
            <button className="btn btn_theme " onClick={verifiyEmailHandler}>
              {t("Vérifier_votre_email")}{" "}
            </button>
          </div>
          <p className="text-danger text-center mt-3 mb-3">{message}</p>
        </section>
      </section>
      <FooterComponent />
    </>
  );
};
export default EmailVerification;
