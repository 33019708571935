import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import BottomButton from "../../../../components/BottomButton/BottomButton";
import Input from "../../../../components/Input/Input";
import config from "../../../../config/config";
import { updateUserFile } from "../../../../reducers/donator";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const updateUserFileAction = async (formData: any, dispatch: any) => {
  return await dispatch(updateUserFile(formData)).unwrap();
};
const Links = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const fileInput: any = useRef();
  const [showEditLinks, setShowEditLinks] = useState(false);
  const [linkedin, setLinkedin] = useState(
    props.user.linkedin
      ? props.user.linkedin.replace("http://www.linkedin.com/", "")
      : ""
  );
  const [facebook, setFacebook] = useState(
    props.user.facebook
      ? props.user.facebook.replace("http://www.facebook.com/", "")
      : ""
  );
  const [twitter, setTwitter] = useState(
    props.user.twitter
      ? props.user.twitter.replace("http://www.twitter.com/", "")
      : ""
  );
  const [file, setFile] = useState<any>();
  const [filename, setFilename] = useState<any>(
    props.user.cv ? props.user.cv : ""
  );

  const changeLinkedinHandler = (e: any) => {
    setLinkedin(e.target.value);
  };
  const changeFacebookHandler = (e: any) => {
    setFacebook(e.target.value);
  };
  const changeTwitterHandler = (e: any) => {
    setTwitter(e.target.value);
  };
  const onInputClick = (event: any) => {
    event.target.value = "";
  };
  const selectFile = (e: any) => {
    e.preventDefault();
    fileInput.current.click();
  };
  const onChangeHandler = async (file: any) => {
    const files = file.target.files;
    setFile(files[0]);
    setFilename(files[0].name);
  };
  const validate = async () => {
    if (props.user) {
      const user = { id: props.id, ...props.user };
      user.facebook = "http://www.facebook.com/" + facebook;
      if (facebook.charAt(0) === "/") {
        user.facebook = "http://www.facebook.com/" + facebook.slice(1);
      }
      user.twitter = "http://www.twitter.com/" + twitter;
      if (twitter.charAt(0) === "/") {
        user.twitter = "http://www.twitter.com/" + twitter.slice(1);
      }
      user.linkedin = "http://www.linkedin.com/" + linkedin;
      if (linkedin.charAt(0) === "/") {
        user.linkedin = "http://www.linkedin.com/" + linkedin.slice(1);
      }
      props.editUserHandler(user);
      const data = { id: props.id, file: file };
      await updateUserFileAction(data, dispatch);
      setShowEditLinks(false);
    }
  };
  const removeFile = async () => {
    setFile(null);
    setFilename("");
    const data = { id: props.id, file: null };
    await updateUserFileAction(data, dispatch);
  };
  const annulate = () => {
    setShowEditLinks(false);
    setLinkedin(
      props.user.linkedin
        ? props.user.linkedin.replace("http://www.linkedin.com/", "")
        : ""
    );
    setFacebook(
      props.user.facebook
        ? props.user.facebook.replace("http://www.facebook.com/", "")
        : ""
    );
    setTwitter(
      props.user.twitter
        ? props.user.twitter.replace("http://www.twitter.com/", "")
        : ""
    );

    setFilename(props.user.cv ? props.user.cv : "");
  };
  return (
    <div className="mt-4">
      <div className="mb-4" style={{ display: "flex", alignItems: "center" }}>
        <h5 className="me-2">{t("Liens")}</h5>
        <i
          className={!showEditLinks ? "fa fa-edit me-2 pointer" : "fa fa-times me-2 pointer"}
          style={{
            color: "#818090",
          }}
          onClick={() => setShowEditLinks(!showEditLinks ? true : false)}
        ></i>
      </div>
      {showEditLinks && (
        <div className="mb-3">
          <Input
            name="linkedin"
            type="text"
            placeholder="Linkedin"
            value={linkedin.replace("http://www.linkedin.com/", "")}
            onChange={changeLinkedinHandler}
            fixedValue={"www.linkedin.com/"}
            cssClass={"small-text"}
          />
          <Input
            name="facebook"
            type="text"
            placeholder="Facebook"
            value={facebook.replace("http://www.facebook.com/", "")}
            onChange={changeFacebookHandler}
            fixedValue={"www.facebook.com/"}
            cssClass={"small-text"}
          />
          <Input
            name="twitter"
            type="text"
            placeholder="Twitter"
            value={twitter.replace("http://www.twitter.com/", "")}
            onChange={changeTwitterHandler}
            fixedValue={"www.twitter.com/"}
            cssClass={"small-text"}
          />
          {!filename && (
            <div className="mt-2 mb-4">
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInput}
                accept={"application/pdf"}
                onClick={onInputClick}
                onChange={onChangeHandler}
              />
              <i
                className="fa fa-plus me-2 pointer"
                aria-hidden="true"
                style={{ color: "#0081af" }}
                onClick={selectFile}
              ></i>
              <a href="" onClick={selectFile} className="me-2 pointer">
                {t("add_file")}
              </a>
            </div>
          )}
          {filename && (
            <div className="selected-file flex-center me-2 mb-4">
              <span className="me-2 filename">{filename}</span>
              <i
                className="fa fa-times-circle pointer"
                aria-hidden="true"
                onClick={removeFile}
              ></i>
            </div>
          )}
          <BottomButton
          firstBtnTitle={t("Annuler")}
          secondBtnTitle={t("Valider")}
            firstBtnAction={annulate}
            secondBtnAction={validate}
          />
        </div>
      )}
      {!showEditLinks && (
        <div className="social_icon_sidebar mt-3">
          <ul>
            <li>
              <a
                className="disabled"
                href={linkedin ? "http://www.linkedin.com/" + linkedin : ""}
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href={facebook ? "http://www.facebook.com/" + facebook : ""}
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href={twitter ? "http://www.twitter.com/" + twitter : ""}
                target="_blank"
              >
                <i
                  style={{ fontSize: "30px" }}
                  className="fab fa-twitter-square"
                ></i>
              </a>
            </li>
            <li>
              <a
                href={
                  filename ? config.apiUrl + "/donator/getFile/" + filename : ""
                }
                target="_blank"
              >
                <i style={{ fontSize: "30px" }} className="fa fa-file-pdf"></i>
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default Links;
