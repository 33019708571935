import Button from "../../../../components/Button/Button";
import Modal from "react-bootstrap/Modal";
import { regions2, regions2Arabe } from "../../../../utils/constants";
import { useEffect, useState } from "react";
import { getProjectCategories } from "../../../../reducers/projectCategory";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import _ from "lodash";

const getCategoriesAction = async (dispatch: any) => {
  return await dispatch(getProjectCategories({})).unwrap();
};
const Filter = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmRegion, setConfirmRegion] = useState(false);
  const [confirmCategory, setConfirmCategory] = useState(false);
  const [show, setShow] = useState(false);
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  const [allRegions, setAllRegions] = useState<any>(regions2);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCategoryFilter = () => setShowCategoryFilter(false);
  const handleShowCatgegoryFilter = () => setShowCategoryFilter(true);

  const projectCategories = useSelector(
    (state: any) => state.ProjectCategory.categories.data
  );
  const getCategories = async () => {
    await getCategoriesAction(dispatch);
  };

  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    if (props.currentLanguage === "ar") {
      setAllRegions(regions2Arabe);
    }
    if (props.currentLanguage === "fr") {
      setAllRegions(regions2);
    }
  }, [props.currentLanguage]);

  const getRegionName = (region: any) => {
    const x = _.find(allRegions, { _id: region });
    if (x && props.currentLanguage === "ar") {
      return x.name;
    } else {
      return region;
    }
  };

  return (
    <>
      <section className="mt-4 mb-4" style={{ display: "flex" }}>
        <Button cssClass="me-2" title={t("regions")} onClick={handleShow} />
        <Button
          cssClass="me-2"
          title={t("Secteurs")}
          onClick={handleShowCatgegoryFilter}
        />
      </section>
      {confirmRegion && props.selectedRegions.length !== 0 && (
        <section className="mt-4 mb-4">
          <h6 className="mb-2">{t("Régions_sélectionnées")}</h6>
          <div>
            {props.selectedRegions.map((item: any, index: any) => (
              <span key={index} className="item-1 me-1">
                {getRegionName(item)}
              </span>
            ))}
          </div>
        </section>
      )}
      {confirmCategory && props.selectedCategoriesNames.length !== 0 && (
        <section className="mt-4 mb-4">
          <h6 className="mb-2">{t("Secteurs_dactivité_sélectionnés")}</h6>
          {props.selectedCategoriesNames.map((item: string, index: any) => (
            <span key={index} className="item-1 me-1">
              {item}
            </span>
          ))}
        </section>
      )}
      <Modal show={show} onHide={handleClose} dir={t("dir")}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Sélectionner_des_régions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {allRegions.map((t: any, index: any) => (
            <div key={index} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                onChange={() => props.onSelectRegionHandler(t._id)}
                checked={props.selectedRegions.includes(t._id)}
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                {t.name}
              </label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            cssClass="me-2"
            title={t("Réinitialiser")}
            onClick={props.resetFilterByRegion}
          />
          <Button
            cssClass="me-2"
            title={t("Confirmer")}
            onClick={() => {
              props.filterByRegion();
              setConfirmRegion(true);
              handleClose();
            }}
          />
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCategoryFilter}
        onHide={handleCloseCategoryFilter}
        dir={t("dir")}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Sélectionner_des_secteurs_activité")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {projectCategories &&
            projectCategories.length > 0 &&
            projectCategories.map((item: any, index: any) => (
              <div key={index} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  onChange={() => props.onSelectCategoryHandler(item)}
                  checked={props.selectedCategories.includes(item._id)}
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  {props.currentLanguage === "fr" && item.name}
                  {props.currentLanguage === "ar" && item.arabicName}{" "}
                </label>
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            cssClass="me-2"
            title={t("Réinitialiser")}
            onClick={props.resetFilterByCategory}
          />
          <Button
            cssClass="me-2"
            title={t("Confirmer")}
            onClick={() => {
              props.filterByCategory();
              setConfirmCategory(true);
              handleCloseCategoryFilter();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Filter;
