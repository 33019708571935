export const TRANSLATIONS_AR = {
  Projets: "المشاريع",
  Details: "تفاصيل",
  Gallery: "صالة عرض",
  News: "أنباء",
  Investments: "الاستثمارات",
  Contact: "اتصل",
  Donate: "تبرع الآن",
  dir: "rtl",
  Accueil: "الصفحة الرئيسية",
  Mesprojets: "مشاريعي",
  Biblothèque: "مكتبة",
  A_propos: "حول",
  Details_du_profile: "الملف الشخصي",
  Paramètres: "الإعدادات",
  Déconnexion: "تسجيل خروج",
  Login: "تسجيل الدخول",
  mon_profile: "ملفي",
  La_première: "المنصة الأولى",
  de: "للتمويل التشاركي",
  crowdonation: " في شكل منح  ",
  titre_description:
    " تهدف إلى تطوير المرونة المجتمعية في مختلف مناطق الجمهورية التونسية وتستخدم تكنولوجيا البلوكشين لضمان الأكبر قدر من الشفافية",
  Faire_un_don_pour: "تبرع",
  Environment: "البيئة",
  Voir_plus: "شاهد المزيد...",
  Pour_quoi_Antlike: " لماذا انطلق",
  Inspirée: "مستلهمة من كلمة إنطلاق .    ",
  planète: "  هي حث لكل صاحب مبادرة للبدء في مشروعه من أجلٍ تحسين حياته ",
  travail: "و الحياة في المنطقة التي يسكنها",
  Au_travers: "من خلال انطلق، نحاول :",
  Renforcer: " معاضدة جهود التطور الاجتماعي في تونس     ",
  Offrir_des_possibilités: "تقديم الخيارات المالية البديلة",
  aux_petites: "للشركات الصغيرة والشركات الجديدة.",
  Assurer_une_meilleure: "ضمان جودة الحياة الأفضل للمواطنين التونسيين",
  Comment_ça_marche: "كيف يعمل",
  inscrire: "سجل في مبادرة إنطلق",
  porteur: "كصاحب مشروع",
  personne: "كفرد ممول     ",
  institution: "كمؤسسة ممولة     ",
  Les_campagnes_les_plus_récentes: "أحدث المشاريع",
  Antlike_est_un_consortium_entre_4_entités: "انطلق هي مؤتمر بين 4 كيانات",
  Financement_quadratique: "حملة تمويل مؤسستية ",
  Montant_total_collectés: "إجمالي المبلغ الذي تم جمعه",
  Donations_Faites: "التبرعات التي تمت",
  Campagnes_réalisées: "المشاريع التي تم الإنجاز بها",
  Les_campagnes_de_succès: "المشاريع التي حققت نجاحا    ",
  Newsletter: "إشعارات البريدية",
  Pour_recevoir: "للحصول على رسائل البريد الإلكتروني من انطلق",
  Abonnez_vous: "إشترك",
  objectif: "الهدف",
  campagne_exipred: "انتهت الحملة منذ  ",
  campagne_in_coming: "تنطلق الحملة  خلال",
  Date: " الزمان",
  region: "المكان",
  Projets_Soutenus: "المشاريع المدعومة",
  Wallet: "المحفظة",
  Bio: "السيرة الذاتية",
  Ajouter_une_biographie: "أضف سيرة ذاتية",
  Ajouter_une_image: " أضف صورة",
  Retirer: "انسحب",
  Liens: "الروابط",
  Créer_un_Projet: "أنشئ مشروعًا",
  Informations_generales: "الخطوة الاولى: معلومات عامة    ",
  Nom_du_Project: "اسم المشروع    ",
  Descriptif_du_projet: "وصف المشروع    ",
  Suivant: "التالي    ",
  Questionnaire: "الخطوة الثانية: الاستبيان    ",
  Durabilité: "الاستدامة    ",
  précisions: "يرجى تقديم تفاصيل لدعم إجابتك",
  projet_est_durable:
    "هل مشروعك مستدام (على سبيل المثال: هل تتوقع أن مشروعك وأنشطته يمكن تطويرها من خلال خلق قيمة طويلة الأجل لمجتمعك وشركائك؟)",
  échelle: "على مقياس من 1 (منخفض) إلى 5 (مرتفع)؟",
  Employabilité: "القابلية للتوظيف     ",
  chaque_sexe: "ما هو عدد العاملين من كل جنس؟     ",
  Homme: "ذكور",
  Femme: "النساء",
  tranche_âge: "ما هو عدد العاملين حسب الفئة العمرية؟",
  Entre_18_30: "بين 18 و 30 سنة:",
  Entre_30_45: "بين 30 و 45 سنة:     ",
  Entre_46_55: "بين 46 و 55 سنة    :",
  Entre_55: "> 55 سنة    :",
  Innovation: "الابتكار",
  innovant: "هل تعتبر مشروعك مبتكر؟",
  Exemple: "(مثال: المنتج ، العملية ، التسويق ، الابتكار التنظيمي ، إلخ.)     ",
  Unicité: "التفرد",
  unique: "هل مشروعك فريد من نوعه في المنطقة؟     ",
  Impact: "التأثير البيئي ",
  project_impact: "هل تعتقد أن مشروعك له تأثير؟     ",
  Collaborataires_et_prestataires: "المتعاونون ومقدمو الخدمة     ",
  Combien: "      كم عدد المتعاونين / أو مقدمي الخدمات لديك؟     ",
  Valeur_Ajoutée: "القيمة المضافة للمجتمع     ",
  bénéficie: " هل يستفيد المجتمع أيضًا من مشروعك؟     ",
  Finaliser: "الخطوة الثالثة: أنهِ مشروعك     ",
  Co_fondateur: "شريك مؤسس",
  ajouter_Co_fondateur: "هل ترغب في إضافة شريك مؤسس لمشروعك؟     ",
  Ajouter_un_co_fondateur: "أضف مؤسسًا مشاركًا     ",
  Valider: "تثبيت",
  photos_vidéos_documents: "أضف صورًا / مقاطع فيديو / مستندات     ",
  photos: "يرجى إرفاق الصور المتعلقة بمشروعك",
  Télécharger: "تحميل",
  PDF: "يرجى إرفاق مستند (pdf) يتعلق بمشروعك    ",
  Video: " يرجى إرفاق مقطع فيديو متعلق بمشروعك: (اختياري)     ",
  Terminer: "إنهاء",
  Créer_un_projet: "أنشئ مشروعًا     ",
  Félecitations: "تهانئ",
  succes: "تم إنشاء مشروعك بنجاح!     ",
  Créateur: "منشئ المشروع ",
  Email: "بريد إلكتروني     ",
  Gouvernorat: "محافظة",
  Telephone: "هاتف",
  Financé_à: "ممول في",
  Contributeurs: "المساهمون ",
  Jours_restants: "الأيام المتبقية ",
  Jours: "أيام ",
  Créer_une_campagne: "أنشئ حملة",
  Détails_de_la_campagne: "تفاصيل الحملة  ",
  Donations_récentes: "التبرعات الأخيرة",
  pas_de_donation: "  لا تبرع     ",
  Etape1_Campagne: "الخطوة الاولي: أضف حملة     ",
  Nom_de_la_campagne: "اسم الحملة    ",
  Montant_à_collecter: "المبلغ المراد تحصيله     ",
  Date_de_début: "تاريخ البدء",
  Date_de_fin: "تاريخ النهاية",
  Durée: "مدة",
  Objectifs_de_la_compagne: "أهداف الحملة     ",
  CampagneVideo: "يرجى إرفاق مقطع فيديو متعلق بحملتك     ",
  Campagnephotos: "يرجى إرفاق الصور المتعلقة بحملتك",
  CampagnePdf: "يرجى إرفاق مستند يتعلق بحملتك (pdf)      ",
  photos_vidéos_documentsCampagne: "أضف صورًا / مقاطع فيديو / مستندات     ",
  succes_campaign: "تم إنشاء الحملة بنجاح     ",
  Créer_un_Campagne: " أنشئ حملة",
  Faire_un_don: "تبرع",
  Faites_un_don_maintenant: "                  تبرع الآن ",
  Montant: "المبلغ ",
  sucessDonation: "تم تبرعك بنجاح!     ",
  Historique_des_transactions: "تاريخ المعاملات   ",
  Nom: "الاسم",
  Connectez_vous: "تسجيل الدخول",
  Mot_de_passe: "كلمة المرور",
  Mot_de_passe_oublié: "نسيت كلمة السر ؟   ",
  Inscrivez_vous_ici: "سجل هنا    ",
  Email_Obligatoire: "البريد الالكتروني مطلوب",
  Rejoignez_nous: "انضم إلينا ، سجل!    ",
  Donateur: "المانح",
  Entreprise: "شركة",
  Nom_et_Prénom: "الإسم واللقب",
  Confirmer_mot_passe: "تأكيد كلمة المرور    ",
  Inscrivez_vous: "سجل     ",
  ONG: "منظمة غير حكومية    ",
  Nouvelle_entreprise: "شركة جديدة",
  Gérant: "مدير",
  Employé: "الموظف",
  Nouvelle_ONG: "منظمة غير حكومية جديدة    ",
  Nom_de_lEntreprise: "اسم الشركة",
  Nom_de_lONG: "اسم المنظمة غير الحكومية    ",
  Choisir_une_entreprise: " اختر شركة    ",
  Choisir_une_ONG: "اختر منظمة غير حكومية    ",
  Télécharger_RNE: "تحميل سجل الأعمال الوطني    ",
  Télécharger_justificatif: "تحميل إثبات",
  Télécharger_CIN_Recto: "تحميل بطاقة الهوية الأمامية",
  Télécharger_CIN_verco: "تحميل بطاقة الهوية الخلفية    ",
  Vérification: "التحقق من عنوان البريد الإلكتروني    ",
  Vérifier_votre_email: "         تحقق من بريدك الالكتروني    ",
  Adresse_delentreprise: "عنوان الشركة",
  historique_des_compagnes_quadratique: "            تاريخ الحملة ",
  regions: "المناطق",
  Secteurs: "مجالات الأنشطة",
  attente: "الحالة: معلق",
  Simulé: "الحالة: محاكاة",
  Accepté: " الحالة: مقبولة",
  Refusé: "    الحالة: مرفوض",
  Auncu_campagne_quadratique: "لا توجد حملة ",
  Lancer_une_campagne_quadratique: "إطلاق حملة",
  Créer_une_campagne_quadratique: "           قم بإنشاء حملة    ",
  Selectionner: "يرجى تحديد المناطق و / أو الصناعات لإنشاء حملة   ",
  Régions_sélectionnées: "مناطق مختارة",
  Secteurs_dactivité_sélectionnés: "قطاعات أعمال مختارة    ",
  Sélectionner_des_régions: "حدد المناطق",
  Sélectionner_des_secteurs_activité: "حدد الصناعات",
  Réinitialiser: "إعادة",
  Confirmer: "تأكيد    ",
  Campagne: "الحملة",
  Projet: "المشروع",
  Montant_total: "إجمالي المبلغ",
  Progression: "التقدم %",
  simulation: " محاكاة",
  Montant_Donné: "المبلغ الممنوح",
  Annuler: "لالغاء",
  Ajouter_Somme: "أضف المبلغ المراد المساهمة به",
  Obligatoire: "إجباري",
  Télecharger_PDF: "تحميل PDF",
  Nombre_des_employés: "عدد الموظفين",
  Nombre_des_femmes: "عدد النساء",
  Nombre_des_hommes: "عدد الرجال",
  email_est_obligatoire: "البريد الالكتروني مطلوب    ",
  Le_mot_de_passe_est_obligatoire: "كلمة المرور مطلوبة    ",
  Veuillez_confirmer_votre_adresse_email:
    "يرجى تأكيد عنوان البريد الإلكتروني الخاص بك    ",
  Vérifier_votre_email_et_mot_de_passe:
    "تحقق من بريدك الإلكتروني وكلمة المرور    ",
  Ce_champ_est_obligatoire: "هذا الحقل مطلوب",
  Veuillez_remplir_tous_les_champs: "الرجاء إكمال جميع الحقول",
  Lemail_nest_pas_valide: "البريد الإلكتروني غير صالح    ",
  mdp: "يجب أن تتكون كلمة المرور الخاصة بك من 6 أحرف على الأقل ويجب أن تتضمن مجموعة من الأرقام والأحرف والأحرف الخاصة (! @٪)",
  mdp_necorrespondpas: "كلمة السر لا تتطابق",
  validation_de_votre_compte:
    "         لقد تلقيت رسالة بريد إلكتروني لتأكيد حسابك    ",
  Veuillez_taper_au_moins_50_mots: "الرجاء كتابة 50 كلمة على الأقل    ",
  Il_faut_ajouter_au_moins_une_image_à_votre_campagne:
    "يجب عليك إضافة صورة واحدة على الأقل إلى حملتك    ",
  Il_faut_ajouter_un_document_pdf_à_votre_campagne:
    "يجب عليك إضافة مستند إلى حملتك    ",
  Il_faut_ajouter_une_vidéo_à_votre_campagne:
    "تحتاج إلى إضافة مقطع فيديو إلى حملتك",
  caractére_numérique: "الرجاء كتابة رقم (0،1،2،3،4)    ",
  Il_faut_ajouter_au_moins_une_image_à_votre_project:
    "يجب عليك إضافة صورة واحدة على الأقل إلى مشروعك    ",
  Il_faut_ajouter_un_document_pdf_à_votre_project:
    "يجب عليك إضافة مستند إلى مشروعك    ",
  Profession: "المهنة",
  Aucun_Projet: "لا يوجد مشروع",
  campaign_number: "عدد الحملات",
  Nombre_des_collaborators: "عدد المتعاونين",
  co_founder: "شريك مؤسس",
  collected_amount: "المبلغ الذي تم جمعه",
  category: "الفئة",
  remaining_days: "الأيام المتبقية",
  Veuillez_taper_au_moins_10_mots: "الرجاء كتابة 10 كلمة على الأقل    ",
  Services: "خدمات",
  À_propos_de_nous: "ما يخصنا",
  Communiquez_avec_nous: "اتصل بنا",
  Conditions_de_service: "شروط الخدمة    ",
  Supports: "يدعم",
  Liens_rapides: "روابط سريعة",
  Images: "الصور",
  DurabilitéTitle: "الاستدامة",
  UnicitéTitle: "التفرد",
  InnovationTitle: "الابتكار",
  Impact_sur_environnement: "التأثير البيئي    ",
  Valeur_communauté: "القيمة المضافة للمجتمع    ",
  Autres: "معلومات أخرى",
  Aucune_vidéo: "لا يوجد فيديو",
  Réponse: "الإجابة",
  Download: "تحميل",
  Document: "الوثيقة",
  Donner: "يعطي",
  première_plateforme: "المنصة الأولى للتجمع التبرعي",
  Adresse: "عنوان",
  Numero: "رقم الهاتف",
  employees: "الموظفين",
  nombre_des_projets: "عدد المشاريع    ",
  general_informations: "معلومات عامة",
  durability: "استدامة المشروع",
  employability: "قابلية التوظيف للمشروع",
  innovation: "الابتكار",
  uniqueness: "الانفراد",
  environmental_impact: "الأثر على البيئة",
  indirect_jobs_and_service_providers:
    "الوظائف الغير مباشرة ومزودو الخدمات المستفيدون",
  added_value_community: "القيمة المضافة للمجتمع",
  cofounder_project_team: "الشركاء وفريق المشروع",
  supporting_materials_promotional_content:
    "وثائق الدعم المشروع و المحتوى الترويجي",
  how_create_project: "كيف تصنع مشروع ؟",
  explanation_1:
    "إذا كنت ترغب في إنشاء مشروع، يرجى ملء الاستمارة أدناه لتقديم التفاصيل عن  مشروعك أو  مبادرتك الاجتماعية. ستكون المعلومات التي ستقدمها مفيدة لتفسير مشروعك و انجاح حملتك التمويلية .",
  explanation_2:
    "النموذج يحتوي على 10 مراحل، حيث ستجيب على سلسلة من الأسئلة الآتية:",
  explanation_3:
    "بمجرد انتهاء ملء إستمارة المشروع الخاص بك، سيقيم فريق خاص من انطلق ملفك و نعلمك في حال وجود تغيرات أو تحسينات لازمة لقبول مشروعك على المنصة",
  explanation_4:
    "بعد التأكيد على مشروعك، يمكنك إنشاء حملة تمويل تشاركي تحدد فيها أهدافك واحتياجاتك المالية. يمكنك أيضًا إنشاء محتوى خاص لهذه الحملة ومشاركته على الملف الخاص بالحملة",
  explanation_5:
    "يجدر الذكر  أنه يمكنك إطلاق عدة حملات تمويل تشاركي لنفس المشروع، ولكن فقط حملة واحدة ستكون مفتوحة للتبرعات",
  pays: "البلد",
  yes: "نعم",
  no: "لا",
  good_impact: "تأثير بيئي جيد",
  bad_impact: "تأثير سيء",
  no_impact: "لا تأثير",
  no_value: "لا قيمة (مشروع شخصي)",
  little_value: "القليل من القيمة",
  moyennement: "باعتدال",
  majority: "الأغلبية",
  FinaliserPDF: "الخطوة 10: المواد الداعمة والمحتوى الترويجي.",
  equipe_admin:
    "الآن الأمر متروك لفريق المسؤولين للتحقق من صحة طلبك ، ستستغرق هذه العملية بضعة أيام!",
  emailandtel:
    "إذا لزم الأمر ، يمكنك الاتصال بنا عبر البريد الإلكتروني: على candidature@Ant'like.co أو عبر الهاتف 71 XXXXXX.",
  Notez_bien:
    "يرجى ملاحظة: بمجرد التحقق من صحة مشروعك ، يجب عليك إطلاق النموذج لإنشاء حملة جمع التبرعات الخاصة بك ، من أجل تلقي الأموال. يمكنك إنشاء حملات متعددة ، ولكن واحدة فقط ستكون مفتوحة للتبرعات.",
  video: "الفيديو",
  no_document: "لا يوجد وثيقة",
  none: "لا أحد",
  tnd: "دينار تونسي",
  days: "أيّام",
  description: "التفاصيل",
  add_file: "أضف ملفًا",
  inscription: "التسجيل",
  all_categories: "جميع الفئات",
  no_transcations: "لا يوجد تحويلات",
  aucun: "لا يوجد",
  check_number_of_employees: "تحقق من عدد الموظفين",
  succes_create_quadratic_campaign: "لقد أطلقت حملة مؤسستية",
  see_details: "انظر التفاصيل",
  add_project: "أضف مشروعا",
  Montant_Total: "المبلغ الإجمالي",
  Solidarity_Token: "رمز التضامن",
  nb_donations: "عدد التبرعات",
  amount_to_give: "المبلغ الذي سيتم منحه",
};
