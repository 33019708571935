import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProjectCategories } from "../../reducers/projectCategory";
import {
  countries,
  countriesArabe,
  regions,
  regionsArabe,
} from "../../utils/constants";
import SelectInput from "../SelectInput/SelectInput";
import i18n from "i18next";

const getCategoriesAction = async (dispatch: any) => {
  return await dispatch(getProjectCategories({})).unwrap();
};
const ProjectFilter = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
  const projectCategories = useSelector(
    (state: any) => state.ProjectCategory.categories.data
  );

  const getCategories = async () => {
    await getCategoriesAction(dispatch);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleCategoryChange = (e: any) => {
    props.setCategory(e.target.value);
    props.setCurrentPage(1);
    props.setData([]);
  };
  const handleRegionChange = (e: any) => {
    props.setRegion(e.target.value);
    props.setCurrentPage(1);
    props.setData([]);
  };
  const handleCountryChange = (event: any) => {
    props.setCountry(event.target.value);
    props.setCurrentPage(1);
    props.setData([]);
  };

  return (
    <div className="row mt-3 mb-3">
      <div className="col-4">
        <SelectInput
          selectedCategory={props.category}
          defaultValue={{ _id: "", name: t("all_categories") }}
          selectList={projectCategories}
          onChange={handleCategoryChange}
          isCategory={true}
        />
      </div>
      <div className="col-4">
        <SelectInput
          selectedCategory={props.region}
          defaultValue={{ _id: "", name: t("region") }}
          selectList={currentLanguage === "fr" ? regions : regionsArabe}
          onChange={handleRegionChange}
        />
      </div>
      <div className="col-4">
        {props.region === "Autre" && (
          <SelectInput
            selectedCategory={props.country}
            defaultValue={{ _id: "", name: t("pays") }}
            selectList={
              currentLanguage === "fr" ? countries : countriesArabe
            }
            onChange={handleCountryChange}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectFilter;
