import { FooterComponent } from "../../../components/footer";
import RecentCampaigns from "../../home/components/recentProject/RecentCampaigns";


const EnCoursComponent = () => {
 
  return (
    <>
      <section id="make_donation_area" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
              <div className="section_heading">
                <h2>
                  <span className="color_big_heading">
                    En Cours..
                    
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <RecentCampaigns />
        </div>
      </section>
      <FooterComponent/>
    </>
  );
};
export default EnCoursComponent;
