// Card.js
import './Card.css';
import { useNavigate,Link } from "react-router-dom";
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";


interface CardProps {
    icon: string;
    CategoryName: string;
    projectCount: number;
}  



const Card: React.FC<CardProps> = ({  icon, CategoryName, projectCount  }) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const projectCategories = useSelector(
    (state: any) => state.ProjectCategory.categories.data
  );

  
  const handleSeeMore = (CategoryName: any) => {
    navigate("/AllProjects", {
      state: {
        category: CategoryName,
      },
    });
  };
    return (

            <div className="hexagon-card">
            <div className="hexagon-cat">
              <div className="hexagon-icon">
                <img src={icon} alt={`${CategoryName} Icon`} className="card-icon"/>
              </div>
            </div>
            <h2>{CategoryName}</h2>
            <p>nombre de projets : {projectCount}</p>
            {projectCount === 0 ? (
              <a>
              <p read-only >voir plus </p>
              <button disabled className="card-button">Faire un don</button>
            </a>
             
            ) : (
            <span>
              <a href='/AllProjects'>voir plus </a><br></br>
              <button className="card-button" onClick={(e) => {
                          e.preventDefault();
                          handleSeeMore('6374f6aba9ae53f94cbc2382');
                        }}>Faire un don</button>
            </span>
        
    
      )}
          </div>
      );
};

export default Card;
