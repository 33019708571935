const Spinner = (props: any) => {
  return (
    <div
      className="spinner-border text-primary col-12"
      role="status"
      style={{ margin: " 0 auto" }}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};
export default Spinner;
