import { useState } from "react";
import Input from "../../../../components/Input/Input";
import {
  blockInvalidChar,
  isPositiveInteger,
} from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const FormStep21 = (props: any) => {
  const [nbEmpMan, setNbEmpMan] = useState(
    props.project.nbEmpMan ? props.project.nbEmpMan : undefined
  );
  const [nbEmpWoman, setNbEmpWoman] = useState(
    props.project.nbEmpWoman ? props.project.nbEmpWoman : undefined
  );
  const [nbEmpAge1, setNbEmpAge1] = useState(
    props.project.nbEmpAge1 ? props.project.nbEmpAge1 : undefined
  );
  const [nbEmpAge2, setNbEmpAge2] = useState(
    props.project.nbEmpAge2 ? props.project.nbEmpAge2 : undefined
  );
  const [nbEmpAge3, setNbEmpAge3] = useState(
    props.project.nbEmpAge3 ? props.project.nbEmpAge3 : undefined
  );
  const [nbEmpAge4, setNbEmpAge4] = useState(
    props.project.nbEmpAge4 ? props.project.nbEmpAge4 : undefined
  );
  const { t } = useTranslation();

  const [nbEmpManError, setNbEmpManError] = useState("");
  const [nbEmpWomanError, setNbEmpWomanError] = useState("");
  const [nbEmpAge1Error, setNbEmpAge1Error] = useState("");
  const [nbEmpAge2Error, setNbEmpAge2Error] = useState("");
  const [nbEmpAge3Error, setNbEmpAge3Error] = useState("");
  const [nbEmpAge4Error, setNbEmpAge4Error] = useState("");
  const [message, setMessage] = useState("");

  const validateForm = () => {
    let invalidForm = false;
    const emptyFields =
      nbEmpMan === "" &&
      nbEmpWoman === "" &&
      nbEmpAge1 === "" &&
      nbEmpAge2 === "" &&
      nbEmpAge3 === "" &&
      nbEmpAge4 === "";

    if (emptyFields) {
      setMessage(t("Veuillez_remplir_tous_les_champs"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }

    if (!nbEmpMan) {
      invalidForm = true;
      setNbEmpManError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpMan))) {
      setNbEmpManError(t("caractére_numérique"));
    } else {
      setNbEmpManError("");
    }
    if (!nbEmpWoman) {
      invalidForm = true;
      setNbEmpWomanError(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpWoman))) {
      setNbEmpWomanError(t("caractére_numérique"));
    } else {
      setNbEmpWomanError("");
    }
    if (!nbEmpAge1) {
      invalidForm = true;
      setNbEmpAge1Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge1))) {
      setNbEmpAge1Error(t("caractére_numérique"));
    } else {
      setNbEmpAge1Error("");
    }
    if (!nbEmpAge2) {
      invalidForm = true;
      setNbEmpAge2Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge2))) {
      setNbEmpAge2Error(t("caractére_numérique"));
    } else {
      setNbEmpAge2Error("");
    }
    if (!nbEmpAge3) {
      invalidForm = true;
      setNbEmpAge3Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge3))) {
      setNbEmpAge3Error(t("caractére_numérique"));
    } else {
      setNbEmpAge3Error("");
    }
    if (!nbEmpAge4) {
      invalidForm = true;
      setNbEmpAge4Error(t("Ce_champ_est_obligatoire"));
    } else if (!isPositiveInteger(parseFloat(nbEmpAge4))) {
      setNbEmpAge4Error(t("caractére_numérique"));
    } else {
      setNbEmpAge4Error("");
    }
    if (
      parseInt(nbEmpMan) + parseInt(nbEmpWoman) !==
      parseInt(nbEmpAge1) +
        parseInt(nbEmpAge2) +
        parseInt(nbEmpAge3) +
        parseInt(nbEmpAge4)
    ) {
      setMessage(t("check_number_of_employees"));
      invalidForm = true;
      return invalidForm;
    } else {
      setMessage("");
    }
    return invalidForm;
  };
  const handleNbEmpManChange = (event: any) => {
    setNbEmpMan(event.target.value);
  };
  const handleNbEmpWomanChange = (event: any) => {
    setNbEmpWoman(event.target.value);
  };
  const handleNbEmpAge1Change = (event: any) => {
    setNbEmpAge1(event.target.value);
  };
  const handleNbEmpAge2Change = (event: any) => {
    setNbEmpAge2(event.target.value);
  };
  const handleNbEmpAge3Change = (event: any) => {
    setNbEmpAge3(event.target.value);
  };
  const handleNbEmpAge4Change = (event: any) => {
    setNbEmpAge4(event.target.value);
  };

  const nextStepHandler = (e: any) => {
    e.preventDefault();
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    props.project.nbEmpMan = nbEmpMan;
    props.project.nbEmpWoman = nbEmpWoman;
    props.project.nbEmpAge1 = nbEmpAge1;
    props.project.nbEmpAge2 = nbEmpAge2;
    props.project.nbEmpAge3 = nbEmpAge3;
    props.project.nbEmpAge4 = nbEmpAge4;
    props.updateProject(props.project);
    props.nextHandler(3);
  };
  return (
    <>
      <div className="row">
        <button
          className="btn btn_theme back-btn mb-2"
          onClick={() => props.nextHandler(1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h5 className="text-center mb-5">{t("Employabilité")}</h5>
        <span className="mb-4">{t("chaque_sexe")} </span>
        <div className="col-12">
          <Input
            label={t("Homme")}
            type={"number"}
            onChange={handleNbEmpManChange}
            error={nbEmpManError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpMan}
          />
        </div>
        <div className="col-12">
          <Input
            label={t("Femme")}
            type={"number"}
            onChange={handleNbEmpWomanChange}
            error={nbEmpWomanError}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpWoman}
          />
        </div>
        <span className="mt-4 mb-4">{t("tranche_âge")}</span>
        <div className="col-12">
          <Input
            label={t("Entre_18_30")}
            type={"number"}
            onChange={handleNbEmpAge1Change}
            error={nbEmpAge1Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge1}
          />
        </div>
        <div className="col-12">
          <Input
            label={t("Entre_30_45")}
            type={"number"}
            onChange={handleNbEmpAge2Change}
            error={nbEmpAge2Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge2}
          />
        </div>
        <div className="col-12">
          <Input
            label={t("Entre_46_55")}
            type={"number"}
            onChange={handleNbEmpAge3Change}
            error={nbEmpAge3Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge3}
          />
        </div>
        <div className="col-12">
          <Input
            label={t("Entre_55")}
            type={"number"}
            onChange={handleNbEmpAge4Change}
            error={nbEmpAge4Error}
            onKeyDown={blockInvalidChar}
            min={0}
            value={nbEmpAge4}
          />
        </div>
        <p className="text-danger text-center mt-3 mb-3">{message}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="btn btn_theme" onClick={nextStepHandler}>
          {t("Suivant")}
        </button>
      </div>
    </>
  );
};
export default FormStep21;
