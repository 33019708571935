import Api from "../config/axios";
import config from "../config/config";

export const ProjectService = async (id:any) => {
  const url = config.apiUrl + `/project/${id}`;

  try {
    return await Api.get(url);

  } catch (error) {
    throw new Error("An error occured");
  }
};
export const InformationUserService = async (id:any) => {
  const url = config.apiUrl + `/donator/${id}`;

  try {
    return await Api.get(url);

  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
    ProjectService,InformationUserService
};
